import API_ENDPOINTS from '../constants/apiEndpoints';
import apiRequest, { METHODS } from './apiRequest';

export const getUserDetails = (id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.ADMIN_USER_DETAILS}${id}`,
    method: METHODS.GET,
    privateURL: true,
    headers: {},
  });
