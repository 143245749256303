import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AuthInput from '../../components/auth-input';
import { APP_LANGUAGE, Colors } from '../../common/constants/AppConstants';
import { TICKET_STATUS } from './constants';
import { validationSchema } from '../../schema/updateTicketStatusSchema';
import {
  formHelperStyle,
  textInputField,
  textInputFieldStyle,
} from '../../sections/auth/login/LoginForm.style';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

export default function UpdateTicketStatus({
  open,
  handleClose,
  ticketData,
  title,
  submitBtnText,
  handleStatusUpdate,
}) {
  const { t, i18n } = useTranslation();
  const { language } = useContext(LanguageContext);
  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;

  const formik = useFormik({
    initialValues: {
      remarks: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema:
      ticketData?.status === TICKET_STATUS.IN_PROGRESS
        ? validationSchema
        : undefined,
    onSubmit: (values) => {
      const payload = {
        status:
          ticketData?.status === TICKET_STATUS.OPEN
            ? TICKET_STATUS.IN_PROGRESS
            : TICKET_STATUS.CLOSED,
      };
      if (ticketData?.status === TICKET_STATUS.IN_PROGRESS) {
        payload.remarks = values.remarks;
      }

      handleStatusUpdate(payload, ticketData?.status);
    },
  });

  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { width: '40%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="row" dir={isArabic ? 'rtl' : 'ltr'}>
          <div className="status-label">
            {t('CURRENT_STATUS')} :{' '}
            <span className="status-val label-yellow">
              {ticketData?.status === TICKET_STATUS.IN_PROGRESS
                ? 'IN PROGRESS'
                : ticketData.status}
            </span>
          </div>
        </div>

        {ticketData?.status === TICKET_STATUS.IN_PROGRESS && (
          <div className="row mt-4">
            <AuthInput
              className="col-12"
              htmlFor="remarks"
              label={t('REMARK')}
              required={true}
              sx={textInputField(isFieldError('remarks'), language)}
              height="40px"
              inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
              placeholder={t('REMARK')}
              name="remarks"
              id="remarks"
              onChange={formik.handleChange}
              value={formik.values.remarks}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('remarks')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('remarks') && t(formik.errors.remarks)}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <SubmitButton
          onClick={formik.handleSubmit}
          disabled={
            ticketData?.status === TICKET_STATUS.IN_PROGRESS && !formik.isValid
          }
          autoFocus
        >
          {submitBtnText}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
