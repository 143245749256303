export const styledContent = (theme) => ({
  maxWidth: 480,
  margin: 'auto',
  maxHeight: '100vh',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});

export const styledRoot = (theme) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
});
