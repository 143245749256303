import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addproductSchema = Yup.object().shape({
  productName: Yup.string()
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .matches(
      REGEX_PATTERN.REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE,
      'Invalid product name'
    )
    .required('Field cannot be empty')
    .label('product Name'),

  nameAr: Yup.string()
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .matches(
      REGEX_PATTERN.ARABIC_REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE,
      'Invalid product name'
    )
    .required('Field cannot be empty')
    .label('product Name'),

  product_id_sku: Yup.string()
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(REGEX_PATTERN.ALPHA_NUMERIC, 'Invalid Product ID/SKU')
    .required('Field cannot be empty')
    .label('Product ID/SKU'),

  description: Yup.string()
    .matches(
      REGEX_PATTERN.REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE,
      'Invalid description'
    )
    .max(300, 'Too Long!')
    .required('Field cannot be empty')
    .label('description'),

  descriptionAr: Yup.string()
    .matches(
      REGEX_PATTERN.ARABIC_REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE,
      'Invalid description'
    )
    .max(300, 'Too Long!')
    .required('Field cannot be empty')
    .label('description'),

  purity: Yup.string().required('Field cannot be empty').label('purity'),
  category: Yup.string().required('Field cannot be empty').label('category'),
  metalType: Yup.string().required('Field cannot be empty').label('metal Type'),

  weight: Yup.number()
    .min(0.01, 'Invalid weight')
    .max(999999.99, 'Invalid weight')
    .required('Field cannot be empty')
    .typeError('Invalid weight')
    .label('Weight'),

  quantity: Yup.string()
    .max(10, 'Invalid quantity')
    .matches(REGEX_PATTERN.NUMBER_WITH_ZERO_WITHOUT_DECIMAL, 'Invalid quantity')
    .required('Field cannot be empty')
    .label('quantity'),

  makingCharge: Yup.string()
    .matches(
      REGEX_PATTERN.NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL,
      'Invalid making cost'
    )
    .required('Field cannot be empty')
    .label('making cost'),

  additionalcost: Yup.string()
    .matches(
      REGEX_PATTERN.NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL,
      'Invalid additional cost'
    )
    .label('additional cost'),

  hallmark: Yup.string().label('hallmark'),
  productImages: Yup.array()
    .of(Yup.string().required('Product images is required'))
    .min(1, 'At least one image is required')
    .max(5, 'No more than five images are allowed')
    .required('Field cannot be empty')
    .label('Product Images'),

  fk_countries_id: Yup.string()
    .required('Field cannot be empty')
    .label('fk_countries_id'),
});
