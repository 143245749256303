import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addAccountManagerSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      REGEX_PATTERN.ALPHA_NUMERIC_WITH_NO_SPACE_WITH_ARABIC,
      'Invalid FirstName'
    )
    .required('Field cannot be empty')
    .label('First Name'),

  middleName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .matches(
      REGEX_PATTERN.ALPHA_NUMERIC_WITH_NO_SPACE_WITH_ARABIC,
      'Invalid MiddleName'
    )
    .label('Middle Name'),

  lastName: Yup.string()
    .min(2, 'Too Short!')
    .matches(
      REGEX_PATTERN.ALPHA_NUMERIC_WITH_NO_SPACE_WITH_ARABIC,
      'Invalid LastName'
    )
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('Last Name'),

  email: Yup.string()
    .email('Invalid email')
    .matches(REGEX_PATTERN.EMAIL, 'Invalid email')
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('Email'),

  phoneNumber: Yup.string()
    .matches(REGEX_PATTERN.PHONE_NUMBER, 'Invalid Phone number')
    .min(7, 'Invalid Phone number')
    .max(15, 'Invalid Phone number')
    .required('Field cannot be empty')
    .label('Phone Number'),

  countryCode: Yup.string()
    .required('Field cannot be empty')
    .label('country code'),
  isActive: Yup.string().required('Field cannot be empty'),
});
