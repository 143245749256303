import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';
export const getTransferList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.METAL_TRANSFER,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const getTarnsferDetails = (id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.METAL_TRANSFER}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
