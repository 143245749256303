import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addInventorySchema = Yup.object().shape({
  quantity: Yup.string()
    .max(11, 'Invalid quantity')
    .matches(
      REGEX_PATTERN.NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL_AND_NEGATIVE_SIGN_UPTO_TEN_PLACES,
      'Invalid quantity'
    )
    .required('Field cannot be empty')
    .label('quantity'),
});
