import { Box, Stack, Typography } from '@mui/material';
import { headingStyle, contentStyle } from './Stamp.style';

export function Stamp({ heading, content, icon }) {
  return (
    <Box>
      <Typography sx={headingStyle}>{heading}</Typography>
      <Stack direction={'row'} alignItems={'center'} columnGap={'4px'}>
        {icon}
        <Typography sx={contentStyle}>{content}</Typography>
      </Stack>
    </Box>
  );
}
