import { InputLabel, Stack, TextField, InputAdornment } from '@mui/material';
import { inputLabelStyle, style } from './style';
import { useContext } from 'react';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { REGEX_PATTERN } from '../../constants/regex';
import './style.scss';

const handleEnteredvalue = (event, type) => {
  const inputValue = event.key;
  if (inputValue === '-') {
    if (event.target.value.length !== 0) {
      event.preventDefault();
    }
    return;
  }
  if (inputValue === '.') {
    if (event.target.value.includes('.')) {
      event.preventDefault();
    }
    return;
  }
  if (!REGEX_PATTERN.NUMBERONLY.test(inputValue) && type === 'number') {
    event.preventDefault();
  }
};

const handleEnteredNumbervalue = (event, type) => {
  const inputValue = event.key;
  if (!REGEX_PATTERN.NUMBERONLY.test(inputValue) && type === 'number') {
    event.preventDefault();
  }
};

const AuthInput = ({
  label,
  onChange,
  placeholder,
  textFieldError,
  helperText,
  containerSx,
  textFieldStyle,
  maxLength,
  required,
  className,
  inputType,
  allowdecimals,
  endAdornmentContent,
  autoComplete = 'off',
  ...props
}) => {
  const { language } = useContext(LanguageContext);
  return (
    <Stack sx={containerSx} className={`${className} auth-input-container`}>
      <InputLabel htmlFor={props?.id} sx={inputLabelStyle(language)} required>
        <span className={required ? 'asterisk-content' : ''}>{label}</span>
      </InputLabel>
      <TextField
        sx={{ ...style.textInputField, ...textFieldStyle }}
        height="40px"
        inputProps={{
          sx: style.textInputFieldStyle,
          maxLength,
          autoComplete: autoComplete,
        }}
        InputProps={{
          endAdornment: endAdornmentContent && (
            <InputAdornment
              position="end"
              sx={{ fontSize: 14, fontWeight: 500 }}
            >
              {endAdornmentContent}
            </InputAdornment>
          ),
        }}
        onKeyPress={(event) =>
          allowdecimals
            ? handleEnteredvalue(event, inputType)
            : handleEnteredNumbervalue(event, inputType)
        }
        placeholder={placeholder}
        onChange={onChange}
        error={Boolean(textFieldError)}
        helperText={helperText || ' '}
        onFocus={(event) => {
          event.target.setAttribute('autocomplete', 'off');
          event.target.value = '';
        }}
        {...props}
      />
    </Stack>
  );
};

export default AuthInput;
