import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { defaultModalStyle, defaultScreenStyle } from './ModalBox.style';
import { STR_CONSTANTS } from '../../constants/stringConstants';

const handleClose = (event, reason, onClose, toCloseOnOutsideModalClick) => {
  if (reason === STR_CONSTANTS.BACK_DROP_CLICK && toCloseOnOutsideModalClick) {
    onClose();
  } else if (reason === STR_CONSTANTS.ESCAPE_KEY) {
    onClose();
  }
};

export const ModalBox = ({
  children,
  isOpen = false,
  onClose,
  screenStyle = {},
  height,
  width,
  paddingX,
  paddingY,
  toCloseOnOutsideModalClick = false,
  modalStyle = {},
}) => {
  const boxStyling = {
    ...defaultModalStyle,
    ...modalStyle,
  };
  if (height != null) boxStyling['height'] = `${height}px`;
  if (width != null) boxStyling['width'] = `${width}px`;
  // Apply padding only if values are provided
  const paddingStyles = {};
  if (paddingY != null) paddingStyles.paddingY = `${paddingY}px`;
  if (paddingX != null) paddingStyles.paddingX = `${paddingX}px`;

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) =>
        handleClose(event, reason, onClose, toCloseOnOutsideModalClick)
      }
      sx={{ ...defaultScreenStyle, ...screenStyle }}
    >
      <Box style={{ ...boxStyling, ...paddingStyles }}>{children}</Box>
    </Modal>
  );
};
export default ModalBox;
