import { FormHelperText, InputLabel, Stack, TextField } from '@mui/material';
import { inputLabelStyle, style, imageStyle } from '../phone-number/style';
import { useContext } from 'react';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import '../phone-number/style.scss';
import { Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import EGP from '../../assets/icons/egypt.jpeg';
import AED from '../../assets/icons/unitedArab.png';
import SAR from '../../assets/icons/saudi.png';
import '../add-user/selectComponent.css';
import {
  selectInputField,
  textInputFieldStyle,
} from './../../sections/auth/login/LoginForm.style';
const CountryWithFLag = ({
  label,
  placeholderForCountry,
  containerSx,
  className,
  t,
  formik,
  fieldName,
  multiSelect = false,
  labelName,
  options,
  disabled = false,
  selectedOptions,
  setSelectedOptions,
  disableInput,
  required = false,
  defaultValue,
  onBlur,
  isUseLabel = false,
  ...props
}) => {
  const { language } = useContext(LanguageContext);
  const isFieldError = (formik, fieldName) => {
    const hasError = Boolean(formik.errors[fieldName]);
    const isTouched = Boolean(formik.touched[fieldName]);
    return isTouched && hasError;
  };
  const isError = isFieldError(formik, fieldName);
  const handleChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue(fieldName, value);
  };
  const countryFlag = {
    EGP,
    AED,
    SAR,
  };

  const renderValue = (selected) => {
    const selectedOption = options.find((option) => option.id === selected);

    return (
      <div className="d-flex gap-1 w-100">
        <img src={countryFlag[selectedOption?.currency]} style={imageStyle} />
        <div className="">
          {' '}
          {isUseLabel
            ? `${selectedOption?.label}`
            : `${selectedOption?.nameEng}`}
        </div>
      </div>
    );
  };
  return (
    <Stack sx={containerSx} className={`${className} auth-input-container`}>
      <InputLabel sx={inputLabelStyle(language)}>
        <span className={required ? 'asterisk-content' : ''}>{labelName}</span>
      </InputLabel>
      <div className="row gx-1" style={{ height: '40px' }}>
        <div className="">
          <Select
            displayEmpty
            disabled={disabled}
            id={fieldName}
            sx={selectInputField(
              isFieldError(formik, fieldName) && formik.values[fieldName] == '',
              language
            )}
            input={<OutlinedInput error={isError} />}
            inputProps={{ sx: textInputFieldStyle }}
            multiple={multiSelect}
            height="40px"
            fullWidth
            name={fieldName}
            value={selectedOptions}
            renderValue={
              selectedOptions && selectedOptions.length > 0
                ? renderValue
                : () => (
                    <span className="placeholder-label">
                      {placeholderForCountry}
                    </span>
                  )
            }
            onChange={(event) =>
              handleChange(event, setSelectedOptions, options)
            }
            onBlur={onBlur}
            error={isError}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <div className="d-flex align-items-center gap-2">
                  <img style={imageStyle} src={countryFlag[option.currency]} />
                  {isUseLabel ? option?.label : option.nameEng}
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
        {isError && (
          <FormHelperText error={true}>
            {t(formik.errors[fieldName])}
          </FormHelperText>
        )}
      </div>
    </Stack>
  );
};

export default CountryWithFLag;
