import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';
import { withdrawalConstants } from '../pages/Withdrawal Requests/constants';

export const getWithdrawalListData = ({ query = {}, id }) => {
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.WITHDRAWAL_REQUEST}/${id}`
      : `${API_ENDPOINTS.WITHDRAWAL_REQUEST}`,
    header: {},
    method: METHODS.GET,
    privateURL: true,
    query: query,
  });
};

export const acceptRejectWithdrawalDetails = (data) => {
  let payload = {};
  if (data?.status === withdrawalConstants.REJECT_STATUS) {
    payload = {
      status: data?.status,
      admin_remarks: data?.remark,
    };
  } else {
    payload = {
      status: data?.status,
      bank_reference_no: data?.bankReferenceNumber,
    };
  }
  apiRequest({
    endpoint: `${API_ENDPOINTS.WITHDRAWAL_REQUEST}/${data?.id}`,
    method: METHODS.PUT,
    data: payload,
    headers: {
      'content-type': 'application/json',
    },
    privateURL: true,
  });
};
