import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const createProduct = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.CREATE_PRODUCT,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const editProduct = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.CREATE_PRODUCT}/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
export const getProductDetails = (id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.CREATE_PRODUCT}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};

export const uploadFile = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return apiRequest({
    endpoint: API_ENDPOINTS.FILES,
    method: METHODS.POST,
    headers: {},
    type: 'multipart/form-data',
    data: formData,
    privateURL: true,
  });
};

export const updateQuantity = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.CREATE_PRODUCT}/quantity/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const bulkUpload = (data) => {
  const formData = new FormData();
  formData.append('file', data);
  return apiRequest({
    endpoint: API_ENDPOINTS.BULK_UPDATE,
    method: METHODS.PUT,
    headers: {},
    type: 'multipart/form-data',
    data: formData,
    responseType: 'blob',
    privateURL: true,
  });
};
