export const style = {
  welcomeText: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '47px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  welcomeDesText: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#BDBDBD',
  },
};

export const generateStyledBox = (theme) => ({
  width: '100%',
  maxWidth: 480,
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '52px',
  boxShadow: theme.customShadows.card,
  background: '#121311',
  [theme.breakpoints.up('md')]: {
    width: '33%',
  },
});

export const styledImage = {
  width: '150px',
  height: '150px',
  backgroundImage:
    'radial-gradient(rgb(208, 165, 86, 0.1) 0% 45%, transparent 80%)',
  borderRadius: '40%',
};

export const styledMessage = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '28px',
  gap: '16px',
  height: '100px',
};
