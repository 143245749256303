import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import AccountDeleteConfirmation from './AccountDeleteConfirmation';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';

import { getAccountDetails, approveRejectReq } from '../../api/account-delete';
import {
  successToaster,
  errorToaster,
  getFormattedDate,
} from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { AccountDeleteConstant, statusEnum } from './AccountDeleteConstant';
import { Colors } from '../../common/constants/Color';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import { getModulePerm } from '../../utils/helperUtility';
import './styles.css';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const RejectButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.RED}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    marginTop: '40px',
  };
});

function AccountDeleteVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [accountData, setAccountData] = useState([]);
  const [ModalMessage, setModalMessage] = useState('');
  const [actionType, setActionType] = useState(' ');
  const { language } = useContext(LanguageContext);
  const [isActionRequired, setIsActionRequired] = useState(false);

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  const requestId = () => {
    return query.get('id');
  };

  const getUserName = () => {
    return `${accountData.firstName || ''} ${accountData.middleName || ''} ${
      accountData.lastName || ''
    }`;
  };

  const handleClickOpen = (type) => {
    const msg =
      type === AccountDeleteConstant.ACCEPT
        ? `${t('ACCEPT_ACCOUNT_DELETE_MSG')} ${getUserName()}`
        : `${t('REJECT_ACCOUNT_DELETE_MSG')} ${getUserName()}`;
    setModalMessage(msg);
    setActionType(
      type === AccountDeleteConstant.ACCEPT
        ? AccountDeleteConstant.ACCEPT
        : AccountDeleteConstant.REJECT
    );
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleAccept = async (event) => {
    performAccountDeleteVerification({
      status: event.action,
      remarks: event.data || '',
    });
  };

  const handleReject = () => {
    setActionType('');
  };

  const performAccountDeleteVerification = async ({ status, remarks }) => {
    try {
      setLoading(false);
      const body = {
        status,
        admin_remarks: remarks,
      };
      const response = await approveRejectReq(body, requestId());
      if (response.data.isSuccess) {
        const messageKey =
          status === AccountDeleteConstant.ACCEPT_STATUS
            ? t('ACCOUNT_DELETE_ACEEPT_MSG')
            : t('ACCOUNT_DELETE_REJECT_MSG');
        successToaster(messageKey, language);
        navigate(ENDPOINTS.ACCOUNT_LIST);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };

  useEffect(() => {
    const id = requestId();
    const getDataForReq = async () => {
      try {
        const response = await getAccountDetails(id);
        const data = response.data.data;
        const isRequired =
          data.status == AccountDeleteConstant.ACCEPT_STATUS ||
          data.status == AccountDeleteConstant.REJECT_STATUS
            ? false
            : true;
        if (data.createdAt) {
          data.createdAt = getFormattedDate(
            data.createdAt,
            'DD/MM/YYYY, HH:mm'
          );
        }

        data.phoneNo = data.mobileCode
          ? `(+${data.mobileCode}) ${data.phoneNo}`
          : data.phoneNo;

        setAccountData(data);
        setIsActionRequired(isRequired);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    getDataForReq();
  }, []);

  const handleAcceptReject = (type) => {
    handleClickOpen(type);
  };
  const currentModulePerm = getModulePerm(MODULE_CONST.USER_MANAGEMENT);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.ACCOUNT_LIST)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t('ACCOUNT_DELETE_REQUEST')}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('FIRST_NAME')}</div>
            <div className="account-details-value">
              {accountData.firstName || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('MIDDLE_NAME')}</div>
            <div className="account-details-value">
              {accountData.middleName || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('LAST_NAME')}</div>
            <div className="account-details-value">
              {accountData.lastName || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('EMAIL_TEXT')}</div>
            <div className="account-details-value">
              {accountData.email || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">
              {t('REQUEST_DATE_TIME')}
            </div>
            <div className="account-details-value">
              {accountData.createdAt || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('PHONE')}</div>
            <div className="account-details-value">
              {accountData.phoneNo || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('STATUS')}</div>
            <div className="account-details-value">
              {statusEnum[accountData.status] || 'NA'}
            </div>
          </div>

          {accountData && accountData?.adminRemarks && (
            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('ADMIN_REMAKS')}</div>
              <div className="account-details-value">
                {accountData.adminRemarks || 'NA'}
              </div>
            </div>
          )}

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('USER_REMARKS')}</div>
            <div className="account-details-value">
              {accountData.userRemarks || 'NA'}
            </div>
          </div>
        </div>
        <FormButtons>
          {isActionRequired && currentModulePerm.writeMode && (
            <RejectButton
              onClick={() => handleAcceptReject(AccountDeleteConstant.REJECT)}
            >
              {t('REJECT')}
            </RejectButton>
          )}

          {isActionRequired && currentModulePerm.writeMode && (
            <SubmitButton
              type="submit"
              onClick={() => {
                handleAcceptReject(AccountDeleteConstant.ACCEPT);
              }}
            >
              {t('ACCEPT')}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>

      {open && (
        <AccountDeleteConfirmation
          open={open}
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleReject={handleReject}
          actionType={actionType}
          title={t('ACCOUNT_DELETE_REQUEST')}
          description={t(ModalMessage)}
          ModalMessage
        />
      )}
    </div>
  );
}

export default AccountDeleteVerification;
