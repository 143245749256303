import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getTransactionDetails = (id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.WALLET_TRANSACTION}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
    paymentUrl: true,
  });

export const getTransactionList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.WALLET_TRANSACTION,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
    paymentUrl: true,
  });

export const downloadTransactionFile = (query, isDownload = false) => {
  const payload = {
    endpoint: API_ENDPOINTS.TRANSACTION_LIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  };
  if (isDownload) {
    payload.responseType = 'blob';
  }
  return apiRequest(payload);
};
