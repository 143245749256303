import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import admin from './admin.png';
import { IconButton, Stack } from '@mui/material';
import ProfileOptions from './ProfileOptions';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { navTopBarStyle } from './style';
import LanguageSwitcher from '../../components/language-switcher/LanguageSwitcher';

export default function NavTopbar() {
  return (
    <AppBar position="static" sx={{ height: '44px' }}>
      <Toolbar variant="dense" sx={navTopBarStyle.toolbar}>
        <Stack
          sx={navTopBarStyle.fullSizeContainer}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <img src={admin} alt="logo" height="28.53px"></img>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="30%"
          >
            <ProfileOptions />

            <LanguageSwitcher />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
