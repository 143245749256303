export const headingStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  textAlign: 'left',
  marginBottom: '17px',
};

export const contentStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 450,
  lineHeight: '20px',
  textAlign: 'left',
};
