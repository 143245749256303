import { Box, Stack, Typography } from '@mui/material';
import {
  cardInformationContentStyle,
  cardInformationHeadingStyle,
  informationBoxContentStyle,
  informationBoxStyle,
  informationBoxTitleStyle,
} from './AddUser.style';

export function InformationBox({ title, cardInformation, singleColumn }) {
  return (
    <Stack sx={informationBoxStyle}>
      <Box sx={informationBoxTitleStyle}>{title}</Box>
      <Box sx={() => informationBoxContentStyle(singleColumn)}>
        {Object.keys(cardInformation).map((cardTitle, idx) => {
          return (
            <Box key={idx}>
              <Box sx={cardInformationHeadingStyle}>{cardTitle}</Box>
              <Box>
                <Typography sx={cardInformationContentStyle}>
                  {cardInformation[cardTitle]}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
}
