import { useTranslation } from 'react-i18next';
import { ALL, APP_LANGUAGE } from '../common/constants/AppConstants';

const useChangeLabelForCountryList = (setterFn) => {
  const { t, i18n } = useTranslation();

  return () => {
    setterFn((prev) => {
      const newCountryList = prev.map((country) => {
        if (country?.value === ALL) {
          return {
            label: t('ALL'),
            value: ALL,
          };
        }
        return {
          ...country,
          label:
            i18n?.language === APP_LANGUAGE.ARABIC
              ? country.nameAr
              : country.nameEng,
        };
      });
      return newCountryList;
    });
  };
};

export default useChangeLabelForCountryList;
