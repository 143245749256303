export const MODULE_CONST = {
  BANK_TRANSFERS: 'BANK_TRANSFERS',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  ROLE_MANAGEMENT: 'ROLE_MANAGEMENT',
  ADMIN_MANAGEMENT: 'ADMIN_MANAGEMENT',
  PRODUCT_MANAGEMENT: 'PRODUCT_MANAGEMENT',
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  TRANSFER_MANAGEMENT: 'TRANSFER_MANAGEMENT',
  TRANSACTION_HISTORY: 'TRANSACTION_HISTORY',
  VERIFICATION_APPROVAL: 'VERIFICATION_APPROVAL',
  WAREHOUSE_MANAGEMENT: 'WAREHOUSE_MANAGEMENT',
  ACCOUNT_DELETE_REQUEST: 'ACCOUNT_DELETE_REQUEST',
  REPORTING_ANALYTICS: 'REPORTING_ANALYTICS',
  HOME: 'HOME',
  ACCOUNT_MANAGEMENT: 'ACCOUNT_MANAGEMENT',
  ANNOUNCEMENT_MANAGEMENT: 'ANNOUNCEMENT_MANAGEMENT',
  TICKET_MANAGEMENT: 'TICKET_MANAGEMENT',
  CITY_MANAGEMENT: 'CITY_MANAGEMENT',
  WITHDRAWALS: 'WITHDRAWALS',
  GIFT_SETTINGS: 'GIFT_SETTINGS',
  THIRD_PARTY_CLIENTS: 'THIRD_PARTY_CLIENTS',
};

export const FILTER_OPTION = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const CLICK_EVENT_KEYS = {
  NEW_ROLE: 'NEW_ROLE',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
};

export const ORDER_STATUS_FILTERS = [
  'PLACED',
  'CONFIRMED',
  'SHIPPED',
  'DELIVERED',
  'READYFORPICKUP',
  'REJECTED',
];

export const DELIVERY_TYPE = ['Pickup', 'Ship'];
