import React from 'react';
import FileIcon from '../../assets/icons/file.svg';
import CrossIcon from '../../assets/icons/Icon.svg';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DefaultPdf from '../../assets/pdf.png';
import CircularProgress from '@mui/material/CircularProgress';
import '../file-upload/style.css';
function DocumentsView({
  hideChangeOption = false,
  type,
  listView = true,
  onEvent,
  onView,
  productImages = [],
  deleteCase,
}) {
  const handleDelete = (id) => {
    onEvent(id, deleteCase);
  };
  const viewImage = (id) => {
    onView(id);
  };

  const addDefaultSrc = (ev) => {
    ev.target.src = DefaultPdf;
  };

  const renderProductImages = () => {
    return productImages.map((item) => {
      return (
        <div
          className="image-box-container ms-1"
          key={item.id}
          onClick={() => {
            viewImage(item.id);
          }}
        >
          <img
            className="pdf-border"
            height={60}
            width={60}
            src={item.url}
            onError={addDefaultSrc}
          />
          {!item.id && (
            <CircularProgress
              color="warning"
              style={{
                position: 'absolute',
                top: 8,
                left: 8,
                color: 'warning',
              }}
            />
          )}
          {!hideChangeOption && (
            <div className="close-icon">
              <img
                className="close-img cursor-pointer"
                src={CrossIcon}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDelete(item.id);
                }}
              />
            </div>
          )}
        </div>
      );
    });
  };

  const renderListView = () => {
    return productImages.map((item) => {
      return (
        <div className="d-flex justify-content-between mt-4" key={item.id}>
          <div className="d-flex justify-content-center align-items-center font-13px gap-1">
            <img className="" src={FileIcon} />
            <div
              className="cursor-pointer ms-1"
              onClick={() => {
                viewImage(item.id);
              }}
            >
              {item.name}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            {!hideChangeOption && (
              <IconButton
                aria-label="close"
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDelete(item.id);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </div>
      );
    });
  };
  return (
    <>
      {listView && renderListView()}
      {!listView && <div className="d-flex mt-4">{renderProductImages()}</div>}
    </>
  );
}

export default DocumentsView;
