import * as yup from 'yup';

export const addUserSchema = yup.object().shape({
  firstName: yup.string(),
  middleName: yup.string(),
  lastName: yup.string(),
  email: yup.string(),
  status: yup.string().oneOf(['active', 'inactive']),
  account_manager_id: yup
    .string()
    .required('Field cannot be empty')
    .label('accountManager'),
});
