import { APP_LANGUAGE } from '../../common/constants/AppConstants';

export const style = {
  textInputField: {
    '& .MuiInputBase-root': {
      height: '40px',
      borderRadius: '8px',
      borderWidth: '0.66px',
      gap: '4px',
      '& fieldset': {
        border: '0.66px solid #8A8A8A !important',
      },
      '&.Mui-focused': {
        backgroundColor: '#FDFDFD',
      },
    },
  },
  textInputFieldStyle: {
    padding: '6px 12px 6px 12px',
    lineHeight: '16px',
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: '500',
  },

  linkTextStyle: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Montserrat',
    lineHeight: '16px',
    color: '#616161',
    cursor: 'pointer',
  },
  formButtonStyle: {
    backgroundColor: '#303030',
    height: '40px',
    padding: '6px 12px',
    borderRadius: '8px',
    gap: '2px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#303030',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.17)',
      color: '#FFFFFF',
    },
  },
};

export const inputLabelStyle = (language) => ({
  fontWeight: 500,
  fontFamily: 'Montserrat',
  color: '#424242',
  fontSize: '14px',
  lineHeight: '16px',
  marginBottom: '4px',
  position: 'relative',
  textAlign: language === APP_LANGUAGE.ARABIC ? 'right' : 'left',
  '& .MuiFormLabel-asterisk': {
    display: 'none',
  },
  '.asterisk-content': {
    position: 'relative',
  },
  '.asterisk-content::before': {
    color: '#C24622',
    top: '50%',
    right: '100%',
    paddingRight: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ENGLISH === language ? 'none' : 'inline',
  },
  '.asterisk-content::after': {
    color: '#C24622',
    top: '50%',
    left: '100%',
    paddingLeft: '1px',
    transform: 'translateY(-23%)',
    content: '"*"',
    position: 'absolute',
    fontFamily: 'Montserrat',
    fontSize: 22,
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: 0,
    display: APP_LANGUAGE.ARABIC === language ? 'none' : 'inline',
  },
});

export const imageStyle = {
  width: '20px',
  height: '16px',
  borderRadius: '2px',
};
