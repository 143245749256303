import { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import SearchBar from '../../components/search-bar-debounce';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import { useSearchParams } from 'react-router-dom';
import {
  ALL,
  METAL_TYPE_OPTIONS,
  NA,
} from '../../common/constants/AppConstants';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { getCountryList } from '../../api/cityManagement';
import SelectInput from '../../components/select-input/SelectInput';
import { getGiftSettingListing } from '../../api/giftSettings';
import {
  changeLabelTranslation,
  errorToaster,
  getModulePerm,
} from '../../utils/helperUtility';
import useChangeLabelForCountryList from '../../hooks/useChangeLabelForCountryList';
import useGetCountryListStructuredObject from '../../hooks/useGetCountryListStructuredObject';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function GiftSettings() {
  const { t, i18n } = useTranslation();
  const currentModulePerm = getModulePerm(MODULE_CONST.GIFT_SETTINGS);
  const [countryList, setCountryList] = useState([
    { label: t('ALL'), value: ALL },
  ]);
  const metalList = changeLabelTranslation(METAL_TYPE_OPTIONS, t);
  const changeLabelForCountryList =
    useChangeLabelForCountryList(setCountryList);
  const getStructure = useGetCountryListStructuredObject();

  const [giftSettingListing, setGiftSettingListing] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = parseInt(10);

  const [filterData, setFilterData] = useState({
    countryId: '',
    metalType: '',
  });

  const getListQueryPayload = () => {
    let queryPayload = {};
    if (!!filterData?.countryId && filterData.countryId !== ALL) {
      queryPayload['country_id'] = filterData?.countryId;
    }
    if (!!filterData?.metalType && filterData.metalType !== ALL) {
      queryPayload['metal_type'] = filterData?.metalType;
    }
    return queryPayload;
  };

  useEffect(() => {
    fetchListOfCountry();
  }, []);

  useEffect(() => {
    changeLabelForCountryList();
  }, [i18n?.language]);

  const fetchListOfCountry = async () => {
    setLoading(true);
    try {
      const { data } = await getCountryList();
      const structuredCountryList = getStructure(data.data);
      setCountryList((prev) => [...prev, ...structuredCountryList]);
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTableData();
  }, [filterData]);

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getGiftSettingListing({
        query: queryPayload,
        pageLimit: itemsPerPage,
        pageSkip: page - 1,
      });
      setGiftSettingListing(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      errorToaster(
        error?.data?.message ?? error?.message ?? t('SOME_ERROR_OCCURED'),
        i18n?.language
      );
      setLoading(false);
    }
  };

  const handleCityUpdate = (data) => {
    const serializedData = encodeURIComponent(JSON.stringify(data));
    navigate(`${ENDPOINTS.UPDATE_SETTINGS}?data=${serializedData}`);
  };

  const ButtonForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={`${Colors.GRAY}`}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => handleCityUpdate(rowItemData)}
          >
            {t('UPDATE')}
          </Typography>
        </Box>
      </>
    );
  };

  const currentItems = giftSettingListing.map((setting) => {
    const itemObj = {
      id: setting?.id,
      country_name:
        i18n?.language === APP_LANGUAGE.ARABIC
          ? setting?.nameAr || NA
          : setting?.nameEng || NA,
      country_nameAR: setting?.nameAr || NA,
      country_nameEN: setting?.nameEng || NA,
      gift_fee_value: setting?.giftFeeValue || NA,
      gift_fee_unit: t(setting?.giftFeeUnit) || NA,
      metal_type: t(setting?.metalType) || NA,
      status: setting?.isActive ? t('ACTIVE') : t('INACTIVE') || NA,
      status_key: setting?.isActive,
    };
    itemObj.renderButton = () =>
      currentModulePerm.writeMode && <ButtonForTable rowItemData={itemObj} />;
    return itemObj;
  });

  const giftSettingsColumnConfig = [
    {
      key: 'country_name',
      title: t('Country'),
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'metal_type',
      title: t('METAL_TYPE'),
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'gift_fee_unit',
      title: t('GIFT_FEE_UNIT'),
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'gift_fee_value',
      title: t('GIFT_FEE_VALUE'),
      requiredStyle: { width: '20%' },
    },
    {
      key: 'status',
      title: t('STATUS'),
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'renderButton',
      title: '',
      isComponent: true,
    },
  ];

  const resourceName = {
    singular: 'gift',
    plural: 'gifts',
  };

  const handleFilterSelection = (key, value) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: value,
      };

      return data;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('GIFT_SETTINGS')}
        </Typography>
        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => navigate(ENDPOINTS.ADD_NEW_SETTINGS)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_SETTINGS')}
          </Button>
        )}
      </Stack>

      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex gap-2 justify-content-start">
            <SelectInput
              placeholder={t('Country')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={'Status'}
              value={filterData.countryId}
              options={countryList}
              handleSelectChange={(obj) => {
                handleFilterSelection('countryId', obj);
              }}
            />
            <SelectInput
              placeholder={t('METAL_TYPE')}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={'metalType'}
              value={filterData.metalType}
              options={metalList}
              handleSelectChange={(obj) => {
                handleFilterSelection('metalType', obj);
              }}
            />
          </div>
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={giftSettingsColumnConfig}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_SETTINGS_FOUND')}
        />
      </Card>
    </Container>
  );
}
