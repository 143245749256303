import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const verifyOtp = (data) =>
  apiRequest({
    endpoint: API_ENDPOINTS.VERIFY_OTP,
    method: METHODS.POST,
    data: data.data,
  });

export const resendOtp = (data) =>
  apiRequest({
    endpoint: API_ENDPOINTS.RESEND_OTP,
    method: METHODS.POST,
    data: data.data,
  });
