import {
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from '@mui/material';
import {
  selectLabelStyle,
  selectInputField,
  textInputFieldStyle,
} from './../../sections/auth/login/LoginForm.style';
import './selectMultiple.css';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { errorToaster } from '../../utils/helperUtility';
import { SELECT_ROLE_LIMIT } from '../../common/constants/AppConstants';
import CancelIcon from '@mui/icons-material/Cancel';
import { Colors } from '../../common/constants/Color';
export const SelectComponentMultiple = ({
  language,
  formik,
  fieldName,
  placeholder,
  multiSelect = false,
  labelName,
  options,
  className,
  disabled = false,
  errorText,
  selectedOptions,
  setSelectedOptions,
  isFieldError,
}) => {
  const { t } = useTranslation();
  const handleChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue(
      fieldName,
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const renderValue = (selected) => (
    <div className="chip-container">
      {selected?.map((value) => (
        <Chip
          key={value}
          label={options.find((option) => option.id === value)?.name || value}
          onDelete={(event) => handleChipDelete(value, event)}
        />
      ))}
    </div>
  );
  const errorStyle = {
    color: '#FF4842',
    lineHeight: '1.5',
    fontSize: '0.75rem',
    fontFamily: 'Inter',
    fontWeight: '400',
  };
  const handleDelete = (event, value) => {
    const currvalue = formik.values[fieldName];
    const filteredvalue = currvalue.filter((val) => {
      return val !== value;
    });
    formik.setFieldValue(
      fieldName,
      typeof filteredvalue === 'string'
        ? filteredvalue.split(',')
        : filteredvalue
    );
  };
  const handleChipDelete = (value, event) => {
    if (disabled) return;
    event.stopPropagation();
    handleDelete(event, value);
  };
  return (
    <>
      <div className={className}>
        <InputLabel
          htmlFor={fieldName}
          sx={selectLabelStyle(language)}
          required
        >
          <span className="asterisk-content">{labelName}</span>
        </InputLabel>
        <Select
          MenuProps={{
            PaperProps: {
              className: 'custom-menu-paper',
            },
          }}
          disabled={disabled}
          id={fieldName}
          sx={{
            minHeight: '40px',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${Colors.LIGHT_GRAY}`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: `${Colors.LIGHT_GRAY}`,
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${Colors.LIGHT_GRAY}`,
              border: `0.66px solid ${Colors.LIGHT_GRAY}`,
            },
          }}
          input={<OutlinedInput />}
          inputProps={{ sx: textInputFieldStyle }}
          multiple={multiSelect}
          fullWidth
          name={fieldName}
          value={selectedOptions}
          label=""
          renderValue={(selected) => (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 0.1,
              }}
            >
              {selected.map((value) => (
                <Chip
                  sx={
                    {
                      // zIndex: 99999,
                    }
                  }
                  key={value}
                  label={
                    options.find((option) => option.id === value)?.name || value
                  }
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(event) => {
                    handleChipDelete(value, event);
                  }}
                />
              ))}
            </Box>
          )}
          placeholder={placeholder}
          onChange={(event) => handleChange(event, setSelectedOptions, options)}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        <p style={errorStyle}>{errorText}</p>
      </div>
    </>
  );
};
