import { combineReducers } from 'redux';
import roleListsSlice, {
  resetRoleList,
  roleListSliceName,
} from '../slices/roleLists.slice';
import adminListSlice, {
  adminListSliceName,
  resetAdminList,
} from '../slices/adminList.slice';

import kycListSlice, {
  kycListSliceName,
  resetKycList,
} from '../slices/kycList.slice';

const rootReducer = combineReducers({
  [roleListSliceName]: roleListsSlice,
  [adminListSliceName]: adminListSlice,
  [kycListSliceName]: kycListSlice,
});

export const resetData = (dispatch) => {
  const resetActions = [resetRoleList, resetAdminList, resetKycList];

  resetActions.forEach((action) => dispatch(action()));
};

export default rootReducer;
