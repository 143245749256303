import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import OtpInput from 'react-otp-input';

import { Box, Link, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { style } from './style';
import { resendOtp, verifyOtp } from '../../../api/verifyOtp';
import { errorToaster, successToaster } from '../../../utils/helperUtility';
import { OTP_LENGTH } from './../../../common/constants/AppConstants';
import ENDPOINTS from '../../../constants/endpoints';
import { LanguageContext } from '../../../globalContext/LanguageSwitcher';

const VerifyPasswordForm = () => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState('');

  const location = useLocation();
  const { email, otpId } = location.state || {};
  const { language } = useContext(LanguageContext);

  const [focusedIndex, setFocusedIndex] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const initialCountdown = 59;
  const [countdown, setCountdown] = useState(initialCountdown);

  const [isDisabled, setIsDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const tick = () => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    };

    if (countdown > 0) {
      const timerId = setTimeout(tick, 1000);
      return () => clearTimeout(timerId);
    } else {
      setIsDisabled(false);
    }
  }, [countdown]);

  const handleFocus = (index) => {
    setFocusedIndex(otp.length);
  };

  const handleBlur = () => {
    setFocusedIndex(null);
  };

  const handleChange = (otp) => {
    setFocusedIndex(otp.length);
    setOtp(otp);
  };

  const onResendClick = async (event) => {
    event.preventDefault();
    setOtp('');
    setCountdown(initialCountdown);
    setIsDisabled(true);
    setIsLoading(true);
    try {
      const response = await resendOtp({
        data: {
          otp_id: otpId,
          email,
        },
      });
      if (response?.data?.isSuccess) {
        setIsLoading(false);
        successToaster(t('OTP_RESEND_MSG'));
      }
    } catch (err) {
      setIsLoading(false);
      errorToaster(err?.data?.message ?? t('SOME_ERROR_OCCOURED'));
      setOtp('');
    }
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await verifyOtp({
        data: {
          otpId,
          emailOtp: parseInt(otp),
        },
      });
      if (response?.data?.isSuccess) {
        setIsLoading(false);
        localStorage.setItem('authToken', response?.data?.authorization);
        redirectToPage(ENDPOINTS.RESET_PASSWORD, {
          email,
          from: ENDPOINTS.OPT_VERIFICATION,
        });
      }
    } catch (err) {
      setIsLoading(false);
      errorToaster(t('OPT_FAILURE_MSG'));
      setOtp('');
    }
  };

  const redirectToPage = (path, state) => {
    navigate(`/${path}`, { state });
  };

  const resendOtpIfExpired = (event) => {
    if (isDisabled) return;
    onResendClick(event);
  };

  return (
    <form>
      <Stack>
        <Typography
          marginTop={'10px'}
          maxWidth={'349px'}
          color={'#212121'}
          sx={style.subTitle(language)}
        >
          {t('OTP_VERIFY_SUB_TEXT')} {email}
        </Typography>
        <Stack marginX={'auto'} marginTop={'44px'}>
          <OtpInput
            value={otp}
            inputType="number"
            onChange={handleChange}
            numInputs={OTP_LENGTH}
            renderSeparator={<span style={{ width: '32px' }}></span>}
            renderInput={(props, index) => {
              const isFocused = focusedIndex === index;
              return (
                <input
                  {...props}
                  onFocus={() => handleFocus(index)}
                  onBlur={handleBlur}
                  style={{
                    ...style.inputStyle,
                    margin: 0,
                    backgroundColor:
                      isFocused || otp[index] ? '#FDFDFD' : 'transparent',
                    caretColor: 'transparent',
                  }}
                />
              );
            }}
            shouldAutoFocus
          />
          <Box marginTop={'4px'} marginLeft={'auto'}>
            <Typography
              color={'#777673'}
              fontSize={'13px'}
              fontFamily={'Inter'}
              marginTop={'4px'}
              marginLeft={'auto'}
            >
              {t('OTP_VERIFY_DIDNT_GET_OTP_TEXT')}
              <Link
                onClick={resendOtpIfExpired}
                disabled={isDisabled}
                sx={{
                  marginX: '3px',
                  cursor: 'pointer',
                }}
              >
                <Typography
                  component={'span'}
                  fontSize={'13px'}
                  fontFamily={'Inter'}
                  color={isDisabled ? '#777673' : '#373737'}
                  sx={{ textDecoration: 'underline' }}
                >
                  {t('OTP_RESEND_TEXT')}
                </Typography>
              </Link>
              in {countdown} {countdown > 2 ? 'secs' : 'sec '}
            </Typography>
          </Box>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={style.formButtonStyle}
          disabled={isLoading || otp.length !== OTP_LENGTH}
          onClick={handleOnSubmit}
          loading={isLoading}
        >
          {isLoading ? '' : t('VERIFY_OTP')}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default VerifyPasswordForm;
