import { useContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

import { AppProvider } from '@shopify/polaris';
import en from '@shopify/polaris/locales/en.json';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import ScrollToTop from './components/scroll-to-top';

import store from './redux/store/store';
import { LanguageContext } from './globalContext/LanguageSwitcher';
import './styles/global.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// ----------------------------------------------------------------------

export default function App() {
  const { language } = useContext(LanguageContext);

  return (
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <AppProvider i18n={language}>
            <ThemeProvider>
              <ScrollToTop />
              <Router />
            </ThemeProvider>
          </AppProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
