import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Colors } from '../../common/constants/Color';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import {
  successToaster,
  errorToaster,
  changeLabelTranslation,
} from '../../utils/helperUtility';
import AuthInput from '../../components/auth-input';
import { getCountryList } from '../../api/cityManagement';
import {
  formHelperStyle,
  textInputField,
  textInputFieldStyle,
} from '../../sections/auth/login/LoginForm.style';
import ENDPOINTS from '../../constants/endpoints';
import CountryWithFLag from '../../components/countryList/CountryList';
import LeftChevronIcon from '../..//assets/icons/left_chevron.svg';
import {
  APP_LANGUAGE,
  METAL_LABELS,
  METAL_TYPE_OPTIONS,
  STATUS_TYPE_OPTIONS,
  UNIT_LABELS,
  UNIT_OPTIONS,
} from '../../common/constants/AppConstants';
import { SelectComponent } from '../../components/add-user/SelectComponent';
import RadioInput from '../../components/radio-input/RadioInput';
import { addNewGiftSettings } from '../../schema/addNewGiftSettings';
import { configureSettings } from '../../api/giftSettings';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function AddNewSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const [countryList, setCountryList] = useState([]);
  const previousSavedData = useRef({});
  const [initialValues, setInitialValues] = useState({
    fk_countries_id: '',
    giftFeeValue: '',
    giftFeeUnit: '',
    metalType: '',
    status: true,
  });
  const query = useQuery();
  const serializedData = query.get('data');

  const metalTypeOptions = changeLabelTranslation(METAL_TYPE_OPTIONS, t);
  const statusTypeOptions = changeLabelTranslation(STATUS_TYPE_OPTIONS, t);
  const unitOptions = changeLabelTranslation(UNIT_OPTIONS, t);

  const isFieldError = (field) => {
    if (
      field === 'giftFeeValue' &&
      formik.values.giftFeeUnit === UNIT_LABELS.PERCENTAGE &&
      formik.values.giftFeeValue >= 100
    )
      return true;
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addNewGiftSettings,
    onSubmit: async (values) => {
      createPayload(values);
    },
  });

  useEffect(() => {
    if (serializedData) {
      try {
        previousSavedData.current = JSON.parse(
          decodeURIComponent(serializedData)
        );
      } catch (e) {
        errorToaster(t(`SOME_ERROR_OCCURED`));
        console.error('Failed to parse data:', e);
      }
    }
  }, [serializedData, t]);

  useEffect(() => {
    fetchListOfCountry();
  }, []);

  useEffect(() => {
    changeLabelForCountryList();
  }, [i18n?.language]);

  useEffect(() => {
    if (countryList.length > 0 && Object.keys(previousSavedData).length > 0) {
      setPreviousSavedSettings();
    }
  }, [countryList, previousSavedData]);

  const changeLabelForCountryList = () => {
    setCountryList((prev) => {
      const newCountryList = prev.map((country) => {
        if (!country?.value) {
          return {
            label: t('ALL'),
            value: '',
          };
        }
        return {
          ...country,
          label:
            i18n?.language === APP_LANGUAGE.ARABIC
              ? country.nameAr
              : country.nameEng,
        };
      });
      return newCountryList;
    });
  };

  const getCountryListStructuredObject = (countryList) => {
    const structuredCountryList = countryList.map((country) => ({
      id: country?.id,
      nameAr: country?.nameAr,
      nameEng: country?.nameEng,
      label:
        i18n?.language === APP_LANGUAGE.ARABIC
          ? country?.nameAr
          : country?.nameEng,
      value: country?.nameEng,
      mobileCode: country?.mobilecCode,
      currency: country?.currency,
    }));
    return structuredCountryList;
  };

  const fetchListOfCountry = async () => {
    setLoading(true);
    try {
      const { data } = await getCountryList();
      const structuredCountryList = getCountryListStructuredObject(data.data);
      setCountryList((prev) => [...prev, ...structuredCountryList]);
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
    setLoading(false);
  };

  const getCountry = (countryName) => {
    return countryList.find(
      (item) => item?.label.toUpperCase() === countryName.toUpperCase()
    );
  };

  const setPreviousSavedSettings = async () => {
    setLoading(true);
    try {
      const {
        country_nameAR,
        country_nameEN,
        gift_fee_value,
        gift_fee_unit,
        metal_type,
        status_key,
      } = previousSavedData.current;

      setInitialValues({
        fk_countries_id: getCountry(
          i18n?.language === APP_LANGUAGE.ARABIC
            ? country_nameAR
            : country_nameEN
        )?.id,
        giftFeeValue: gift_fee_value,
        giftFeeUnit:
          gift_fee_unit?.trim()?.toUpperCase() ===
          t(UNIT_LABELS.PERCENTAGE).toUpperCase()
            ? UNIT_LABELS.PERCENTAGE
            : UNIT_LABELS.GRAM,
        metalType:
          metal_type?.toUpperCase() === t(METAL_LABELS.GOLD).toUpperCase()
            ? METAL_LABELS.GOLD
            : METAL_LABELS.SILVER,
        status: status_key,
      });
    } catch (error) {
      console.error('Error fetching city details:', error);
    }
    setLoading(false);
  };

  const createPayload = async (values) => {
    try {
      setLoading(true);
      let { fk_countries_id, giftFeeValue, giftFeeUnit, metalType, status } =
        values;
      const data = previousSavedData.current?.id
        ? {
            payload: {
              fk_countries_id: fk_countries_id,
              gift_fee_value: Number(giftFeeValue),
              gift_fee_unit: giftFeeUnit,
              metal_type: metalType,
              is_active: status,
            },
            id: previousSavedData.current?.id,
          }
        : {
            payload: {
              fk_countries_id: fk_countries_id,
              gift_fee_value: Number(giftFeeValue),
              gift_fee_unit: giftFeeUnit,
              metal_type: metalType,
            },
          };
      const response = await configureSettings({
        ...{ data },
      });
      if (response?.data?.isSuccess) {
        successToaster(
          previousSavedData.current?.id
            ? t('SETTING_UPDATED_SUCCESS')
            : t('NEW_SETTINGS_ADDED'),
          language
        );
        navigate(ENDPOINTS.GIFT_SETTINGS);
      }
    } catch (err) {
      errorToaster(
        t(err.data?.message || err?.message || 'SOME_ERROR_OCCOURED'),
        language
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    formik.submitForm(event);
  };

  const getDisableCond = () => {
    return !(formik.isValid && formik.dirty);
  };

  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'status',
        value: value,
      },
    });
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.GIFT_SETTINGS)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>
          {previousSavedData.current?.id
            ? t('UPDATE_SETTINGS')
            : t('ADD_NEW_SETTINGS')}
        </FormTitle>
      </div>
      <FormContainer>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="row mb-4">
              <CountryWithFLag
                className="col-12 col-md-4"
                placeholderForCountry={t('SELECT_COUNTRY')}
                fieldName="fk_countries_id"
                multiSelect={false}
                labelName={t('Country')}
                options={countryList}
                selectedOptions={formik.values.fk_countries_id}
                formik={formik}
                htmlFor="fk_countries_id"
                label={'fk_countries_id'}
                sx={textInputField(isFieldError('fk_countries_id'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
                name="fk_countries_id"
                id="fk_countries_id"
                onChange={formik.handleChange}
                value={formik.values.fk_countries_id}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('fk_countries_id')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                required
                isUseLabel
                disabled={Object.keys(previousSavedData.current).length > 0}
                {...{ t }}
              />
            </div>
            <div className="row mb-2">
              <AuthInput
                className="col-12 col-md-4"
                htmlFor="giftFeeValue"
                label={t('GIFT_FEE_VALUE')}
                disabled={false}
                required
                sx={textInputField(isFieldError('giftFeeValue'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
                placeholder={t('ENTER_GIFT_FEE')}
                name="giftFeeValue"
                id="giftFeeValue"
                onChange={formik.handleChange}
                value={formik.values.giftFeeValue}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('giftFeeValue')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  (isFieldError('giftFeeValue') &&
                    t(formik.errors.giftFeeValue)) ||
                  (formik.values.giftFeeUnit === UNIT_LABELS.PERCENTAGE &&
                    formik.values.giftFeeValue > 100 &&
                    t('PERCENTAGE_OUT_OF_BOUND_ERROR'))
                }
              />
            </div>
            <div className="col-12 col-md-4 mb-4">
              <SelectComponent
                entityName="label"
                fieldName={'giftFeeUnit'}
                formik={formik}
                labelName={t('GIFT_FEE_UNIT')}
                placeholder={t('GIFT_FEE_UNIT')}
                language={language}
                options={unitOptions}
                value={formik.values.giftFeeUnit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                valueKey={'value'}
              />
            </div>
            <div className="col-12 col-md-4 mb-4">
              <SelectComponent
                entityName="label"
                fieldName={'metalType'}
                formik={formik}
                labelName={t('METAL_TYPE')}
                placeholder={t('PLACEHOLDER_METAL_TYPE')}
                language={language}
                options={metalTypeOptions}
                value={formik.values.metalType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                valueKey={'value'}
                disabled={Object.keys(previousSavedData.current).length > 0}
              />
            </div>
            <div className="col-12 col-md-4">
              <RadioInput
                parentLabel={t('STATUS')}
                required
                value={formik.values.status}
                textFieldError={isFieldError('status')}
                helperText={isFieldError('status')}
                options={statusTypeOptions}
                labelKey="status"
                handleChange={handleRadioValueChange}
              />
            </div>

            <FormButtons>
              <CancelButton onClick={() => navigate(ENDPOINTS.GIFT_SETTINGS)}>
                {t('CANCEL')}
              </CancelButton>

              <SubmitButton
                type="submit"
                onClick={handleSubmit}
                disabled={getDisableCond()}
              >
                {t('ADD_SETTINGS')}
              </SubmitButton>
            </FormButtons>
          </>
        )}
      </FormContainer>
    </div>
  );
}
