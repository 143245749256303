import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import { Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  EMAIL_MATCH,
  MAX_INPUT_LENGTH,
} from './../../../common/constants/AppConstants';
import {
  formButtonStyle,
  formHelperStyle,
  textInputField,
} from '../login/LoginForm.style';
import { errorToaster, successToaster } from '../../../utils/helperUtility';
import ENDPOINTS from '../../../constants/endpoints';
import { forgetPassword } from '../../../api/forgetPassword';
import AuthInput from '../../../components/auth-input';
import { LanguageContext } from '../../../globalContext/LanguageSwitcher';

export default function ForgetPasswordForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { language } = useContext(LanguageContext);

  const [formError, setFormError] = useState({
    isEmailError: false,
  });
  const [formData, setFormData] = useState({
    email: '',
  });

  const redirectToPage = (path, state) => {
    navigate(`/${path}`, { state });
  };

  // TODO: Check later why checkEmptyFields has no affect
  const validateForm = (_formData, checkEmptyFields = true) => {
    const isEmailError =
      _formData.email.match(EMAIL_MATCH) ||
      (checkEmptyFields && _formData.email === '')
        ? false
        : true;

    return {
      isValidated: !isEmailError,
      errorData: {
        isEmailError,
      },
    };
  };

  const handleFormData = (event) => {
    const _formData = {
      ...formData,
      [event.target.name]: event.target.value,
    };
    setFormData(_formData);
    const validationResult = validateForm(_formData);
    setFormError(validationResult.errorData);
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const validationResult = validateForm(formData, false);
    setFormError(validationResult.errorData);
    if (!validationResult.isValidated) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await forgetPassword({
        data: {
          email: formData.email,
        },
      });
      setIsLoading(false);

      if (response?.data?.isSuccess) {
        const otpId = response.data.otpId;
        redirectToPage(ENDPOINTS.OPT_VERIFICATION, {
          email: formData.email,
          otpId,
          from: ENDPOINTS.FORGET_PASSWORD,
        });
      } else {
        setIsLoading(false);
        successToaster(t('FORGET_PASSWORD_SUCCESS_MSG'));
      }
    } catch (err) {
      setIsLoading(false);

      errorToaster(t('FORGET_PASSWORD_FAILURE_MSG'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack sx={{ gap: '16px' }}>
        <Stack sx={{ gap: '44px' }}>
          <Stack>
            <AuthInput
              label={t('EMAIL_TEXT')}
              handleFormData={handleFormData}
              required={true}
              placeholder={t('ENTER_EMAIL_PLACEHOLDER')}
              textFieldError={formError.isEmailError}
              helperText={
                formError.isEmailError ? t('EMAIL_SHOULD_BE_VALID') : ''
              }
              name="email"
              id="email"
              sx={textInputField(formError.isEmailError, language)}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              maxLength={MAX_INPUT_LENGTH}
            />
          </Stack>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={formButtonStyle}
          disabled={
            !validateForm(formData, false).isValidated || formData.email === ''
          }
          loading={isLoading}
          onClick={handleOnSubmit}
        >
          {isLoading ? '' : t('SEND_OTP_BTN_TEXT')}
        </LoadingButton>
      </Stack>
    </form>
  );
}
