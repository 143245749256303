export const modalHeading = {
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '20px',
  color: '#303030',
};

export const modalSubDescription = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 550,
  lineHeight: '16px',
};
