import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addAnnouncementSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Field cannot be empty')
    .label('Title'),

  fk_countries_id: Yup.string().label('Country'),

  description: Yup.string()
    .min(3, 'Too Short!')
    .max(500, 'Too Long!')
    .required('Field cannot be empty')
    .label('description'),
});
