export const style = {
  tableHeadSx: {
    backgroundColor: '#F5F5F5',
    '& .MuiTableCell-root': {
      backgroundColor: '#F7F7F7',
      borderBottom: '1px solid #E3E3E3',
    },
  },
  tableHeadRowSx: {
    backgroundColor: '#F7F7F7',
  },
  rowDataSx: {
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  checkboxSx: {
    color: '#8A8A8A',
    '&.Mui-checked': {
      color: '#303030',
    },
  },
  titleSx: { color: '#616161', fontSize: '12px' },
  tableBodyRowSx: {
    '&.MuiTableRow-root.Mui-selected': {
      backgroundColor: '#fff',
    },
  },
  tableCellContentSx: {
    maxWidth: '150px', // You can adjust this value as needed
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&.MuiTableCell-root': {
      color: '#303030',
      fontSize: '12px',
      fontFamily: 'Inter',
      lineHeight: '20px',
      fontWeight: '500',
    },
  },
  paginationContainerSx: { backgroundColor: '#F7F7F7', paddingY: '6px' },
  pagiShowingSx: {
    color: '#C3C3C3',
    fontSize: 12,
    marginY: '6px',
    fontWeight: '500',
  },
  pagiNumSx: {
    fontSize: '12px',
    fontFamily: 'Inter',
    color: '#777673',
    fontWeight: '500',
    marginX: '2px',
  },
  textSx: {
    color: '#303030',
    fontSize: '12px',
    fontFamily: 'Inter',
    lineHeight: '20px',
    fontWeight: '500',
  },
  textSxHeading: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  nodataFoundText: {
    height: '250px',
    textAlign: 'center',
  },
};
