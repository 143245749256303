import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getKycList = (query, isDownload = false) => {
  const payload = {
    endpoint: API_ENDPOINTS.KYC_LIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  };
  if (isDownload) {
    payload.responseType = 'blob';
  }
  return apiRequest(payload);
};

export const getKycDetails = (id) => {
  const payload = {
    endpoint: `${API_ENDPOINTS.KYC_LIST}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  };
  return apiRequest(payload);
};

export const downloadFile = (id) => {
  const payload = {
    endpoint: `${API_ENDPOINTS.FILES}/${id}`,
    method: METHODS.GET,
    headers: {},
    responseType: 'blob',
    privateURL: false,
  };
  return apiRequest(payload);
};

export const approveRejectKyc = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.KYC_LIST}/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
