import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../../../../components/index-table/CommonTable';
import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';
import {
  successToaster,
  errorToaster,
  getModulePerm,
} from './../../../../utils/helperUtility';
import AuthInput from '../../../../components/auth-input';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../../../assets/icons/left_chevron.svg';
import {
  createRole,
  deleteRole as deleteRoleApi,
  editRole,
  getRoleDetails,
} from '../../../../api/roleList';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../../auth/login/LoginForm.style';
import { addRoleFormSchema } from '../../../../schema/addRole';
import RadioInput from '../../../../components/radio-input';
import SwitchButton from '../../../../components/switch-button';

import ENDPOINTS from '../../../../constants/endpoints';
import DeleteModal from '../../../../components/modal-components/delete-user-modal/DeleteModal';
import { userPageStyles } from '../../../../pages/userPageStyle';
import { Box, Typography } from '@mui/material';
import { containerStyle } from './style';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: '#E3CA7E',
  color: '#000000',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': { backgroundColor: '#E3CA7E' },
}));
const CancelButton = styled(Button)(({ theme }) => ({
  background: '#F4F3F3',
  color: '#3B3B3B',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': { backgroundColor: '#F4F3F3' },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',
    background: '#FFFFFF',
    height: 'auto',
    minHeight: '68vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});
const FormBody = styled('div')(({}) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  };
});
const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});
const PermissionsContainer = styled('div')(({}) => {
  return {
    marginTop: '44px',
    marginBottom: '24px',
  };
});
const TableTitle = styled('div')(({}) => {
  return {
    padding: '8px 22px',
    borderRadius: '8px 8px 0px 0px',
    borderBottom: '1px solid #E3E3E3',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  };
});
const roleColumns = [
  {
    key: 'moduleName',
    title: 'Role Name',
  },
  { key: 'moduleDesc', title: 'Description' },

  { key: 'renderButton', title: '', isComponent: true },
];

const resourceName = {
  singular: 'role',
  plural: 'roles',
};
export default function RoleForm({ isOpen, onClose }) {
  const navigate = useNavigate();
  const location = useLocation();
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const topRef = useRef(null);
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    isActive: true,
    permissions: [],
  });
  const [isSystemDefinedRoles, setSystemDefinedRoles] = useState(false);
  const isEditForm = () => {
    return !!query.get('roleId');
  };

  useEffect(() => {
    if (query.get('roleId')) {
      setWaitingForResponse(true);
      getRoleDetails(query.get('roleId'))
        .then(({ data }) => {
          if (data.data) {
            setSystemDefinedRoles(data.data.systemDefinedRole);
            const initVal = {
              name: data.data.name,
              description: data.data.description,
              isActive: data.data.isActive,
              permissions: data.data.permissions || [],
            };
            setInitialValues(initVal);
          }
        })
        .catch((err) => {
          errorToaster(err.data.message, language);
        })
        .finally(() => {
          setWaitingForResponse(false);
        });
    }
  }, []);
  const isViewMode = isEditForm() && !!query.get('view');

  const transformedData = initialValues.permissions?.map((role, index) => {
    const viewName = `${role.moduleCode}_view`;
    const addName = `${role.moduleCode}_add`;
    return {
      id: role.id,
      moduleCode: role.moduleCode,
      moduleDesc: role.moduleName,
      moduleName: role.moduleName,

      renderButton: () => (
        <div className="d-flex gap-2 align-items-center">
          <SwitchButton
            label={t('VIEW')}
            disabled={isViewMode}
            name={viewName}
            checked={role.readMode}
            handleChange={(event) => setPermission(event, 'readMode', index)}
          />
          <SwitchButton
            label={t('ADD')}
            disabled={isViewMode}
            name={addName}
            checked={role.writeMode}
            handleChange={(event) => setPermission(event, 'writeMode', index)}
          />
        </div>
      ),
    };
  });
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [waitingForResponse, setWaitingForResponse] = useState(false);
  const [open, setOpen] = useState(false);

  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addRoleFormSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let payload = {};
        let response = null;
        if (isEditForm()) {
          const permissionsPayload = initialValues.permissions?.map((item) => {
            return {
              id: item.id,
              readMode: item.readMode,
              writeMode: item.writeMode,
            };
          });
          payload = { ...values, permissions: permissionsPayload };
          response = await editRole(query.get('roleId'), { ...payload });
        } else {
          payload.name = values.name;
          payload.description = values.description;
          response = await createRole({ ...payload });
        }
        if (response.data.isSuccess) {
          successToaster(
            t(isEditForm() ? 'ROLE_UPDATED_SUCCESS' : 'ROLE_ADDED_SUCCESS'),
            language
          );
          navigate(ENDPOINTS.ROLE_MANAGEMENT);
        }
      } catch (err) {
        errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
      } finally {
        setLoading(false);
      }
    },
  });
  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'isActive', // The name of the field in Formik state
        value: value,
      },
    });
  };
  const setPermission = (event, key, index) => {
    initialValues.permissions[index][key] = event.target.checked;
  };

  const handleModalClose = () => {
    setOpen(false);
  };
  const handleCancelBtn = () => {
    navigate(ENDPOINTS.ROLE_MANAGEMENT);
  };

  const deleteRole = async (roleId) => {
    const roleData = {
      isActive: false,
    };
    try {
      const response = await deleteRoleApi(roleId, roleData);
      if (response?.data?.isSuccess) {
        successToaster(response?.data?.message, language);
      }
    } catch (error) {
      errorToaster(error.data.message, language);
    }
  };
  const handleConfirmDeleteClick = () => {
    deleteRole(query.get('roleId'));
    navigate(ENDPOINTS.ROLE_MANAGEMENT);
    setOpen(false);
  };
  const handleSubmit = (event) => {
    if (isViewMode) {
      if (isSystemDefinedRoles) {
        return;
      }

      if (topRef.current) {
        topRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
      navigate({
        pathname: ENDPOINTS.ROLE_FORM,
        search: `?roleId=${query.get('roleId')}`,
      });
      return;
    }
    formik.submitForm(event);
  };

  const currentModulePerm = getModulePerm('ROLE_MANAGEMENT');

  return (
    <div className="d-flex flex-column h-100">
      {waitingForResponse ? (
        <div style={containerStyle}>
          <h3>{t('LOADING')}</h3>
        </div>
      ) : (
        <>
          {' '}
          <div className="d-flex align-items-center gap-2" ref={topRef}>
            <div
              className="cursor-pointer"
              onClick={() => navigate(ENDPOINTS.ROLE_MANAGEMENT)}
            >
              <img src={LeftChevronIcon} />
            </div>
            <FormTitle>
              {isEditForm() ? initialValues?.name : t('CREATE_ROLE')}
            </FormTitle>
          </div>
          <FormContainer>
            <FormBody className="d-flex flex-column">
              <div className="row">
                <AuthInput
                  className="col-12 col-md-4"
                  htmlFor="name"
                  disabled={isViewMode}
                  label={t('ROLE_NAME')}
                  required={true}
                  sx={textInputField(isFieldError('name'), language)}
                  height="40px"
                  inputProps={{ sx: textInputFieldStyle, maxLength: 30 }}
                  placeholder={t('ENTER_ROLE_NAME')}
                  name="name"
                  id="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={formik.handleBlur}
                  textFieldError={isFieldError('name')}
                  FormHelperTextProps={{
                    sx: formHelperStyle,
                  }}
                  helperText={isFieldError('name') && t(formik.errors.name)}
                />
                <div
                  className={`${isEditForm() ? 'col-12 col-md-8' : 'col-12'}`}
                >
                  <AuthInput
                    disabled={isViewMode}
                    className={`${isEditForm() ? 'col-12' : 'col-12 col-md-8'}`}
                    htmlFor="description"
                    label={t('DESCRIPTION')}
                    required={true}
                    sx={textInputField(isFieldError('description'), language)}
                    height="40px"
                    inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
                    placeholder={t('ENTER_DESCRIPTION')}
                    name="description"
                    id="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    textFieldError={isFieldError('description')}
                    FormHelperTextProps={{
                      sx: formHelperStyle,
                    }}
                    helperText={
                      isFieldError('description') &&
                      t(formik.errors.description)
                    }
                  />
                </div>
              </div>
              {isEditForm() ? (
                <RadioInput
                  disabled={isViewMode}
                  parentLabel={t('STATUS')}
                  required={true}
                  value={formik.values.isActive}
                  textFieldError={isFieldError('isActive')}
                  helperText={isFieldError('isActive')}
                  options={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                  ]}
                  labelKey="isActive"
                  handleChange={handleRadioValueChange}
                />
              ) : null}
              {isEditForm() && !isSystemDefinedRoles ? (
                <PermissionsContainer>
                  <TableTitle>{t('PERMISSIONS')}</TableTitle>
                  <CommonTable
                    resourceName={resourceName}
                    data={transformedData || []}
                    columns={roleColumns}
                    isCheckBox={false}
                    loading={loading}
                    emptyTableText={t('NO_ROLE_FOUND')}
                  />
                </PermissionsContainer>
              ) : null}
            </FormBody>
            <FormButtons>
              <CancelButton onClick={handleCancelBtn}>
                {t('CANCEL')}
              </CancelButton>
              {currentModulePerm.writeMode && (
                <SubmitButton
                  type="submit"
                  onClick={handleSubmit}
                  disabled={!currentModulePerm.writeMode}
                  title={
                    isSystemDefinedRoles
                      ? t('NOT_ALLOWED_FOR_SYSTEM_ADMIN')
                      : ''
                  }
                  style={{
                    cursor: isSystemDefinedRoles ? 'not-allowed' : 'pointer',
                  }}
                >
                  {t(isEditForm() ? (isViewMode ? 'EDIT' : 'SAVE') : 'CREATE')}
                </SubmitButton>
              )}
            </FormButtons>
          </FormContainer>
          <DeleteModal
            isopen={open}
            handleClose={handleModalClose}
            toCloseOnOutsideModalClick={true}
            handleConfirmDeleteClick={handleConfirmDeleteClick}
          >
            <Box marginTop="32px">
              <Typography sx={userPageStyles.subHeadSx}>
                {t('DELETE_MODAL_SUB_HEAD')}
              </Typography>
              <Box display="flex" gap="16px" marginTop="16px">
                <Box
                  height={'56px'}
                  width={'56px'}
                  borderRadius={'50%'}
                  bgcolor={'lightGray'}
                ></Box>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                >
                  <Typography sx={userPageStyles.roleTextSx}>
                    {formik.values.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </DeleteModal>
        </>
      )}
    </div>
  );
}
