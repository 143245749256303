import { APP_LANGUAGE } from '../common/constants/AppConstants';

export const style = {
  loginFormTitle: (language) => ({
    fontSize: '24px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    lineHeight: '32px',
    textAlign: language === APP_LANGUAGE.ARABIC ? 'right' : 'left',
  }),
  backButton: {
    color: '#424242',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'montserrat',
    span: {
      marginRight: '-3px',
    },
  },
};

export const styledBox = {
  maxWidth: 480,
  margin: 'auto',
  maxHeight: '100vh',
  height: '100vh',
  display: 'flex',
  gap: '44px',
  justifyContent: 'center',
  flexDirection: 'column',
};
