export const headingFontStyle = {
  fontFamily: 'Lato',
  fontSize: '20px',
  fontWeight: '600',
  lineHeight: '20px',
  letterSpacing: '0',
  color: '#3B3B3B',
};

export const exitIconStyle = {
  width: 22,
  height: 22,
};

export const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};
