import React from 'react';
import { Card } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';
import GroupIcon from '@mui/icons-material/Group';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import './style.css';
import { numberWithCommas } from '../../utils/helperUtility';
function DashboardCard({ data }) {
  const iconMapping = {
    group: GroupRoundedIcon,
    inventory: InventoryRoundedIcon,
    transaction: AccountBalanceWalletRoundedIcon,
  };
  const IconComponent = iconMapping[data.icon] || GroupIcon;
  const { t, i18n } = useTranslation();

  return (
    <Card
      variant="outlined"
      sx={{ minHeight: '110px' }}
      style={{ backgroundColor: '#303030' }}
    >
      <CardContent className="" sx={{ padding: '20px 16px' }}>
        <div className="d-flex justify-content-between align-items-baseline">
          <div className="sub-title-label">{t(data.title)}</div>
          <div>
            <IconComponent className={`cardicon-dashboard ${data.iconColor}`} />
          </div>
        </div>
        <div>
          <div className="sub-title-value">
            {numberWithCommas(data.value ?? '0')}{' '}
            <span className={`${data.iconColor}`}>{t(data.helperText)}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default DashboardCard;
