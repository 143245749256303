const Production = require('./production');
const Development = require('./development');
const Staging = require('./staging');
const Uat = require('./uat');

const config = {
  production: Production,
  development: Development,
  staging: Staging,
  uat: Uat
};
// get app environment
const env = process.env.REACT_APP_BUILD_ENV || 'development';
const configFile = config[env] || config.development;

// export config file
module.exports = configFile;