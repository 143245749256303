import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getThirdPartyClientListing = ({ id }) => {
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_THIRD_PARTY_CLIENT_LISTING}/${id}`
      : `${API_ENDPOINTS.GET_THIRD_PARTY_CLIENT_LISTING}`,
    method: METHODS.GET,
    privateURL: true,
  });
};

export const configThirdPartyClientListing = ({ data }) => {
  const { payload, id } = data;
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_THIRD_PARTY_CLIENT_LISTING}/${id}`
      : `${API_ENDPOINTS.GET_THIRD_PARTY_CLIENT_LISTING}`,
    method: id ? METHODS.PUT : METHODS.POST,
    headers: {},
    data: payload,
    privateURL: true,
  });
};
