import './generate-report.css';
import { SelectComponent } from '../../components/add-user/SelectComponent';
import { useFormik } from 'formik';
import { generateReportSchema } from '../../schema/report';
import { generateReport, getReportTypes } from '../../api/reports';
import { useEffect, useState } from 'react';
import { Stack } from '@mui/system';
import validator from '@rjsf/validator-ajv8';
import { withTheme } from '@rjsf/core';
import Theme from '@rjsf/mui';
import {
  errorToaster,
  getFormattedDate,
  successToaster,
} from '../../utils/helperUtility';
import moment from 'moment';
import { ALL } from '../../common/constants/AppConstants';
import { endOfDay, startOfDay } from 'date-fns';
const Form = withTheme(Theme);

const updateJSONForm = (form) => {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  if (form?.properties?.start_date) {
    form.properties.start_date = {
      title: 'Start Date',
      default: getFormattedDate(startDate, 'YYYY-MM-DD'),
      ...form.properties.start_date,
    };
  }
  if (form?.properties?.end_date) {
    form.properties.end_date = {
      title: 'End Date',
      default: getFormattedDate(Date.now(), 'YYYY-MM-DD'),
      ...form.properties.end_date,
    };
  }
  return form;
};

export const GenerateReport = ({ t, language, setOpen, regenerateReport }) => {
  const [reportList, setReportList] = useState([]);
  const fetchReportList = async () => {
    const response = await getReportTypes();
    const reportList = response?.data?.data ?? [];
    setReportList(reportList);
  };
  useEffect(() => {
    fetchReportList();
  }, []);
  const formik = useFormik({
    validationSchema: generateReportSchema,
    initialValues: {
      reportType: '',
    },
  });
  const submitForm = async (result) => {
    const start_date = result?.formData?.start_date
      ? startOfDay(new Date(result.formData.start_date))
      : undefined;
    const end_date = result?.formData?.end_date
      ? endOfDay(new Date(result.formData.end_date))
      : undefined;
    const requestBody = {
      fkReportTypesId: formik.values.reportType,
      filterValues: {
        ...result.formData,
        start_date,
        end_date,
      },
    };
    try {
      const startDateFilter = result.formData?.start_date;
      const endDateFilter = result.formData?.end_date;
      if (startDateFilter || endDateFilter) {
        if (!(startDateFilter && endDateFilter)) {
          errorToaster(
            t('Either of start date or end date cannot be provided'),
            language
          );
          return;
        }
        if (moment(endDateFilter).diff(moment(startDateFilter), 'days') > 60) {
          errorToaster(
            t('Report greater then 60 days cannot be generated'),
            language
          );
          return;
        }
        if (
          moment(startDateFilter).isAfter(new Date()) ||
          moment(endDateFilter).isAfter(new Date())
        ) {
          errorToaster(t('Date cannot be greater than current date'), language);
          return;
        }
        if (moment(startDateFilter).isAfter(endDateFilter)) {
          errorToaster(
            t('Start Date Cannot be greater than End Date'),
            language
          );
          return;
        }
      }
      const response = await generateReport(requestBody);
      response.data.isSuccess
        ? successToaster(t('REPORT_REQUEST_ADDED'), language)
        : errorToaster(t('SOME_ERROR_OCCOURED'), language);
      regenerateReport();
      setOpen(false);
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };
  const report = reportList.find(
    (report) => report.id === formik.values.reportType
  )?.filters;
  const jsonReport = report ? JSON.parse(report) : {};
  return (
    <>
      <SelectComponent
        fieldName={'reportType'}
        formik={formik}
        labelName={t('Select the Report type to generate')}
        placeholder={t('Report Type')}
        language={language}
        options={reportList.map((report) => ({
          id: report?.id,
          name: report?.label,
        }))}
        value={formik.values.reportType}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Form
        validator={validator}
        onSubmit={(formData) => submitForm(formData)}
        uiSchema={{
          'ui:title': formik.values.reportType ? t('Filters') : undefined,
          'ui:submitButtonOptions': {
            props: {
              className: 'btn-info',
            },
            norender: !formik.values.reportType,
            submitText: t('Generate'),
          },
          'ui:inputType': {
            props: {
              marginBottom: '10px',
            },
          },
          ...Object.keys(jsonReport?.properties ?? {}).reduce(
            (uiSchema, inputField) => {
              uiSchema[inputField] = {
                'ui:placeholder': ALL,
                'ui:classNames': 'input-field-rjsf',
              };
              return uiSchema;
            },
            {}
          ),
        }}
        schema={{
          type: 'object',
          ...(Object.keys(jsonReport?.properties ?? []).length
            ? updateJSONForm(jsonReport)
            : {}),
        }}
      />

      <Stack
        direction={'row'}
        columnGap={'13px'}
        justifyContent={'flex-end'}
        marginTop={'17px'}
        marginBottom={'-32px'}
      ></Stack>
    </>
  );
};
