import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';

export const schemaToConfigClient = Yup.object().shape({
  name: Yup.string()
    .test(
      'check-min-length',
      'You name is too short!',
      (value) => value.length > 3
    )
    .test(
      'check-max-length',
      'Your name is too long!',
      (value) => value.length <= 50
    )
    .test(
      'check-alphabets',
      'Digits and special characters (%,#,*,@) are not allowed.',
      (value) => REGEX_PATTERN.ALPHABET_ONLY_WITH_SPACE.test(value)
    )
    .required('Field cannot be empty')
    .label('name'),
});
