import { useEffect, useState } from 'react';
import { Card, Stack, Typography, Container, Box } from '@mui/material';
import CommonTable from '../../components/index-table/CommonTable';
import { getDashboardData } from '../../api/dashboard';
import { getOrderList } from '../../api/order';
import { useTranslation } from 'react-i18next';
import { getFormattedDate, getPhone } from '../../utils/helperUtility';
import { NA } from '../../common/constants/AppConstants';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import './style.css';
import { orderStatusConstant } from '../order-details-page/orderConstant';
import DashboardCard from './DashboardCard';
import { resourceName, getModifiedArray } from './DashboardConstant';
export default function DashboardAppPage() {
  const { t, i18n } = useTranslation();
  const [tableData, setTableData] = useState([]);
  const [dashboardData, setDashboarddata] = useState();
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(5);

  useEffect(() => {
    getTableData();
    getAllData();
  }, []);

  const getAllData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getDashboardData({});
      setDashboarddata(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
      setLoading(false);
    }
  };

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getOrderList({
        pageLimit: limit,
        pageSkip: 0,
      });
      setTableData(data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
      setLoading(false);
    }
  };

  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;

  const currentItems = tableData.map((order) => ({
    id: order.id,
    orderNo: <Box>{order.orderNo ?? NA}</Box>,
    customerInfo: (
      <Box className="boxContent">
        {`${getPhone(order)},` ?? ''}
        <br />
        {`${order.email}` ?? ''}
      </Box>
    ),
    itemCount: order.numberOfItems ?? NA,
    totalPrice: order.orderValue ? `${order.orderValue} ${order.currency}` : NA,
    status: orderStatusConstant[order.status] ?? NA,
    createdAt: getFormattedDate(order.createdAt, 'DD/MM/YYYY, HH:mm') ?? NA,
    category: order.category ?? NA,
    deliveryMode: orderStatusConstant[order.deliveryMode] ?? NA,
  }));

  const roleColumns = [
    {
      key: 'orderNo',
      title: t('Order ID'),
      requiredStyle: { minWidth: '160px' },
    },
    {
      key: 'customerInfo',
      title: t('Customer Info'),
      requiredStyle: {
        minWidth: '160px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-all',
      },
    },
    {
      key: 'itemCount',
      title: t('Item Count'),
    },
    {
      key: 'totalPrice',
      title: t('Total Price'),
    },
    {
      key: 'deliveryMode',
      title: t('Delivery Type'),
    },
    {
      key: 'status',
      title: t('TH_STATUS'),
    },
    {
      key: 'createdAt',
      title: t('Date & Time'),
    },
  ];

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={'35px'}
      >
        <Typography variant="h4" gutterBottom></Typography>
      </Stack>

      <Card sx={{ padding: 2 }}>
        <div className="row mb-4 ">
          {dashboardData &&
            getModifiedArray(dashboardData).map((item, index) => {
              return (
                <div className="col-12 col-md-4 mb-2" key={index}>
                  <DashboardCard data={item} />
                </div>
              );
            })}
        </div>

        <div className="row  ms-2 mb-4" dir={isArabic ? 'rtl' : 'ltr'}>
          <div className="card-title-label">{t('Latest Orders Details')} </div>
        </div>

        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={roleColumns}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_ORDER_FOUND')}
        />
      </Card>
    </Container>
  );
}
