export const initialFormValues = {
  profileImageDocumentId: null,
  address: '',
  kycDetails: null,
  bankDetails: null,
  zipCode: '',
  dateOfBirth: '',
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneNo: '',
  account_manager_id: '',
  status: '',
  countryId: '',
  gender: '',
  city: '',
  locality: '',
  name: '',
  walletBalance: '',
  goldBalance: '',
  silverBalance: '',
  currency: '',
};

export const allowedValuesForEdit = [
  'firstName',
  'isActive',
  'lastName',
  'middleName',
  'account_manager_id',
];
