import { createSlice } from '@reduxjs/toolkit';
import { getRoleList as getRoleListApi } from '../../api/roleList';
import asyncActionCreator from '../../common/utils/asyncActionCreator';

export const roleListSliceName = 'roleLists';

export const getRoleList = asyncActionCreator(
  `${roleListSliceName}/list`,
  getRoleListApi,
  {
    doNotNotify: true,
    doNotLoad: true,
  }
);

const initialState = {
  loading: true,
  error: false,
  data: [],
  totalList: 0,
  limit: 10,
};

const getRoleListSlice = createSlice({
  name: roleListSliceName,
  initialState,
  reducers: {
    resetRoleList: (state) => {
      state.data = {
        ...initialState.data,
        list: { ...initialState.data },
      };
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.totalList = initialState.totalList;
      state.limit = initialState.limit;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRoleList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getRoleList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload.data || [];
        state.totalList = action.payload.totalCount;
        state.limit = action.payload.limit;
      })
      .addCase(getRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetRoleList } = getRoleListSlice.actions;

export default getRoleListSlice.reducer;
