import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  InputLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import {
  inputLabelStyle,
  textInputField,
  textInputFieldStyle,
  formButtonStyle,
  linkTextStyle,
  formHelperStyle,
} from './LoginForm.style';
import ENDPOINTS from '../../../constants/endpoints';
import { login } from './../../../api/auth';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageContext } from '../../../globalContext/LanguageSwitcher';
import notify from './../../../components/notify';
import { loginSchema } from './../../../schema/login';
import { useFormik } from 'formik';
import { LOCALSTORAGE_KEYS } from '../../../constants/localStorageConstant';
import { getUserDetails, getAccountManagerDetails } from '../../../api/user';
import { AccountManagerPermission } from '../../../common/constants/AppConstants';
import {
  hasDashboardAccess,
  getRespectiveUrl,
} from '../../../utils/helperUtility';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await login({
          data: {
            email: values.email,
            password: values.password,
          },
        });
        const allowedRoles = ['ADMIN', 'ACCOUNT_MANAGER'];
        if (!allowedRoles.includes(response.data.user?.role)) {
          notify(t('USER_MUST_BE_ADMIN'));
          return;
        }
        if (response?.data?.authorization) {
          window.localStorage.setItem(
            LOCALSTORAGE_KEYS.AUTH_TOKEN,
            response.data.authorization
          );
          window.localStorage.setItem(
            LOCALSTORAGE_KEYS.USER_ID,
            response.data.user.userId
          );

          const res = await getUserDetails(response.data.user.userId);

          if (response.data.user?.role === 'ACCOUNT_MANAGER') {
            const userDetails = {
              ...res.data?.data,
              permissions: AccountManagerPermission,
              systemDefinedRole: false,
            };

            window.localStorage.setItem(
              'userDetails',
              JSON.stringify({ ...userDetails })
            );
          } else {
            // const res = await getUserDetails(response.data.user.userId);
            let systemDefinedRole = false;
            (res.data?.data?.role || []).every((item) => {
              if (item.systemDefinedRole) {
                systemDefinedRole = true;
                return false;
              }
              return true;
            });

            window.localStorage.setItem(
              'userDetails',
              JSON.stringify({ ...res.data?.data, systemDefinedRole })
            );
          }
          const lastLogin = response.data.user.lastLoginTime;
          if (lastLogin) {
            if (hasDashboardAccess()) {
              return navigate(ENDPOINTS.DASHBOARD);
            } else {
              const respUrl = getRespectiveUrl();
              if (respUrl) {
                return navigate(respUrl);
              }
            }
          } else {
            window.localStorage.setItem('changePasswordRequired', true);
            navigate(`/${ENDPOINTS.RESET_PASSWORD}`);
          }
          return;
        }
      } catch (err) {
        notify(t(err?.data?.message), language);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isEmailError() || isPasswordError()) {
      return;
    }
    formik.submitForm(formik.values);
  };

  const isEmailError = () => {
    if (!formik.touched.email && formik.values.email === '') return true;
    return !!formik.errors.email;
  };

  const isPasswordError = () => {
    if (!formik.touched.password && formik.values.password === '') return true;
    return !!formik.errors.password;
  };

  return (
    <form>
      <Stack sx={{ gap: '14px' }}>
        <Stack sx={{ gap: '12px' }}>
          <Stack>
            <InputLabel htmlFor="email" sx={inputLabelStyle(language)} required>
              <span className="asterisk-content">{t('EMAIL_TEXT')}</span>
            </InputLabel>
            <TextField
              sx={textInputField(
                isEmailError() && formik.values.email !== '',
                language
              )}
              height="40px"
              inputProps={{
                sx: textInputFieldStyle,
                maxLength: 50,
                autoComplete: 'off',
              }}
              placeholder={t('ENTER_EMAIL_PLACEHOLDER')}
              name="email"
              id="email"
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              error={isEmailError() && formik.values.email !== ''}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                // prettier-ignore
                isEmailError() && formik.values.email !== '' ? t('EMAIL_ERROR_MESSAGE') : ' '
              }
              onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
                event.target.value = '';
              }}
            />
          </Stack>
          <Stack>
            <InputLabel
              htmlFor="password"
              sx={inputLabelStyle(language)}
              required
            >
              <span className="asterisk-content">{t('PASSWORD_TEXT')}</span>
            </InputLabel>
            <TextField
              name="password"
              id="password"
              sx={textInputField(
                isPasswordError() && formik.values.password !== '',
                language
              )}
              type={showPassword ? 'text' : 'password'}
              placeholder={t('ENTER_PASSWORD_PLACEHOLDER')}
              inputProps={{
                sx: textInputFieldStyle,
                maxLength: 20,
                autoComplete: 'off',
              }}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={isPasswordError() && formik.values.password !== ''}
              onChange={formik.handleChange}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={
                // prettier-ignore
                isPasswordError() && formik.values.password !== "" ? t('PASSWORD_ERROR_MESSAGE') : ' '
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={
                          showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                        }
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onFocus={(event) => {
                event.target.setAttribute('autocomplete', 'off');
                event.target.value = '';
              }}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          sx={{ height: '16px' }}
        >
          <Link
            variant="subtitle2"
            underline="hover"
            sx={linkTextStyle}
            onClick={() => navigate(ENDPOINTS.FORGET_PASSWORD)}
          >
            {t('FORGOT_PASSWORD_TEXT')}
          </Link>
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={formButtonStyle}
          loading={loading}
          disabled={isEmailError() || isPasswordError() || loading}
          onClick={handleSubmit}
        >
          {loading ? '' : t('LOGIN')}
        </LoadingButton>
      </Stack>
    </form>
  );
}
