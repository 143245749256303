import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import config from '../../config';
import API_ENDPOINTS from '../../constants/apiEndpoints';
import { useTranslation } from 'react-i18next';
import ImageSlider from '../image-preview/ImagePreview';
import './cardStyle.css';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

export default function OutlinedCard({ className, data, currency }) {
  const { t } = useTranslation();

  const returnUrl = (id) => {
    if (!id) return;
    const { API } = config;
    const BASE_URL = API.REACT_APP_BASE_URL;
    const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
    return url;
  };

  const returnImageList = () => {
    const list = data.images.map((item) => {
      return { url: returnUrl(item) };
    });
    return list;
  };
  const returnSubTotal = () => {
    if (!data || !data.unitMetalPrice || !data.quantity || !data.weight) {
      return '';
    } else {
      const total = data.unitMetalPrice * data.quantity * data.weight;
      return `${total.toFixed(2)} ${currency || ''}` || 'NA';
    }
  };
  const returnMakingCharges = () => {
    if (!data || !data?.makingCharge) return '';

    return `${data.makingCharge} ${currency || ''}` || 'NA';
  };

  const returnAdditionalCharges = () => {
    if (!data || !data?.additionalCost) return '';

    return `${data?.additionalCost} ${currency || ''}` || 'NA';
  };

  const returnTotalPrice = () => {
    if (!data || !data?.totalPrice) return '';
    return `${data.totalPrice} ${currency || ''}` || 'NA';
  };
  return (
    <div className={className}>
      <Card variant="outlined" sx={{ height: '320px' }}>
        <React.Fragment>
          <CardContent>
            <div className="row">
              <div className="col-3 d-flex justify-content-center align-items-center">
                <ImageSlider imageList={returnImageList()} />
              </div>
              <div className="col-9 mt-1">
                <div className="row">
                  <div className="col-6">
                    <div className="tileHeading">{t('PRODUCT_NAME')}</div>
                    <div className="tileValue">{data.name || 'NA'}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('PRODUCT_ID_SKU')}</div>
                    <div className="tileValue">{data.sku || 'NA'}</div>
                  </div>
                  <div className="col-6">
                    <div className="tileHeading">{t('METAL_TYPE')}</div>
                    <div className="tileValue">
                      {`${data.metalType} (${data.purity})` || 'NA'}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="tileHeading">{t('QUANTITY')}</div>
                    <div className="tileValue">{data.quantity || 'NA'}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('WEIGHT')}</div>
                    <div className="tileValue">
                      {`${data.weight} gm` || 'NA'}
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('CATEGORY_AD')}</div>
                    <div className="tileValue">{data.category || 'NA'}</div>
                  </div>
                  <div className="col-6">
                    <div className="tileHeading">{t('SUB_TOTAL')}</div>
                    <div className="tileValue">{returnSubTotal()}</div>
                  </div>
                  <div className="col-6">
                    <div className="tileHeading">{t('MAKING_CHARGE')}</div>
                    <div className="tileValue">{returnMakingCharges()}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('ADDITIONAL_CHARGE')}</div>
                    <div className="tileValue">{returnAdditionalCharges()}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('TOTAL')}</div>
                    <div className="tileValue">
                      {returnTotalPrice() || 'NA'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </React.Fragment>
      </Card>
    </div>
  );
}
