import { RadioButton } from '@shopify/polaris';
import { InputLabel, Stack } from '@mui/material';
import { inputLabelStyle } from '../auth-input/style';
import { useContext } from 'react';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';

import './style.css';
export default function RadioInput({
  parentLabel,
  options,
  labelKey,
  containerSx,
  handleChange,
  required,
  value,
  setFieldValue,
  ...props
}) {
  const { language } = useContext(LanguageContext);
  const customHandleChange = (val) => {
    handleChange(val);
  };
  return (
    <Stack sx={containerSx}>
      <InputLabel htmlFor="email" sx={inputLabelStyle(language)} required>
        <span className={required ? 'asterisk-content' : null}>
          {parentLabel}
        </span>
      </InputLabel>
      <div>
        {options.map((option, index) => {
          return (
            <RadioButton
              label={option.label}
              checked={value === option.value}
              id={`radio-input-${index}`}
              key={`radio-input-${option.value}`}
              name={option.name}
              onChange={() => customHandleChange(option.value)}
              {...props}
            />
          );
        })}
      </div>
    </Stack>
  );
}
