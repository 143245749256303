// component
// import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

// const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
import {
  HomeIcon,
  CollectionFilledIcon,
  OrderFilledIcon,
  PersonFilledIcon,
  BankFilledIcon,
  ImageIcon,
} from '@shopify/polaris-icons';
import ENDPOINTS from '../../../constants/endpoints';
import { MODULE_CONST } from '../../../common/constants/ModuleConstant';

const createConfig = (handleNavigation, selectedItem) => {
  const userPermissions = JSON.parse(localStorage.getItem('userDetails')) || {};
  const navModules = [
    {
      excludeurls: ['#'],
      label: 'HOME',
      moduleCode: 'DASHBOARD',
      icon: HomeIcon,
      onClick: handleNavigation(ENDPOINTS.HOME),
      selected: 'HOME' === selectedItem,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ADMIN_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ADMIN_MANAGEMENT),
      label: 'ADMIN_MANAGEMENT',
      icon: CollectionFilledIcon,
      moduleCode: 'ADMIN_MANAGEMENT',
    },
    {
      excludeurls: ['#'],
      label: 'ACCOUNT_MANAGEMENT',
      moduleCode: 'ACCOUNT_MANAGER',
      selected: MODULE_CONST.ACCOUNT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ACCOUNT_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.USER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.USER_MANAGEMENT),
      label: 'USER_MANAGEMENT',
      icon: OrderFilledIcon,
      moduleCode: 'USER_MANAGEMENT',
    },

    {
      selected: MODULE_CONST.ROLE_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ROLE_MANAGEMENT),
      superAdminAccess: true,
      excludeurls: ['#'],
      label: 'ROLE_MANAGEMENT',
      moduleCode: 'ROLE_MANAGEMENT',
      icon: OrderFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TRANSACTION_HISTORY === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TRANSACTION_MANAGEMENT),
      label: 'TRANSACTIONS',
      icon: PersonFilledIcon,
      moduleCode: 'TRANSACTION_HISTORY',
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.VERIFICATION_APPROVAL === selectedItem,
      onClick: handleNavigation(ENDPOINTS.KYC_MANAGEMENT),
      label: 'VERIFICATION_APPROVAL',
      icon: PersonFilledIcon,
      moduleCode: 'VERIFICATION_APPROVAL',
    },
    {
      excludeurls: ['#'],
      label: 'PRODUCT_MANAGEMENT',
      moduleCode: 'PRODUCT_MANAGEMENT',
      selected: MODULE_CONST.PRODUCT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.PRODUCT_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      label: 'ORDER_MANAGEMENT',
      moduleCode: 'ORDER_MANAGEMENT',
      selected: MODULE_CONST.ORDER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ORDER_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.WAREHOUSE_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.WAREHOUSE_MANAGEMENT),
      label: 'WAREHOUSE_MANAGEMENT',
      moduleCode: 'WAREHOUSE_MANAGEMENT',
      icon: ImageIcon,
    },

    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ACCOUNT_DELETE_REQUEST === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ACCOUNT_LIST),
      label: 'ACCOUNT_DELETE_REQUEST',
      moduleCode: MODULE_CONST.USER_MANAGEMENT,
      icon: PersonFilledIcon,
    },
    {
      excludeurls: ['#'],
      label: 'Report Management',
      moduleCode: 'REPORTING_ANALYTICS',
      selected: MODULE_CONST.REPORTING_ANALYTICS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.REPORT_MANAGEMENT),
      icon: BankFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TRANSFER_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TRANSFER_LIST),
      label: 'TRANSFER_GOLD_SILVER',
      moduleCode: MODULE_CONST.TRANSFER_MANAGEMENT,
      icon: PersonFilledIcon,
    },

    {
      excludeurls: ['#'],
      selected: MODULE_CONST.ANNOUNCEMENT_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.ANNOUNCEMENT_LIST),
      label: 'ANNOUNCEMENT_MANAGEMENT',
      moduleCode: MODULE_CONST.ANNOUNCEMENT_MANAGEMENT,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.TICKET_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.TICKET_MANAGEMENT),
      label: 'TICKET_MANAGEMENT',
      moduleCode: MODULE_CONST.TICKET_MANAGEMENT,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.WITHDRAWALS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.WITHDRAWALS),
      label: 'WITHDRAWALS',
      moduleCode: MODULE_CONST.WITHDRAWALS,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.BANK_TRANSFERS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.BANK_TRANSFERS),
      label: 'BANK_TRANSFERS',
      moduleCode: MODULE_CONST.BANK_TRANSFERS,
      icon: CollectionFilledIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.CITY_MANAGEMENT === selectedItem,
      onClick: handleNavigation(ENDPOINTS.CITY_MANAGEMENT),
      label: 'CITY_MANAGEMENT',
      moduleCode: MODULE_CONST.CITY_MANAGEMENT,
      icon: ImageIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.GIFT_SETTINGS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.GIFT_SETTINGS),
      label: 'GIFT_SETTINGS',
      moduleCode: MODULE_CONST.GIFT_SETTINGS,
      icon: HomeIcon,
    },
    {
      excludeurls: ['#'],
      selected: MODULE_CONST.THIRD_PARTY_CLIENTS === selectedItem,
      onClick: handleNavigation(ENDPOINTS.THIRD_PARTY_CLIENTS_LISTING),
      label: 'THIRD_PARTY_CLIENTS',
      icon: OrderFilledIcon,
      moduleCode: 'THIRD_PARTY_CLIENTS',
    },
  ];
  if (userPermissions.systemDefinedRole) {
    return navModules;
  }
  return navModules.filter((navItem) => {
    if (navItem.moduleCode === 'HOME') return true;
    const modulePermission =
      userPermissions.permissions?.find(
        (userPerm) => userPerm.moduleCode === navItem.moduleCode
      ) || {};
    return (
      (navItem.isSuperAdminAccess && userPermissions.systemDefinedRole) ||
      modulePermission?.readMode ||
      modulePermission?.writeMode
    );
  });
};

export default createConfig;
