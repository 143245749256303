import React, { createContext, useState } from 'react';
import {
  APP_LANGUAGE,
  SESSION_LANGUAGE_KEY,
} from './../common/constants/AppConstants';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLang] = useState(
    localStorage.getItem(SESSION_LANGUAGE_KEY)
      ? localStorage.getItem(SESSION_LANGUAGE_KEY)
      : APP_LANGUAGE.ENGLISH
  );
  const setLanguage = (lang) => {
    localStorage.setItem(SESSION_LANGUAGE_KEY, lang);
    setLang(lang);
  };
  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
