import { Box } from '@mui/material';
import { Header } from './../components/add-user/Header';
import { heading } from './../components/add-user/AddUser.style';
import WarehouseForm from './../components/warehouse/WarehouseForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ENDPOINTS from '../constants/endpoints';
import { useTranslation } from 'react-i18next';

export default function () {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const isCreateMode = params.get('id') == null;
  const { t } = useTranslation();
  return (
    <Box marginX="24px">
      <Header
        headingStyle={heading}
        onClick={() => navigate(`${ENDPOINTS.WAREHOUSE_MANAGEMENT}`)}
      >
        {t(isCreateMode ? 'Create Warehouse' : 'Warehouse Details')}
      </Header>
      <WarehouseForm width="100%" minHeight="480px"></WarehouseForm>
    </Box>
  );
}
