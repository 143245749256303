import API_ENDPOINTS from '../constants/apiEndpoints';
import apiRequest, { METHODS } from './apiRequest';

export const getProductList = (query) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.PRODUCT_LIST,
    query,
    method: METHODS.GET,
    privateURL: true,
  });
};
