const API_ENDPOINTS = {
  FORGET_PASSWORD: '/auth/forgot-pwd',
  VERIFY_OTP: '/auth/otp-verify',
  RESET_PASSWORD: '/users/reset-pwd',
  RESEND_OTP: '/auth/otp-resend',
  LOGIN: '/auth/login',
  ROLES: '/roles',
  USER_ROLE_LIST: '/roles',
  USER: '/users',
  ADMIN_USER_LIST: '/admin/users',
  ADMIN_USER_DETAILS: '/admin/users/',
  ADD_ROLE: '/roles',
  GET_ROLE: '/roles',
  DELETE_ROLE: 'roles',
  ROLES: '/roles',
  ADMINLIST: '/admin/users',
  CREATE_ADMIN: '/auth/admin/users',
  CREATE_ACCOUNT_MANAGER: '/admin/account-managers',
  GET_COUNTRIES: '/masters/countries',
  UPDATE_ADMIN: 'admin/users/',
  KYC_LIST: '/admin/kyc',
  COUNTRIES: 'masters/countries',
  WAREHOUSE: '/warehouse',
  WAREHOUSES: '/warehouses',
  FILES: '/files',
  WALLET_TRANSACTION: '/wallet-transactions',
  TRANSACTION_LIST: '/admin/transaction',
  CREATE_PRODUCT: '/products',
  PRODUCT_LIST: '/products',
  ORDER_LIST: '/orders',
  REPORT: '/reports',
  REPORT_TYPES: '/report-types',
  BULK_UPDATE: '/products/bulk-update',
  ACCOUNT_LIST: '/delete-account-requests',
  METAL_TRANSFER: '/metal-transfers',
  DASHBOARD: '/admins/dashboard',
  CREATE_ANNOUNCEMENT: '/announcements',
  TICKETS_LIST: '/support-tickets',
  BANK_TRANSFER: '/bank-transfers',
  MANUAL_DEPOSIT: '/wallets/manual',
  UPDATE_TICKET_STATUS: '/support-ticket',
  GET_CITY: '/masters/cities',
  GET_GIFT_SETTINGS: '/gifts',
  WITHDRAWAL_REQUEST: '/wallet/withdrawals',
  GET_THIRD_PARTY_CLIENT_LISTING: '/clients',
};

export default API_ENDPOINTS;
