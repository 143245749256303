import { Box } from '@mui/material';
import { useEffect, useState, useContext } from 'react';

import { Navigation } from '@shopify/polaris';
import createConfig from './config';
import { SidebarContext } from '../../../globalContext/SideBar';
import './sideNavBar.css';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const StyledBox = styled('div')(() => ({
  height: '32px',
  padding: '6px 12px 6px 12px',
  maxWidth: 'calc(15rem + env(safe-area-inset-left) - 12px)',
  borderRadius: '8px',
  border: '1px solid #8A8A8A',
  background: '#ffffff',
  fontWeight: 450,
  fontSize: '13px',
  color: '#616161',
  margin: '16px 12px 0 12px',
}));

export default function SideNavBar() {
  const { t } = useTranslation();
  const { selectedNav, selectNavItem } = useContext(SidebarContext);
  const navigate = useNavigate();
  const handleNavigation = (url) => {
    return (event) => {
      selectNavItem(url);
      event?.preventDefault(); // Prevent the default anchor behavior
      navigate(url); // Use navigate to change the route
    };
  };
  const [navConfig, setNavConfig] = useState([
    ...createConfig(handleNavigation, null),
  ]);

  useEffect(() => {
    const newConfig = createConfig(handleNavigation, selectedNav).map(
      (navItem) => {
        return {
          ...navItem,
          label: t(navItem.label),
        };
      }
    );
    setNavConfig([...newConfig]);
  }, [selectedNav, t]);
  return (
    <Box>
      <StyledBox>{t('Super Admin')}</StyledBox>
      <Navigation location="/">
        {navConfig?.length > 0 ? (
          <Navigation.Section items={navConfig} />
        ) : null}
      </Navigation>
    </Box>
  );
}
