import React, { useRef } from 'react';
import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { SelectComponent } from '../../components/add-user/SelectComponent';
import { Box } from '@mui/material';
import InputFileUpload from '../../components/file-upload';

import {
  successToaster,
  errorToaster,
  getModulePerm,
} from '../../utils/helperUtility';

import AuthInput from '../../components/auth-input';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import {
  createProduct,
  editProduct,
  getProductDetails,
  uploadFile,
  updateQuantity,
} from '../../api/add-edit-product';

import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import config from '../../config';
import API_ENDPOINTS from '../../constants/apiEndpoints';

import { addproductSchema } from '../../schema/addProduct';
import ENDPOINTS from '../../constants/endpoints';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import RadioInput from '../../components/radio-input';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddInventory from './AddInventory';
import { Colors } from '../../common/constants/Color';
import DocumentsView from '../../components/DocumentView/DocumentsView';
import DeleteModal from '../../components/modal-components/delete-user-modal/DeleteModal';
import { userPageStyles } from '../userPageStyle';
import { MAX_LONG_TEXT_INPUT_LENGTH } from '../../common/constants/AppConstants';
import CountryWithFLag from '../../components/countryList/CountryList';
import { getCountriesList } from '../../api/adminManagement';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    // maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',
    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    marginTop: '30px',
  };
});

export default function AddProduct() {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('ADD_NEW_PRODUCT');
  const [productImages, setProductImages] = useState([]);
  const [listViewImages, setListViewImages] = useState([]);
  const [productData, setProductData] = useState({});
  const [popUpHeading, setPopUpHeading] = useState('INACTIVE_HEADING');
  const fileInputRef = useRef(null);
  const fileInputRefhallmark = useRef(null);
  const [countries, setCountries] = useState([]);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const [initialValues, setInitialValues] = useState({
    productName: '',
    nameAr: '',
    descriptionAr: '',
    product_id_sku: '',
    description: '',
    category: '',
    purity: '',
    weight: '',
    quantity: '',
    makingCharge: '',
    additionalcost: '',
    isActive: true,
    metalType: '',
    hallmark: '',
    productImages: [],
    fk_countries_id: '',
  });

  const productId = () => {
    return query.get('productId');
  };
  const isViewForm = () => {
    return !!productId() && !!query.get('view');
  };
  const isEditForm = () => {
    return !!productId() && !isViewForm();
  };

  const getCountries = async () => {
    const resCountries = await getCountriesList();

    const modifiedCountry = resCountries.data.data.map((country) => {
      return {
        ...country,
        id: country.id,
      };
    });
    setCountries(modifiedCountry);
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      if (isViewForm() || isEditForm()) {
        setPageTitle('PRODUCT_DETAILS');
      }
      if (productId()) {
        getProductsDetailsById();
      }
    };
    fetchAllData();
  }, []);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [deletepopUpOpen, setDeletepopUpOpen] = useState(false);

  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addproductSchema,
    onSubmit: async (values) => {
      isEditForm() ? submitEditProduct(values) : submitCreateProduct(values);
    },
  });
  const CATEGORYLIST = [
    { id: 'BANGLES', name: t('bangles') },
    { id: 'BAR', name: t('bar') },
    { id: 'COINS', name: t('coins') },
    { id: 'EARRINGS', name: t('earrings') },
    { id: 'NECKLACES', name: t('necklaces') },
    { id: 'RINGS', name: t('rings_cat') },
  ];

  const getProductsDetailsById = () => {
    getProductDetails(productId())
      .then(({ data }) => {
        if (data) {
          const {
            name,
            sku,
            weight,
            purity,
            makingCharge,
            quantity,
            images,
            category,
            additionalCost,
            description,
            fkPurityHallmarkId,
            isActive,
            metalType,
            nameAr,
            descriptionAr,
            fkCountriesId,
          } = data.data;

          const receivedValue = {
            productName: name || '',
            product_id_sku: sku || '',
            weight: weight || '',
            purity: purity || '',
            makingCharge: parseInt(makingCharge) || '',
            quantity: quantity || 0,
            category: category || '',
            additionalcost: parseInt(additionalCost) || '',
            description: description || '',
            isActive: isActive || false,
            metalType: metalType || '',
            hallmark: fkPurityHallmarkId || '',
            productImages: images || [],
            nameAr: nameAr || '',
            descriptionAr: descriptionAr || '',
            fk_countries_id: fkCountriesId || '',
          };
          const prodImages = images || [];
          const hallmarkImages = fkPurityHallmarkId || [];
          const modifiedProdImages = prodImages.map((item, index) => {
            return { id: item, url: returnUrl(item), index, isDelete: false };
          });

          setProductImages(modifiedProdImages);
          setInitialValues(receivedValue);
          setProductData(data.data);
          if (fkPurityHallmarkId) {
            const modifiedhallMarkImages = [
              {
                id: fkPurityHallmarkId,
                name: 'certification & hallmark',
                url: returnUrl(fkPurityHallmarkId),
              },
            ];
            setListViewImages(modifiedhallMarkImages);
          }
        }
      })
      .catch((err) => {
        errorToaster(err?.data?.message, language);
      });
  };

  const getproductImagesId = () => {
    const id = productImages.map((item) => {
      return item.id;
    });
    return id || [];
  };

  const getHallmarkId = () => {
    const item = listViewImages.map((item) => {
      return item.id;
    });
    return item[0] || '';
  };
  const submitCreateProduct = async (values) => {
    try {
      setLoading(true);
      let {
        productName,
        product_id_sku,
        weight,
        purity,
        makingCharge,
        quantity,
        category,
        additionalcost,
        metalType,
        description,
        nameAr,
        descriptionAr,
        fk_countries_id,
      } = values;
      const payload = {
        name: productName,
        nameAr,
        sku: product_id_sku,
        weight: parseFloat(weight),
        metalType,
        purity,
        makingCharge: parseInt(makingCharge),
        quantity: parseFloat(quantity),
        images: getproductImagesId(),
        fkPurityHallmarkId: getHallmarkId() || undefined,
        category,
        additional_cost: parseInt(additionalcost) || 0,
        description,
        descriptionAr,
        fk_countries_id,
      };
      const response = await createProduct({ ...payload });
      if (response.data.isSuccess) {
        successToaster(t('PRODUCT_ADDED_SUCCESS'), language);
        navigate(ENDPOINTS.PRODUCTS);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const submitEditProduct = async (values) => {
    try {
      setLoading(true);
      let {
        productName,
        product_id_sku,
        weight,
        purity,
        makingCharge,
        quantity,
        category,
        additionalcost,
        metalType,
        description,
        isActive,
        nameAr,
        descriptionAr,
        fk_countries_id,
      } = values;
      const payload = {
        name: productName,
        sku: product_id_sku,
        weight: parseFloat(weight),
        metalType,
        purity,
        makingCharge: parseInt(makingCharge),
        images: getproductImagesId(),
        fkPurityHallmarkId: getHallmarkId() || null,
        category,
        additional_cost: parseInt(additionalcost) || 0,
        description,
        isActive,
        nameAr,
        descriptionAr,
        fk_countries_id,
      };
      const response = await editProduct(payload, productId());
      if (response.data.isSuccess) {
        successToaster(t('PRODUCT_UPDATE_SUCCESS'), language);
        navigate(ENDPOINTS.PRODUCT_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.PRODUCT_MANAGEMENT);
  };

  const handleSubmit = (event) => {
    if (isViewForm()) {
      navigate(`${ENDPOINTS.ADDPRODUCT}?productId=${productId()}`);
      return;
    }
    formik.submitForm(event);
  };

  const handleDelete = (event, type) => {
    switch (type) {
      case 'HALLMARK':
        deleteImageFromList(event);
        break;
      case 'PRODUCT':
        deleteImageFromGrid(event);
        break;
      default:
        return;
    }
  };

  const deleteImageFromList = (id) => {
    const modifiedImage = listViewImages.filter((item) => {
      return item.id !== id;
    });
    setListViewImages(modifiedImage);
    formik.setFieldValue('hallmark', '');
  };
  const deleteImageFromGrid = (id) => {
    const modifiedImage = productImages.filter((item) => {
      return item.id !== id;
    });
    const remainingImageIds = modifiedImage.map((item) => item.id);
    setProductImages(modifiedImage);
    formik.setFieldValue('productImages', remainingImageIds);
  };
  const handleUpload = async (event, type) => {
    const files = event;
    if (!files.length) return;

    for (const file of files) {
      const reader = new FileReader();
      reader.onerror = (error) => {
        console.error('Error reading file:', error);
      };

      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const url = e.target?.result;
        switch (type) {
          case 'HALLMARK':
            setListViewImages((prev) => [
              ...prev,
              { id: '', name: 'certification & hallmark', url },
            ]);
            break;
          case 'PRODUCT':
            setProductImages((prev) => [...prev, { id: '', url }]);
            formik.setFieldValue('productImages', []);
            break;
          default:
            return;
        }

        for (const file of files) {
          try {
            const response = await uploadFile(file);
            const id = response.data.data;
            switch (type) {
              case 'HALLMARK':
                setListImageId(id);

                break;
              case 'PRODUCT':
                setProductImageId(id);
                break;
              default:
                return;
            }
          } catch (err) {
            console.log('err', err);
          }
        }
      };
    }
  };
  const setListImageId = (id) => {
    if (id) {
      setListViewImages((prev) => [
        ...prev.filter((item) => item.id),
        { id, name: '', url: returnUrl(id) },
      ]);
      formik.setFieldValue('hallmark', id);
    }
    setTimeout(() => {
      if (fileInputRefhallmark.current) {
        fileInputRefhallmark.current.value = '';
      }
    }, 1000);
  };
  const setProductImageId = (id) => {
    if (id) {
      setProductImages((prev) => [
        ...prev.filter((item) => item.id),
        { id, url: returnUrl(id) },
      ]);
      formik.setFieldValue('productImages', [
        ...formik.values.productImages,
        id,
      ]);
    }
    setTimeout(() => {
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }, 1000);
  };
  const handleView = (id) => {
    const url = returnUrl(id);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const returnUrl = (id) => {
    const { API } = config;
    const BASE_URL = API.REACT_APP_BASE_URL;
    const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
    return url;
  };

  const getDisabledCondition = () => {
    const isFileUploadValid = productImages.length >= 1;

    return isViewForm()
      ? false
      : !(isFileUploadValid && formik.dirty && formik.isValid);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleAccept = async (event) => {
    performUpdateInventory({
      quantity: event.data || '',
    });
  };

  const performUpdateInventory = async ({ quantity }) => {
    const data = { quantity: parseInt(quantity) };
    try {
      const response = await updateQuantity(data, productId());
      if (response.data.isSuccess) {
        successToaster(t('INVENTORY_UPDATE_SUCCESS'), language);
        getProductsDetailsById();
        setLoading(false);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
      setLoading(false);
    }
  };

  const handleReject = () => {
    // setActionType('');
  };

  const handleModalClose = () => {
    setDeletepopUpOpen(false);
    formik.handleChange({
      target: {
        name: 'isActive',
        value: !formik.values.isActive,
      },
    });
  };

  const handleConfirmDeleteClick = (value) => {
    setDeletepopUpOpen(false);
  };

  const handleRadioValueChange = (value) => {
    const heading = value ? t('ACTIVE_HEADING') : t('INACTIVE_HEADING');
    setPopUpHeading(heading);
    setDeletepopUpOpen(true);
    formik.handleChange({
      target: {
        name: 'isActive',
        value: value,
      },
    });
  };

  const currentModulePerm = getModulePerm(MODULE_CONST.PRODUCT_MANAGEMENT);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => navigate(ENDPOINTS.PRODUCT_MANAGEMENT)}
          >
            <img src={LeftChevronIcon} />
          </div>
          <FormTitle>{t(pageTitle)}</FormTitle>
        </div>
        {isEditForm() && (
          <SubmitButton
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t('UPDATE_INVENTORY')}
          </SubmitButton>
        )}
      </div>
      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="productName"
            label={t('PRODUCT_NAME_ENG')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('productName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
            placeholder={t('PRODUCT_NAME_ENG')}
            name="productName"
            id="productName"
            onChange={formik.handleChange}
            value={formik.values.productName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('productName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('productName') && t(formik.errors.productName)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="nameAr"
            label={t('PRODUCT_NAME_AR')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('nameAr'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
            placeholder={t('PRODUCT_NAME_AR')}
            name="nameAr"
            id="nameAr"
            onChange={formik.handleChange}
            value={formik.values.nameAr}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('nameAr')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('nameAr') && t(formik.errors.nameAr)}
          />
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="product_id_sku"
            label={t('PRODUCT_ID_SKU')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('product_id_sku'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('PRODUCT_ID_SKU')}
            name="product_id_sku"
            id="product_id_sku"
            onChange={formik.handleChange}
            value={formik.values.product_id_sku}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('product_id_sku')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('product_id_sku') && t(formik.errors.product_id_sku)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="description"
            label={t('DESCRIPTION_ENG')}
            required={true}
            disabled={isViewForm()}
            sx={textInputField(isFieldError('description'), language)}
            height="40px"
            inputProps={{
              sx: textInputFieldStyle,
              maxLength: MAX_LONG_TEXT_INPUT_LENGTH,
            }}
            placeholder={t('DESCRIPTION_ENG')}
            name="description"
            id="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('description')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('description') && t(formik.errors.description)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="descriptionAr"
            label={t('DESCRIPTION_AR')}
            required={true}
            disabled={isViewForm()}
            sx={textInputField(isFieldError('descriptionAr'), language)}
            height="40px"
            inputProps={{
              sx: textInputFieldStyle,
              maxLength: MAX_LONG_TEXT_INPUT_LENGTH,
            }}
            placeholder={t('DESCRIPTION_AR')}
            name="descriptionAr"
            id="descriptionAr"
            onChange={formik.handleChange}
            value={formik.values.descriptionAr}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('descriptionAr')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('descriptionAr') && t(formik.errors.descriptionAr)
            }
          />
          <div className="col-12 col-md-4 mb-4">
            <SelectComponent
              fieldName={'metalType'}
              formik={formik}
              labelName={t('MATALTYPE')}
              placeholder={t('PLACEHOLDER_METAL_TYPE')}
              language={language}
              options={[
                {
                  id: 'GOLD',
                  name: t('Gold'),
                },
                {
                  id: 'SILVER',
                  name: t('Silver'),
                },
              ]}
              value={formik.values.metalType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isViewForm()}
            />
          </div>
          <div className="col-12 col-md-4 mb-4">
            <SelectComponent
              fieldName={'category'}
              formik={formik}
              labelName={t('CATEGORY_AD')}
              placeholder={t('PLACEHOLDER_CATEGORY')}
              language={language}
              options={CATEGORYLIST}
              value={formik.values.category}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isViewForm()}
              required={true}
            />
          </div>

          <div className="col-12 col-md-4 mb-4">
            <SelectComponent
              fieldName={'purity'}
              formik={formik}
              labelName={t('PURITY_AD')}
              placeholder={t('PLACEHOLDER_PURITY_AD')}
              language={language}
              options={[
                {
                  id: '14k',
                  name: t('14k'),
                },
                {
                  id: '18k',
                  name: t('18k'),
                },
                {
                  id: '22k',
                  name: t('22k'),
                },
                {
                  id: '24k',
                  name: t('24k'),
                },
              ]}
              value={formik.values.purity}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isViewForm()}
            />
          </div>

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="weight"
            label={t('WEIGHT')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('weight'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 10 }}
            placeholder={t('WEIGHT')}
            endAdornmentContent={<p>{t('gm')}</p>}
            name="weight"
            id="weight"
            onChange={formik.handleChange}
            value={formik.values.weight}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('weight')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('weight') && t(formik.errors.weight)}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="quantity"
            label={t('QUANTITY')}
            disabled={isViewForm() || isEditForm()}
            required={true}
            sx={textInputField(isFieldError('quantity'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('QUANTITY')}
            name="quantity"
            id="quantity"
            onChange={formik.handleChange}
            value={formik.values.quantity}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('quantity')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('quantity') && t(formik.errors.quantity)}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="makingCharge"
            label={t('making charge')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('makingCharge'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 5 }}
            placeholder={t('making charge')}
            name="makingCharge"
            id="makingCharge"
            onChange={formik.handleChange}
            value={formik.values.makingCharge}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('makingCharge')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('makingCharge') && t(formik.errors.makingCharge)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="additionalcost"
            label={t('additional cost')}
            disabled={isViewForm()}
            // required={true}
            sx={textInputField(isFieldError('additionalcost'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 5 }}
            placeholder={t('additional cost')}
            name="additionalcost"
            id="additionalcost"
            onChange={formik.handleChange}
            value={formik.values.additionalcost}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('additionalcost')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('additionalcost') && t(formik.errors.additionalcost)
            }
          />

          <div className="row mb-4">
            <CountryWithFLag
              className="col-12 col-md-4"
              placeholderForCountry={t('SELECT_COUNTRY')}
              fieldName="fk_countries_id"
              multiSelect={false}
              labelName={t('Country')}
              options={countries}
              selectedOptions={formik.values.fk_countries_id}
              formik={formik}
              errorText={
                isFieldError('fk_countries_id') &&
                t(formik.errors.fk_countries_id)
              }
              htmlFor="fk_countries_id"
              label={''}
              sx={textInputField(isFieldError('fk_countries_id'), language)}
              height="40px"
              inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
              placeholder={t('SELECT_COUNTRY')}
              name="fk_countries_id"
              id="fk_countries_id"
              onChange={formik.handleChange}
              value={formik.values.fk_countries_id}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('fk_countries_id')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              required={true}
              t={t}
              disabled={isViewForm()}
            />
          </div>

          <div className="col-12 col-md-4">
            <RadioInput
              disabled={isViewForm()}
              parentLabel={t('TH_STATUS')}
              required={true}
              value={formik.values.isActive}
              textFieldError={isFieldError('isActive')}
              helperText={isFieldError('isActive')}
              options={[
                { label: t('ACTIVE'), value: true },
                { label: t('INACTIVE'), value: false },
              ]}
              labelKey="isActive"
              handleChange={handleRadioValueChange}
            />
          </div>
          <div className="col-12 col-md-4"></div>
          <div className="col-12 col-md-4"></div>
          {/* <div className="col-12 col-md-4"></div> */}

          <div className="col-12 col-md-4 mt-4">
            <InputFileUpload
              buttonTitle={t('Choose File')}
              className="col-12 mb-4"
              labelName={t('Certification and Hallmark')}
              required={false}
              hideChangeOption={isViewForm()}
              onEvent={handleDelete}
              onUpload={handleUpload}
              onView={handleView}
              listView={false}
              type="GRID"
              productImages={listViewImages}
              bulkUpload={false}
              requiredLabelName={t('HALL_MARK_REQUIRED_LABEL')}
              fileLimit="1"
              deleteCase="HALLMARK"
              ref={fileInputRefhallmark}
              allowedMimeTypes={{
                '.pdf': 'application/pdf',
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
              }}
            />

            <DocumentsView
              hideChangeOption={isViewForm()}
              onEvent={handleDelete}
              onView={handleView}
              listView={false}
              type="GRID"
              deleteCase="HALLMARK"
              productImages={listViewImages}
            />
          </div>
          <div className="col-12 col-md-4 mt-4">
            <InputFileUpload
              buttonTitle={t('Choose File')}
              className="col-12 mb-4"
              labelName={t('PRODUCT_IMAGES')}
              required={true}
              hideChangeOption={isViewForm()}
              onEvent={handleDelete}
              onUpload={handleUpload}
              onView={handleView}
              listView={false}
              type="GRID"
              ref={fileInputRef}
              productImages={productImages}
              bulkUpload={false}
              requiredLabelName={t('PRODUCT_IMG_REQUIRED_LABEL')}
              fileLimit="5"
              deleteCase="PRODUCT"
              allowedMimeTypes={{
                '.jpeg': 'image/jpeg',
                '.png': 'image/png',
              }}
            />

            <DocumentsView
              hideChangeOption={isViewForm()}
              onEvent={handleDelete}
              onView={handleView}
              listView={false}
              type="GRID"
              deleteCase="PRODUCT"
              productImages={productImages}
            />
          </div>
        </div>
        <FormButtons>
          <CancelButton onClick={() => handleCancelButton()}>
            {t('CANCEL')}
          </CancelButton>

          {currentModulePerm.writeMode && (
            <SubmitButton
              type="submit"
              onClick={handleSubmit}
              disabled={getDisabledCondition()}
            >
              {t(
                !isEditForm()
                  ? isViewForm()
                    ? 'EDIT'
                    : 'CREATE_PRODUCT'
                  : 'SAVE'
              )}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>

      {open && (
        <AddInventory
          open={open}
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={t('UPDATE_INVENTORY')}
          description={t('InventoryModaldesc')}
          productData={productData}
        >
          <div className="mt-2 mb-1">{productData?.name}</div>

          <div className="product-list">
            <span className="product-list-title">{t('PRODUCT_ID')}:</span>{' '}
            {productData?.sku}
          </div>

          <div className="product-list">
            <span className="product-list-title">{t('WEIGHT_TITLE')}:</span>{' '}
            {`${productData?.weight}gm`}
          </div>

          <div className="product-list">
            <span className="product-list-title">
              {t('PURITY_CAMEL_CASE')}:
            </span>{' '}
            {productData?.purity}
          </div>

          <div className="product-list">
            <span className="product-list-title"> {t('Category')}:</span>{' '}
            {productData?.category}
          </div>
          <div className="product-list">
            <span className="product-list-title">{t('QUANTITY')}:</span>{' '}
            {productData?.quantity}
          </div>
        </AddInventory>
      )}

      <DeleteModal
        isopen={deletepopUpOpen}
        handleClose={handleModalClose}
        toCloseOnOutsideModalClick={true}
        handleConfirmDeleteClick={handleConfirmDeleteClick}
        Heading={t('Confirmation')}
        confirmBtnTitle={t('CONFIRM_ACTIVE_BTN')}
      >
        <Box marginTop="32px">
          <Typography
            sx={
              (userPageStyles.confirmationText,
              { lineHeight: '1.5', fontSize: '14px' })
            }
          >
            {popUpHeading}
          </Typography>
          <Box display="flex" gap="16px" marginTop="16px">
            <Box
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            ></Box>
          </Box>
        </Box>
      </DeleteModal>
    </div>
  );
}
