export const styles = {
  cancleBtnSx: {
    backgroundColor: '#F4F3F3',
    color: '#777673',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
  },
  yesBtnSx: {
    backgroundColor: '#D25E46',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
    '&:hover ': {
      backgroundColor: '#D25E46',
    },
  },
  fontStyle: {
    fontFamily: 'Lato',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
};
