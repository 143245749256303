import Button from '@mui/material/Button';
import { styled } from '@mui/material';
import { Colors } from './../../common/constants/Color';

export const heading = {
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '20px',
  letterSpacing: '0',
  textAlign: 'left',
  color: '#303030',
  display: 'inline',
};

export const formInputStyle = {
  minHeight: '276px',
  display: 'grid',
  alignContent: 'space-between',
  gridTemplateColumns: 'repeat(auto-fill, minmax(260px, auto))',
  gridAutoRows: '84px',
  columnGap: '48px',
};

export const cardsStyle = {
  display: 'grid',
  gridAutoFlow: 'column',
  justifyContent: 'space-between',
  gridAutoRows: 'minmax(244px, min-content)',
  gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))',
  columnGap: '48px',
  rowGap: '10px',
  marginTop: '12px',
};

export const paperStyle = {
  backgroundColor: '#FFFFFF',
  borderRadius: '14px',
};

export const circularProgressBarPosition = {
  width: '100%',
  height: '200px',
  display: 'grid',
  justifyContent: 'center',
  alignItems: 'end',
};

export const circularProgressBarStyle = { color: 'rgba(227, 202, 126, 1)' };

export const endFormStyles = {
  gridColumn: '1 / -1',
  marginTop: '32px',
  display: 'grid',
  gridAutoRows: '40px',
  justifyContent: 'end',
  gridAutoFlow: 'column',
  gap: '30px',
};

export const informationBoxStyle = {
  padding: '20px',
  rowGap: '16px',
  boxShadow:
    '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
  border: '1px solid rgba(145, 158, 171, 0.24)',
  borderRadius: '7px',
};

export const informationBoxTitleStyle = {
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: Colors.COMMON_GRAY,
};

export const informationBoxContentStyle = (isSigleColumn) => ({
  display: 'grid',
  gridTemplateColumns: isSigleColumn ? '1ft' : '1fr 1fr',
  alignContent: 'start',
  gap: '12px 10px',
  flexGrow: 1,
});

export const cardInformationHeadingStyle = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '16px',
  textAlign: 'left',
};

export const cardInformationContentStyle = {
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '450',
  lineHeight: '20px',
  color: Colors.COMMON_GRAY,
  wordBreak: 'break-word',
};

export const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

export const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

export const RejectButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
