// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
// sections
import { style } from './LoginPage.style';
import AuthSideBar from '../components/auth-sidebar/AuthSideBar';
import { ResetPasswordForm } from '../sections/auth/reset-password';
import Iconify from '../components/iconify';
import { useLocation, useNavigate } from 'react-router-dom';
import ENDPOINTS from '../constants/endpoints';
import { useContext, useEffect } from 'react';
import useResponsive from '../hooks/useResponsive';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
import { replace } from 'lodash';
import ContentSideBox from '../components/content-sidebox/ContentSidebox';
import { styledRoot } from './resetPassword.style';
import { styledContent } from './forgetPassword.style';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => styledRoot(theme));

const StyledContent = styled('div')(({ theme }) => styledContent(theme));

// ----------------------------------------------------------------------

export default function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const mdUp = useResponsive('up', 'md');
  const { language } = useContext(LanguageContext);

  const redirectToPage = (path) => {
    navigate(`${path}`, (replace = true));
  };

  const isRequired = JSON.parse(localStorage.getItem('changePasswordRequired'));

  useEffect(() => {
    const previousPath = location.state?.from;
    if (previousPath !== ENDPOINTS.OPT_VERIFICATION && !isRequired) {
      redirectToPage(ENDPOINTS.LOGIN);
    }
  }, [location, navigate]);

  return (
    <>
      <StyledRoot>
        <AuthSideBar />
        <ContentSideBox>
          <Container maxWidth={'sm'}>
            <Box position={'absolute'} left={'44px'} top={'44px'}>
              <Button
                startIcon={<Iconify icon={'eva:chevron-left-fill'} />}
                onClick={() => redirectToPage(ENDPOINTS.FORGET_PASSWORD)}
                sx={style.backButton}
              >
                {t('BACK_BTN_TITLE')}
              </Button>
            </Box>
            <Box>
              <StyledContent>
                <Typography
                  color={'#212121'}
                  sx={style.loginFormTitle(language)}
                >
                  {t('RESET_PASSWORD_FORM_TITLE')}
                </Typography>
                <ResetPasswordForm />
              </StyledContent>
            </Box>
          </Container>
        </ContentSideBox>
      </StyledRoot>
    </>
  );
}
