import { Select } from '@shopify/polaris';
import { InputLabel, Stack } from '@mui/material';
import { inputLabelStyle } from '../auth-input/style';
import { useContext } from 'react';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import './style.scss';
import '../warehouse/citySelectStyling.scss';
export default function SelectInput({
  label,
  options,
  handleSelectChange,
  containerSx,
  customStylingClass = null,
  required,
  labelInline,
  ...props
}) {
  const { language } = useContext(LanguageContext);

  return (
    <Stack
      sx={containerSx}
      className={customStylingClass ?? 'app-polaris-select-input'}
    >
      {labelInline ? null : (
        <InputLabel htmlFor="email" sx={inputLabelStyle(language)} required>
          <span className={required ? 'asterisk-content' : null}>{label}</span>
        </InputLabel>
      )}
      <Select
        labelInline
        options={options}
        className="custom-select-height"
        onChange={handleSelectChange}
        {...props}
      />
    </Stack>
  );
}
