import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addNewCitySchema = Yup.object().shape({
  cityName: Yup.string()
    .test(
      'check-min-length',
      'Name of city is too short!',
      (value) => value.length >= 3
    )
    .test(
      'check-max-length',
      'Name of city is too long!',
      (value) => value.length <= 100
    )
    .test(
      'check-alphabets',
      'Digits and special characters (%,#,*,@) are not allowed.',
      (value) => REGEX_PATTERN.ALPHABET_ONLY_WITH_SPACE.test(value)
    )
    .required('Field cannot be empty')
    .label('CityName'),

  fk_countries_id: Yup.string()
    .label('fk_countries_id')
    .required('Field cannot be empty'),

  deliveryCharge: Yup.string()
    .test(
      'check-negatives',
      'Delivery Charge must be greater or equal to zero.',
      (value) => {
        if (REGEX_PATTERN.NEGATIVES_AND_NUMBER_WITH_DECIMAL.test(value)) {
          return Number(value) >= 0;
        } else return true;
      }
    )
    .test(
      'check-number',
      'Alphabet and special characters like %,*,#,! are not allowed.',
      (value) => REGEX_PATTERN.NUMBER_WITH_DECIMAL.test(value)
    )
    .required('Field can not be empty!')
    .label('deliveryCharge'),
});
