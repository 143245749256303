import * as yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex.js';

export const warehouseSchema = yup.object().shape({
  name: yup
    .string()
    .matches(
      REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS,
      'Invalid Warehouse name'
    )
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .label('Name'),
  latitude: yup
    .string('Invalid latitude')
    .test('Invalid latitude', (val) => val >= -90 && val <= 90)
    .required('Required')
    .label('Latitude'),
  longitude: yup
    .string('Invalid longitude')
    .test('Invalid longitude', (val) => val >= -180 && val <= 180)
    .required('Required')
    .label('longitude'),
  firstAddressLine: yup
    .string()
    .matches(REGEX_PATTERN.ALL_WITH_SPACES, 'Invalid First address')
    .min(3, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required')
    .label('Address Line 1'),
  secondAddressLine: yup
    .string()
    .matches(REGEX_PATTERN.ALL_WITH_SPACES, 'Invalid Second address')
    .min(3, 'Too Short!')
    .max(255, 'Too Long!')
    .label('Address Line 2'),
  city: yup.string().required('City is required!'),
  zipCode: yup
    .string()
    .matches(REGEX_PATTERN.ALPHA_NUMERIC, 'Invalid Zip code')
    .min(3, 'Too Short!')
    .max(7, 'Too Long!')
    .label('Zip Code'),
  contactPersonName: yup
    .string()
    .matches(
      REGEX_PATTERN.ALL_EXCLUDING_SPECIAL_CHARACTERS,
      'Invalid Contact person name'
    )
    .min(3, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
    .label('Contact Person Name'),
  contactPersonCountryCode: yup
    .string('Invalid Contact person country code')
    .required('Required')
    .label('Contact Person Phone number'),
  contactPersonPhoneNumber: yup
    .string('Should be numberic')
    .matches(REGEX_PATTERN.PHONE_NUMBER, 'Invalid Phone no')
    .min(7, 'Invalid Phone no')
    .max(15, 'Invalid Phone no')
    .required('Required')
    .label('Contact Person Phone number'),
  searchAddress: yup.string().label('Search Address'),
});
