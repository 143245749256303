import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { InputLabel } from '@mui/material';
import { inputLabelStyle } from './../../sections/auth/login/LoginForm.style';

export function InputButton({
  formik,
  labelName,
  fieldName,
  language,
  availableOptions,
  option,
  disabled,
}) {
  return (
    <>
      <InputLabel htmlFor={fieldName} sx={inputLabelStyle(language)} required>
        <span className="asterisk-content">{labelName}</span>
      </InputLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={fieldName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {availableOptions.map((option, index) => (
          <FormControlLabel
            disabled={disabled}
            sx={
              option.value === formik.values.status
                ? {
                    '& .MuiSvgIcon-root': {
                      height: 15,
                      width: 15,
                    },
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                      {
                        color: 'rgba(227, 202, 126, 0.4)',
                      },
                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                      color: 'rgba(227, 202, 126, 1)',
                    },
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                      {
                        stroke: 'rgba(227, 202, 126, 0.4)',
                        strokeWidth: 5,
                      },
                  }
                : {
                    '& .MuiSvgIcon-root': {
                      height: 15,
                      width: 15,
                    },
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)':
                      {
                        color: '#EBEBEB',
                      },
                    '& .MuiSvgIcon-root + .MuiSvgIcon-root': {
                      color: '#B5B5B5',
                    },
                    '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                      {
                        stroke: '#EBEBEB',
                        strokeWidth: 5,
                      },
                  }
            }
            key={option.key}
            checked={option.value === formik.values.status}
            value={option.value}
            control={<Radio disableRipple />}
            label={option.display}
          />
        ))}
      </RadioGroup>
    </>
  );
}
