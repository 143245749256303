import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import SearchBar from '../../components/search-bar-debounce';
import { useTranslation } from 'react-i18next';

import ENDPOINTS from '../../constants/endpoints';
import { getFormattedDate } from '../../utils/helperUtility';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input';
import moment from 'moment';
import { getDeleteAccountList } from '../../api/account-delete';
import { Colors } from '../../common/constants/Color';
import { statusEnum } from './AccountDeleteConstant';
const resourceName = {
  singular: 'order',
  plural: 'orders',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function AccountDelete() {
  const [searchTerm, setSearchTerm] = useState('');
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
    status: 'All',
  });

  const { t } = useTranslation();

  const navigate = useNavigate();
  const kycColumns = [
    { key: 'name', title: t('NAME') },
    { key: 'phone', title: t('PHONE_NUMBER') },
    { key: 'email', title: t('EMAIL_TEXT') },
    { key: 'status', title: t('STATUS') },
    { key: 'createdAt', title: t('REQUEST_DATE_TIME') },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('ACCEPTED'), value: 'ACCEPTED' },
    { label: t('Rejected'), value: 'REJECTED' },
  ];

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = parseInt(limit);

  const pageCount = Math.ceil(totalList / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };
  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={`${Colors.GRAY}`}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              goToAccountVerification(rowItemData);
            }}
          >
            {t('VIEW')}
          </Typography>
        </Box>
      </>
    );
  };

  const goToAccountVerification = (item) => {
    navigate(`${ENDPOINTS.ACCOUNT_DELETE_DETAILS}?id=${item.id}`);
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (filterData.status && filterData.status !== 'All') {
      dateFilterData['status'] = filterData.status;
    }
    if (searchTerm) {
      dateFilterData['searchStr'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['startDate'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['endDate'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  };

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getDeleteAccountList({
        ...queryPayload,
        pageLimit: itemsPerPage,
        pageSkip: page - 1,
      });
      setAccountData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [filterData, searchTerm]);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const transformedData = accountData?.map((item) => {
    return {
      id: item.id,
      name:
        item.firstName || item.middleName || item.lastName
          ? `${item.firstName || ''} ${item.middleName || ''} ${
              item.lastName || ''
            }`.trim()
          : 'NA',
      phone: item.mobileCode
        ? `(+${item.mobileCode}) ${item.phoneNo}`
        : item.phoneNo,
      email: item.email,
      status: statusEnum[item.status] || item.status,
      poi: item.poiName,
      poa: item.poaName,
      createdAt: getFormattedDate(item.createdAt, 'DD/MM/YYYY, HH:mm'),
      renderButton: () => <ButtonsForTable rowItemData={item} />,
    };
  });

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };
  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('ACCOUNT_DELETE_REQUEST')}
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <Card>
              <StyledFilterBox>
                <div className="w-50 d-flex justify-content-start">
                  <SelectInput
                    placeholder="Status"
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    label={'Status'}
                    value={filterData.status}
                    options={statusList}
                    handleSelectChange={(event) => {
                      handleFilterSelection('status', event);
                    }}
                  />
                  <div className="ms-2">
                    <DateRangePicker
                      placeholder={t('REQUESTED_DATE')}
                      dateRange={filterData.createdAt}
                      setDateRange={(event) =>
                        handleFilterSelection('createdAt', event)
                      }
                    />
                  </div>
                </div>
                <SearchBar
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onClear={handleClearSearch}
                  placeholder={t('Search')}
                  maxlength={50}
                />
              </StyledFilterBox>
              <CommonTable
                resourceName={resourceName}
                data={transformedData}
                columns={kycColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                emptyTableText={t('NO_KYC_FOUND')}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={itemsPerPage}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
