export const sourceMapper = {
  gold_buy: 'Gold Buy',
  silver_buy: 'Silver Buy',
  silver_sell: 'Silver Sell',
  gold_sell: 'Gold Sell',
  wallet_recharge: 'Wallet Recharge',
  withdraw: 'Withdrawn',
};

export const TRANSACTION_SOURCE_MODE = {
  gold_buy: {
    NAME: 'Gold Buy',
    SOURCE: 'Gold Wallet',
    MODE: 'Buy',
  },
  silver_buy: {
    NAME: 'Silver Buy',
    SOURCE: 'Silver Wallet',
    MODE: 'Buy',
  },
  gold_sell: {
    NAME: 'Gold Sell',
    SOURCE: 'Gold Wallet',
    MODE: 'Sell',
  },
  silver_sell: {
    NAME: 'Silver Sell',
    SOURCE: 'Silver Wallet',
    MODE: 'Sell',
  },
  wallet_recharge: {
    NAME: 'Wallet Recharge',
    SOURCE: 'Money Wallet',
    MODE: 'Recharge',
  },
  withdraw: {
    NAME: 'Withdrawal',
    SOURCE: 'Money Wallet',
    MODE: 'Withdrawal',
  },
  wallet_refund: {
    NAME: 'Wallet Refund',
    SOURCE: 'Money Wallet',
    MODE: 'Refund',
  },
  metal_refund: {
    NAME: 'Gold/Silver Refund',
    SOURCE: 'Gold/Silver Wallet',
    MODE: 'Refund',
  },
  order_placed: {
    NAME: 'Order Placed',
    SOURCE: 'Wallet',
    MODE: 'Order Placed',
  },
  transfer_to: {
    NAME: 'Transfer To',
    SOURCE: 'Wallet',
    MODE: 'Gifted',
  },
  transfer_from: {
    NAME: 'Transfer From',
    SOURCE: 'Wallet',
    MODE: 'Gift Received',
  },
};
