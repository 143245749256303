import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '.././userPageStyle';
import SearchBar from '../../components/search-bar-debounce';
import { Badge } from '@shopify/polaris';
import { useTranslation } from 'react-i18next';

import Pagination from '../../components/pagination';
import ENDPOINTS from '../../constants/endpoints';
import { getKycList } from '../../redux/slices/kycList.slice';
import { getFormattedDate, downloadExcelFile } from '../../utils/helperUtility';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input';
import moment from 'moment';
import { getKycList as getKycListApi } from '../../api/kyc';
const resourceName = {
  singular: 'order',
  plural: 'orders',
};

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function KycManagement() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
    status: 'All',
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const kycColumns = [
    { key: 'name', title: t('USER_NAME') },
    { key: 'phone', title: t('PHONE_NUMBER') },
    { key: 'email', title: t('EMAIL_TEXT') },
    { key: 'status', title: t('STATUS') },
    { key: 'createdAt', title: t('REQUESTED_DATE') },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const statusList = [
    { label: t('All'), value: 'All' },
    { label: t('Pending'), value: 'PENDING' },
    { label: t('Approved'), value: 'APPROVED' },
    { label: t('Rejected'), value: 'REJECTED' },
  ];
  // For Pagination
  //========================================================
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: kycListData,
    loading,
    error,
    totalList,
    limit,
  } = useSelector((state) => state.kycLists);

  console.log(totalList);

  const itemsPerPage = parseInt(limit);

  const pageCount = Math.ceil(totalList / itemsPerPage);
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getKycListFn(value);
  };
  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              goToKycVerification(rowItemData);
            }}
          >
            {t('VIEW')}
          </Typography>
        </Box>
      </>
    );
  };

  const goToKycVerification = (item) => {
    navigate(`${ENDPOINTS.KYC_VERIFICATION}?id=${item.id}`);
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (filterData.status && filterData.status !== 'All') {
      dateFilterData['status'] = filterData.status;
    }
    if (searchTerm) {
      dateFilterData['searchStr'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['startDate'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['endDate'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    return dateFilterData;
  };

  const getKycListFn = (page = 1) => {
    const pageSkip = currentPage - 1;
    const queryPayload = getListQueryPayload();
    dispatch(
      getKycList({
        reqData: {
          pageLimit: itemsPerPage,
          pageSkip: page - 1,
          ...queryPayload,
        },
      })
    );
    setCurrentPage(page);
  };
  const handleExport = () => {
    const queryPayload = getListQueryPayload();
    queryPayload['download'] = true;
    queryPayload['fileType'] = 'xlsx';
    getKycListApi(queryPayload, true)
      .then((data) => {
        downloadExcelFile(data, t('KYC_LIST'));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getKycListFn();
  }, [filterData, searchTerm]);

  const handleClearSearch = () => {
    setSearchTerm('');
  };

  const transformedData = kycListData?.map((kycItem) => {
    return {
      id: kycItem.id,
      fkUsersId: kycItem.fkUsersId,
      name:
        kycItem.firstName || kycItem.middleName || kycItem.lastName
          ? `${kycItem.firstName || ''} ${kycItem.middleName || ''} ${
              kycItem.lastName || ''
            }`.trim()
          : 'NA',
      phone: kycItem.phoneNo,
      email: kycItem.email,
      status: kycItem.status,
      poi: kycItem.poiName,
      poa: kycItem.poaName,
      createdAt: getFormattedDate(kycItem.createdAt, 'DD/MM/YYYY HH:mm:ss'),
      renderButton: () => <ButtonsForTable rowItemData={kycItem} />,
    };
  });

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };
  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={'35px'}
        >
          <Typography variant="h4" gutterBottom>
            {t('KYC_VERIFICATION')}
          </Typography>

          {/* <Button
            onClick={handleExport}
            variant="contained"
            startIcon={
              <Iconify width={'18px'} icon="material-symbols:download-sharp" />
            }
          >
            {t('EXPORT')}
          </Button> */}
        </Stack>

        <Card>
          <Scrollbar>
            <Card>
              <StyledFilterBox>
                <div className="w-50 d-flex justify-content-start">
                  <SelectInput
                    placeholder="Status"
                    labelInline={true}
                    containerSx={{ width: '120px' }}
                    label={'Status'}
                    value={filterData.status}
                    options={statusList}
                    handleSelectChange={(event) => {
                      handleFilterSelection('status', event);
                    }}
                  />
                  <div className="ms-2">
                    <DateRangePicker
                      placeholder={t('REQUESTED_DATE')}
                      dateRange={filterData.createdAt}
                      setDateRange={(event) =>
                        handleFilterSelection('createdAt', event)
                      }
                    />
                  </div>
                </div>
                <SearchBar
                  onChange={handleSearchChange}
                  value={searchTerm}
                  onClear={handleClearSearch}
                  placeholder={t('Search')}
                  maxlength={50}
                />
              </StyledFilterBox>
              <CommonTable
                resourceName={resourceName}
                data={transformedData}
                columns={kycColumns}
                isCheckBox={false}
                loading={loading}
                showPagination={true}
                pageCount={pageCount}
                currentPage={currentPage}
                emptyTableText={t('NO_KYC_FOUND')}
                handlePageChange={handlePageChange}
                totalList={totalList}
                itemsPerPage={itemsPerPage}
              />
            </Card>
          </Scrollbar>
        </Card>
      </Container>
    </>
  );
}
