import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import AuthInput from '../../components/auth-input';
import PhoneNumber from '../../components/phone-number';
import AlertDialog from './KycConfirmationModal';
import { useFormik } from 'formik';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import FileIcon from '../../assets/icons/file.svg';

import { getKycDetails, downloadFile, approveRejectKyc } from '../../api/kyc';
import { getCountriesList } from '../../api/adminManagement';
import {
  successToaster,
  errorToaster,
  getFormattedDate,
} from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { KycConstant } from './KycConstant';
import { Colors } from '../../common/constants/Color';
import config from '../../config';
import API_ENDPOINTS from '../../constants/apiEndpoints';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const ViewFilesBtn = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.BLACK}`,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '4px, 22px, 4px, 22px',
  height: '25px',
  border: `1px solid ${Colors.LIGHT_YELLOW}`,
  borderRadius: '8px',
  width: '104px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const RejectButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.RED}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    marginTop: '40px',
  };
});

function KycVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [countries, setCountries] = useState([]);
  const [kycData, setKycData] = useState([]);
  const [ModalMessage, setModalMessage] = useState('');
  const [isActionRequired, setIsActionRequired] = useState(false);
  const [actionType, setActionType] = useState(' ');
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    status: '',
    requestedDate: '',
    remarks: '',
  });
  const { language } = useContext(LanguageContext);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
  });

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  const kycId = () => {
    return query.get('id');
  };

  const getUserName = () => {
    return `${kycData.firstName || ''} ${kycData.middleName || ''} ${
      kycData.lastName || ''
    }`;
  };

  const handleClickOpen = (type) => {
    const msg =
      type === KycConstant.ACCEPT
        ? `${t('ACCEPT_CONFIRM_MSG')} ${getUserName()}`
        : `${t('REJECT_CONFIRM_MSG')} ${getUserName()}`;
    setModalMessage(msg);
    setActionType(
      type === KycConstant.ACCEPT ? KycConstant.ACCEPT : KycConstant.REJECT
    );
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleAccept = async (event) => {
    performKycVerification({
      status: event.action,
      remarks: event.data || '',
    });
  };

  const handleReject = () => {
    setActionType('');
  };

  const performKycVerification = async ({ status, remarks }) => {
    try {
      setLoading(false);
      const body = {
        status,
        remarks,
      };
      const id = kycId();
      const response = await approveRejectKyc(body, id);
      if (response.data.isSuccess) {
        const messageKey =
          status === KycConstant.ACCEPT_STATUS
            ? t('KYC_ACEEPT_MSG')
            : t('KYC_REJECT_MSG');
        successToaster(messageKey, language);
        navigate(ENDPOINTS.KYC_MANAGEMENT);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };

  useEffect(() => {
    const id = kycId();
    const getDataForKyc = async () => {
      try {
        setLoading(true);
        const resCountries = await getCountriesList();

        const modifiedCountry = resCountries.data.data.map((country) => {
          return {
            ...country,
            id: country.id,
          };
        });
        setCountries(modifiedCountry);
        const response = await getKycDetails(id);
        const data = response.data.data;
        const isRequired =
          data.status == KycConstant.ACCEPT_STATUS ||
          data.status == KycConstant.REJECT_STATUS
            ? false
            : true;
        setKycData(data);
        setFormData(data);
        setIsActionRequired(isRequired);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    getDataForKyc();
  }, []);

  const setFormData = (data) => {
    const { firstName, lastName, middleName, status, email, phoneNo, remarks } =
      data;
    setInitialValues({
      firstName,
      middleName,
      lastName,
      email,
      countryCode: data.fkCountriesId,
      phoneNumber: phoneNo,
      status,
      requestedDate: getFormattedDate(data.createdAt),
      remarks: remarks || 'NA',
    });
  };

  const openInNewTab = (id) => {
    const { API } = config;
    const BASE_URL = API.REACT_APP_BASE_URL;
    const url = `${BASE_URL}/public${API_ENDPOINTS.FILES}/${id}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const handleCancelButton = () => {
    navigate(ENDPOINTS.KYC_MANAGEMENT);
  };

  const handleAcceptReject = (type) => {
    handleClickOpen(type);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.KYC_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t('KYC_VERIFICATION')}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="firstName"
            label={t('FIRST_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('FIRST_NAME')}
            name="firstName"
            id="firstName"
            value={formik.values.firstName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="middleName"
            label={t('MIDDLE_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('MIDDLE_NAME')}
            name="middleName"
            id="middleName"
            value={formik.values.middleName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="lastName"
            label={t('LAST_NAME')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('LAST_NAME')}
            name="lastName"
            id="lastName"
            value={formik.values.lastName}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="email"
            label={t('EMAIL_TEXT')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('EMAIL_TEXT')}
            name="email"
            id="email"
            value={formik.values.email}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="reqdate"
            label={t('REQUESTED_DATE')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('REQUESTED_DATE')}
            name="reqdate"
            id="reqdate"
            value={formik.values.requestedDate}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <PhoneNumber
            className="col-12 col-md-4"
            placeholderForCountry="Country code"
            fieldName="countryCode"
            multiSelect={false}
            labelName={t('PHONE')}
            options={countries}
            disabled={true}
            disableInput={true}
            selectedOptions={formik.values.countryCode}
            formik={formik}
            htmlFor="phoneNumber"
            label={''}
            inputProps={{
              sx: { ...textInputFieldStyle, height: '40px' },
              maxLength: 10,
            }}
            placeholder={t('PHONE')}
            name="phoneNumber"
            id="phoneNumber"
            value={formik.values.phoneNumber}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="status"
            label={t('STATUS')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('STATUS')}
            name="status"
            id="status"
            value={formik.values.status}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="remarks"
            label={t('REMARK')}
            disabled={true}
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('REMARK')}
            name="remarks"
            id="remarks"
            value={formik.values.remarks}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
          />
          <div className="col-12 col-md-6">
            <div className="">{t('Proof of identity')}</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex justify-content-start align-items-center font-13px ms-4">
                <img src={FileIcon} />
                <div className="ms-1"> {`${kycData['poiName']}`}</div>
              </div>
              <ViewFilesBtn
                onClick={() => {
                  openInNewTab(kycData.poiDocumentId);
                }}
              >
                {t('VIEW_FILE')}
              </ViewFilesBtn>
            </div>
          </div>

          <div className="col-12 col-md-6">
            <div className="">{t('Proof of Address')}</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="d-flex justify-content-start align-items-center font-13px ms-4">
                <img className="" src={FileIcon} />
                <div className="ms-1"> {`${kycData['poaName']}`}</div>
              </div>
              <ViewFilesBtn
                onClick={() => {
                  openInNewTab(kycData.poaDocumentId);
                }}
              >
                {t('VIEW_FILE')}
              </ViewFilesBtn>
            </div>
          </div>
        </div>
        <FormButtons>
          <CancelButton onClick={() => handleCancelButton()}>
            {isActionRequired ? t('CANCEL') : t('CLOSE')}
          </CancelButton>

          {isActionRequired && (
            <RejectButton
              onClick={() => handleAcceptReject(KycConstant.REJECT)}
            >
              {t('REJECT')}
            </RejectButton>
          )}

          {isActionRequired && (
            <SubmitButton
              type="submit"
              onClick={() => {
                handleAcceptReject(KycConstant.ACCEPT);
              }}
            >
              {t('ACCEPT')}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>

      {open && (
        <AlertDialog
          open={open}
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleReject={handleReject}
          actionType={actionType}
          title={t('KYC_VERIFICATION')}
          description={t(ModalMessage)}
          ModalMessage
        />
      )}
    </div>
  );
}

export default KycVerification;
