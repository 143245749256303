import { createSlice } from '@reduxjs/toolkit';
import { getAdminList as getAdminListApi } from '../../api/adminList';
import asyncActionCreator from '../../common/utils/asyncActionCreator';

export const adminListSliceName = 'adminLists';

export const getAdminList = asyncActionCreator(
  `${adminListSliceName}/list`,
  getAdminListApi,
  {
    doNotNotify: true,
    doNotLoad: true,
  }
);

const initialState = {
  loading: true,
  error: false,
  data: [],
  totalList: 0,
  limit: 10,
};

const getAdminListSlice = createSlice({
  name: adminListSliceName,
  initialState,
  reducers: {
    resetAdminList: (state) => {
      state.data = {
        ...initialState.data,
        list: { ...initialState.data },
      };
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.totalList = initialState.totalList;
      state.limit = initialState.limit;
    },
  },  
  extraReducers(builder) {
    builder
      .addCase(getAdminList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload.data || [];
        state.totalList = action.payload.totalCount;
        state.limit = action.payload.limit;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetAdminList } = getAdminListSlice.actions;

export default getAdminListSlice.reducer;
