import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { profileOptionStyle } from './style';
import { User } from '../auth-gaurd/AuthGuard';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import { USER_DETAILS_KEY_NAME } from '../../common/constants/AppConstants';

const getName = (user) => {
  return [user?.firstName, user?.middleName, user?.lastName]
    .filter((name) => name != null)
    .reduce((name, partialName) => {
      return name + ' ' + partialName;
    }, '');
};

export default function ProfileOptions() {
  const navigate = useNavigate();
  const user = React.useContext(User);
  const [name, setName] = React.useState('Joel Jackson');

  const handleChange = (event) => {
    setName(event.target.value);
  };

  const logout = () => {
    localStorage.removeItem(USER_DETAILS_KEY_NAME);
    navigate(`/${ENDPOINTS.LOGIN}`);
  };

  return (
    <FormControl sx={profileOptionStyle.formControl}>
      <Select
        renderValue={() => getName(user)}
        onChange={handleChange}
        displayEmpty
        IconComponent={() => <KeyboardArrowDownIcon />}
        sx={profileOptionStyle.dropdownBox}
      >
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Select>
    </FormControl>
  );
}
