export const AccountDeleteConstant = {
  ACCEPT_STATUS: 'ACCEPTED',
  REJECT_STATUS: 'REJECTED',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
  PENDING: 'PENDING',
};

export const statusEnum = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
};
