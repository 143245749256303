import React, { useRef, useEffect } from 'react';
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import {
  GOOGLE_MAPS_MAPPING,
  libraries,
} from '../../common/constants/GoogleMap';
import configFile from '../../config';
const { API } = configFile;

const fetchInformation = (
  addressResult,
  informations = [
    GOOGLE_MAPS_MAPPING.COUNTRY,
    GOOGLE_MAPS_MAPPING.STATE,
    GOOGLE_MAPS_MAPPING.CITY,
    GOOGLE_MAPS_MAPPING.PIN_CODE,
    GOOGLE_MAPS_MAPPING.LATITUDE,
    GOOGLE_MAPS_MAPPING.LONGITUDE,
    GOOGLE_MAPS_MAPPING.ADDRESS,
  ]
) => {
  //--- On pressing enter key the api only gives the address component ---
  const addressComponent = addressResult.address_components
    ? addressResult.address_components
    : [addressResult];
  //-----------------------------------
  const result = {};
  for (const information of informations) {
    if (
      [
        GOOGLE_MAPS_MAPPING.LONGITUDE.searchName,
        GOOGLE_MAPS_MAPPING.LATITUDE.searchName,
      ].includes(information['searchName'])
    ) {
      result[information['actualName']] =
        addressResult.geometry.location[information['searchName']]();
      continue;
    }
    if (GOOGLE_MAPS_MAPPING.ADDRESS.searchName === information['searchName']) {
      result[information['actualName']] =
        addressResult[information['searchName']];
      continue;
    }
    for (const component of addressComponent) {
      if (component.types.includes(information['searchName'])) {
        result[information['actualName']] = component['long_name'];
        break;
      }
    }
  }
  return result;
};

const Place = ({ children, props: { setResult } }) => {
  const inputRef = useRef();

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: API.GOOGLE_MAPS_API_KEY,
    libraries,
    region: 'AE',
  });

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    places.length && setResult(fetchInformation(places[0]));
  };

  return !isLoaded ? (
    <>{children}</>
  ) : (
    <StandaloneSearchBox
      onLoad={(ref) => (inputRef.current = ref)}
      onPlacesChanged={handlePlaceChanged}
    >
      {children}
    </StandaloneSearchBox>
  );
};

export default Place;
