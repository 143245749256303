import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getAnnouncementList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.CREATE_ANNOUNCEMENT,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const createAnnouncement = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.CREATE_ANNOUNCEMENT,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};
