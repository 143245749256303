import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { toast } from 'react-toastify';
import { toasterStyle } from './Notify.style';

export default function notify(message, language) {
  toast.error(message, {
    position: 'bottom-center',
    hideProgressBar: true,
    style: toasterStyle(language),
    closeButton: false,
  });
}
