import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getAdminList = (query) => {

  return apiRequest({
    endpoint: API_ENDPOINTS.ADMIN_USER_LIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });
};
