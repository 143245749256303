import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';

import { getTarnsferDetails } from '../../api/transfer';
import { getFormattedDate, getPhone } from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { metalEnum } from './TransferConstant';
import { Colors } from '../../common/constants/Color';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import { getModulePerm, returnName } from '../../utils/helperUtility';
import './styles.css';
import { updateQuantity } from '../../api/add-edit-product';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

function TransaferDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const { language } = useContext(LanguageContext);

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  const requestId = () => {
    return query.get('id');
  };

  const getEmail = (data) => {
    if (!data) return;
    return data.email;
  };

  useEffect(() => {
    const id = requestId();
    const getDataForReq = async () => {
      try {
        setLoading(true);
        const response = await getTarnsferDetails(id);
        const data = response.data.data;
        if (data.createdAt) {
          data.createdAt = getFormattedDate(
            data.createdAt,
            'DD/MM/YYYY, HH:mm'
          );
        }

        data.phoneNo = data.mobileCode
          ? `(+${data.mobileCode}) ${data.phoneNo}`
          : data.phoneNo;

        setAccountData(data);

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    getDataForReq();
  }, []);

  const currentModulePerm = getModulePerm(MODULE_CONST.TRANSFER_MANAGEMENT);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.TRANSFER_LIST)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t('TRANSFER_DETAILS')}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('METAL_TYPE')}</div>
            <div className="transfer-details-value">
              {metalEnum[accountData.metalType] || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('TRANSFER_DATE')}</div>
            <div className="transfer-details-value">
              {accountData.createdAt || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('WEIGHT')}</div>
            <div className="transfer-details-value">
              {`${accountData.quantity} gm` || 'NA'}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('GIFTED_BY')}</div>
            <div className="transfer-details-value">
              {returnName(accountData.giftedBy, true)}
              <br />
              {getPhone(accountData.giftedBy)}
              <br />
              {getEmail(accountData.giftedBy)}
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="account-details-label">{t('GIFTED_TO')}</div>
            <div className="transfer-details-value">
              {returnName(accountData.giftedTo, true)}
              <br />
              {getPhone(accountData.giftedTo)}
              <br />
              {getEmail(accountData.giftedTo)}
            </div>
          </div>
        </div>
      </FormContainer>
    </div>
  );
}

export default TransaferDetails;
