import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const createAnnouncement = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.CREATE_ANNOUNCEMENT,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const getCityListing = ({ query, id }) => {
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_CITY}/${id}`
      : `${API_ENDPOINTS.GET_CITY}`,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });
};

export const getCountryList = () => {
  return apiRequest({
    endpoint: `/public/${API_ENDPOINTS.COUNTRIES}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};

export const addUpdateNewCity = ({ data }) => {
  const { payload, id } = data;
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_CITY}/${id}`
      : `${API_ENDPOINTS.GET_CITY}`,
    method: id ? METHODS.PUT : METHODS.POST,
    headers: {},
    data: payload,
    privateURL: true,
  });
};

export const getCityData = ({ id }) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.GET_CITY}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};
