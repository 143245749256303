export const resourceName = {
  singular: 'user',
  plural: 'users',
};

const CurrencyType = {
  SAR: 'SAR',
  AED: 'AED',
  EGP: 'EGP',
};

const gettransaction = (data, type) => {
  const filteredData = data.filter((item) => item.currency === type);
  const defaultStats = {totalAmount: 0, success: 0, pending: 0};
  if(!filteredData || filteredData.length === 0) {
    return defaultStats;
  }
  return filteredData[0];
};

export const getModifiedArray = (dashboardData) => {
  const { totalCount, active, inactive } = dashboardData.user;
  const {
    remaining: remainingSilver,
    stored: SilverStored,
    traded: SilverTraded,
  } = dashboardData.inventory.find((obj)=> obj.metalType === 'SILVER') ? dashboardData.inventory.find((obj)=> obj.metalType === 'SILVER') : {remaining: 0, stored: 0, traded :0 };
  const {
    remaining: remaininggold,
    stored: goldStored,
    traded: goldTraded,
  } = dashboardData.inventory.find((obj)=> obj.metalType === 'GOLD') ? dashboardData.inventory.find((obj)=> obj.metalType === 'GOLD') : {remaining: 0, stored: 0, traded :0 };
  const {
    totalAmount: totalAmountSAR,
    success: successSAR,
    pending: pendingSAR,
  } = gettransaction(dashboardData.transaction, CurrencyType.SAR);

  const {
    totalAmount: totalAmountEGP,
    success: successEGP,
    pending: pendingEGP,
  } = gettransaction(dashboardData.transaction, CurrencyType.EGP);

  const {
    totalAmount: totalAmountAED,
    success: successAED,
    pending: pendingAED,
  } = gettransaction(dashboardData.transaction, CurrencyType.AED);

  const cardListDetails = [
    {
      title: 'Registered Users',
      value: totalCount,
      icon: 'group',
      iconColor: 'label-pale-yellow',
      helperText: '',
    },
    {
      title: 'Active Users',
      value: active,
      icon: 'group',
      iconColor: 'label-pale-green',
      helperText: '',
    },
    {
      title: 'Inactive Users',
      value: inactive,
      icon: 'group',
      backgroundColor: '#303030',
      iconColor: 'label-red',
      helperText: '',
    },
    {
      title: 'Transactions',
      value: totalAmountSAR,
      icon: 'transaction',
      iconColor: 'label-violet',
      helperText: 'SAR',
    },

    {
      title: 'Transactions',
      value: totalAmountAED,
      icon: 'transaction',
      iconColor: 'label-violet',
      helperText: 'AED',
    },

    {
      title: 'Transactions',
      value: totalAmountEGP,
      icon: 'transaction',
      iconColor: 'label-violet',
      helperText: 'EGP',
    },

    {
      title: 'Completed Transactions',
      value: successSAR,
      icon: 'transaction',
      iconColor: 'label-pale-green',
      helperText: 'SAR',
    },

    {
      title: 'Completed Transactions',
      value: successAED,
      icon: 'transaction',
      iconColor: 'label-pale-green',
      helperText: 'AED',
    },

    {
      title: 'Completed Transactions',
      value: successEGP,
      icon: 'transaction',
      iconColor: 'label-pale-green',
      helperText: 'EGP',
    },

    {
      title: 'Pending Transactions',
      value: pendingSAR,
      icon: 'transaction',
      iconColor: 'label-pale-yellow',
      helperText: 'SAR',
    },

    {
      title: 'Pending Transactions',
      value: pendingAED,
      icon: 'transaction',
      iconColor: 'label-pale-yellow',
      helperText: 'AED',
    },

    {
      title: 'Pending Transactions',
      value: pendingEGP,
      icon: 'transaction',
      iconColor: 'label-pale-yellow',
      helperText: 'EGP',
    },

    {
      title: 'Gold Stored',
      value: goldStored,
      icon: 'inventory',
      iconColor: 'label-pale-yellow',
      helperText: 'gm',
    },
    {
      title: 'Gold Traded',
      value: goldTraded,
      icon: 'inventory',
      iconColor: 'label-violet ',
      helperText: 'gm',
    },

    {
      title: 'Gold Remaining',
      value: remaininggold,
      icon: 'inventory',
      iconColor: 'label-pale-green',
      helperText: 'gm',
    },

    {
      title: 'Silver Stored',
      value: SilverStored,
      icon: 'inventory',
      iconColor: 'label-pale-yellow',
      helperText: 'gm',
    },

    {
      title: 'Silver Traded',
      value: SilverTraded,
      icon: 'inventory',
      iconColor: 'label-violet',
      helperText: 'gm',
    },

    {
      title: 'Silver Remaining',
      value: remainingSilver,
      icon: 'inventory',
      iconColor: 'label-pale-green',
      helperText: 'gm',
    },
  ];
  return cardListDetails;
};
