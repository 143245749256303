import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getadminList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.ADMINLIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const getRolesList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.GET_ROLE,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const getCountriesList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.GET_COUNTRIES,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: false,
  });

export const createAdmin = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.CREATE_ADMIN,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const createAccountManager = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.CREATE_ACCOUNT_MANAGER,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const editAccountManager = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.CREATE_ACCOUNT_MANAGER}/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};

export const editAdmin = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.ADMINLIST}/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
export const getAdminDetails = (id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.ADMINLIST}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};

export const getAccountManagerDetails = (id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.CREATE_ACCOUNT_MANAGER}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};
