import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export function updateUser(userId, data) {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.UPDATE_ADMIN}${userId}`,
    method: METHODS.PUT,
    privateURL: true,
    data: data,
  });
}
