import API_ENDPOINTS from '../constants/apiEndpoints';
import apiRequest, { METHODS } from './apiRequest';

export const getUsersForQuery = async ({ query }) => {
  const data = await apiRequest({
    endpoint: `${API_ENDPOINTS.USER}?search_str=${query}`,
    method: METHODS.GET,
    privateURL: true,
  });
  return data;
};
