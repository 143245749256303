import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Scrollbar from '../../components/scrollbar';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from './../userPageStyle';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar';
import { getOrderList } from '../../api/order';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../components/select-input';
import './../AdminManagement/adminConstant';
import './../AdminManagement/adminManagement.css';
import DateRangePicker from '../../components/date-range-picker';
import {
  getFormattedDate,
  getFormattedName,
  getFormattedAddress,
  extractValuesIfNotTypeIsAll,
  extractStartAndEndDate,
} from '../../utils/helperUtility';
import { MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { ALL, NA } from './../../common/constants/AppConstants';
import { Colors } from './../../common/constants/Color';
import {
  ORDER_STATUS_FILTERS,
  DELIVERY_TYPE,
} from '../../common/constants/ModuleConstant';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import '../order-details-page/orderStyle.css';
import { orderStatusConstant } from '../order-details-page/orderConstant';
import { getThirdPartyClientListing } from '../../api/thirdPartyClient';
export function OrderListing() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(true);
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [clientList, setClientList] = useState([
    { value: undefined, label: 'All' },
  ]);
  const [searchFilter, setSearchFilter] = useState({
    deliveryType: ALL,
    createdAt: [null, null],
    status: ALL,
    clientId: undefined,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  const handleFilterSelection = (key, value) => {
    setSearchFilter({
      ...searchFilter,
      [key]: value,
    });
  };

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  function goToViewDetails(data) {
    navigate(`${ENDPOINTS.ORDER_DETAILS}?id=${data.id}`);
  }

  useEffect(() => {
    getClientList();
  }, []);

  const getClientList = async () => {
    try {
      const { data } = await getThirdPartyClientListing({ id: undefined });
      createClientFilterOptionList(data?.data?.result);
    } catch (error) {
      console.error(error);
    }
  };

  const createClientFilterOptionList = (clientList) => {
    const clientFilterOptionList = clientList.map((client) => {
      return {
        value: client.id,
        label: client.name,
      };
    });
    setClientList((prev) => [...prev, ...clientFilterOptionList]);
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, searchFilter]);

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getOrderList({
        pageLimit: limit,
        pageSkip: page - 1,
        searchStr: searchTerm ? searchTerm : undefined,
        status: extractValuesIfNotTypeIsAll(searchFilter, 'status'),
        deliveryType: extractValuesIfNotTypeIsAll(searchFilter, 'deliveryType'),
        fk_clients_id: extractValuesIfNotTypeIsAll(searchFilter, 'clientId'),
        ...extractStartAndEndDate(searchFilter.createdAt),
      });
      setOrderData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching order data:', error);
      setLoading(false);
    }
  };

  const ViewButton = function ({ rowItemData }) {
    return (
      <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
        <Typography
          fontWeight={'500'}
          fontSize={'12px'}
          fontFamily={'Inter'}
          color={Colors.GRAY}
          display={'flex'}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => goToViewDetails(rowItemData)}
        >
          {t('VIEW')}
        </Typography>
      </Box>
    );
  };

  const getCustomerDetails = (order) => {
    const { firstName, middleName, lastName, email } = order;
    return `${firstName || ''} ${middleName || ''} ${lastName || ''} ${email}
    ${getFormattedAddress(order)}`;
  };

  const currentItems = orderData.map((order) => ({
    id: order.id,
    orderNo: <Box>{order.orderNo ?? NA}</Box>,
    customerInfo: (
      <Box className="boxContent">
        {getFormattedName(order.firstName, order.middleName, order.lastName) ??
          ''}{' '}
        <br />
        {`${order.email},` ?? ''}
        {getFormattedAddress(order) ?? NA}
        {/* {getCustomerDetails(order)} */}
      </Box>
    ),
    itemCount: order.numberOfItems ?? NA,
    totalPrice: order.orderValue ? `${order.orderValue} ${order.currency}` : NA,
    status: orderStatusConstant[order.status] ?? NA,
    createdAt: getFormattedDate(order.createdAt, 'DD/MM/YYYY HH:mm') ?? NA,
    category: order.category ?? NA,
    deliveryMode: orderStatusConstant[order.deliveryMode] ?? NA,
    button: <ViewButton rowItemData={order} />,
  }));

  const roleColumns = [
    {
      key: 'orderNo',
      title: t('Order ID'),
      requiredStyle: { minWidth: '160px' },
    },
    {
      key: 'customerInfo',
      title: t('Customer Info'),
      requiredStyle: {
        minWidth: '160px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        wordBreak: 'break-all',
      },
    },
    {
      key: 'itemCount',
      title: t('Item Count'),
    },
    {
      key: 'totalPrice',
      title: t('Total Price'),
    },
    {
      key: 'deliveryMode',
      title: t('Delivery Type'),
    },
    {
      key: 'status',
      title: t('TH_STATUS'),
    },
    {
      key: 'createdAt',
      title: t('Date'),
    },
    {
      key: 'button',
      title: '',
    },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={'35px'}
      >
        <Typography variant="h4" gutterBottom>
          {t('Order Management')}
        </Typography>
      </Stack>

      <Card>
        <Scrollbar>
          <div className="filter-wrapper">
            <Stack direction={'row'} flexGrow={1} columnGap={'18px'}>
              <SelectInput
                placeholder={t('STATUS')}
                labelInline={true}
                containerSx={{ width: '140px' }}
                label={'status'}
                value={searchFilter.status}
                options={[ALL, ...ORDER_STATUS_FILTERS].map((status) => ({
                  label: t(status),
                  value: status,
                }))}
                handleSelectChange={(event) => {
                  handleFilterSelection('status', event);
                }}
              />
              <SelectInput
                placeholder={t('DELIVERY_TYPE')}
                labelInline={true}
                containerSx={{ width: '140px' }}
                label={'deliveryType'}
                value={searchFilter.deliveryType}
                options={[ALL, ...DELIVERY_TYPE].map((status) => ({
                  label: t(status),
                  value: status === ALL ? ALL : status.toUpperCase(),
                }))}
                handleSelectChange={(event) => {
                  handleFilterSelection('deliveryType', event);
                }}
              />
              <SelectInput
                labelInline={true}
                containerSx={{ width: '120px' }}
                options={clientList}
                value={searchFilter?.clientId}
                handleSelectChange={(value) => {
                  setSearchFilter((prev) => ({
                    ...prev,
                    clientId: value,
                  }));
                }}
              />
              <DateRangePicker
                placeholder={t('DATE_PICKER_LABEL')}
                dateRange={searchFilter.createdAt}
                setDateRange={(event) =>
                  handleFilterSelection('createdAt', event)
                }
              />
            </Stack>
            <SearchBar
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder={t('Search')}
              maxlength={MAX_INPUT_LENGTH}
              onClear={handleClearSearch}
            />
          </div>
          <CommonTable
            resourceName={resourceName}
            data={currentItems}
            columns={roleColumns}
            isCheckBox={false}
            loading={loading}
            onSortButtonClick={() => {}}
            handleStatusChange={handleStatusChange}
            emptyTableText={t('NO_ORDER_FOUND')}
          />
          <Box
            display="flex"
            justifyContent={'flex-end'}
            sx={userPageStyles.paginationContainerSx}
          >
            <Typography
              color={Colors.GRAY}
              fontSize={12}
              marginY={'6px'}
              fontFamily={'Inter'}
              fontWeight={'500'}
            >
              {t('PG_SHOWING')}
              <Typography
                component={'span'}
                fontSize={12}
                color={Colors.DARK_GRAY}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {Math.min(startIndex + 1, totalList)}-
                {Math.min(endIndex, totalList)}
              </Typography>
              {t('PG_FROM')}
              <Typography
                component={'span'}
                fontSize={12}
                color={Colors.DARK_GRAY}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {totalList}
              </Typography>
              {t('PG_RESULTS')}
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Scrollbar>
      </Card>
    </Container>
  );
}
