// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Container, Typography } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
// sections
import { style } from './LoginPage.style';
import AuthSideBar from '../components/auth-sidebar/AuthSideBar';
import Iconify from '../components/iconify';
import { VerifyOtpForm } from '../sections/auth/verify-otp';
import ENDPOINTS from '../constants/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
import ContentSideBox from '../components/content-sidebox/ContentSidebox';
import { styledContent, styledRoot } from './verifyOtp.style';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => styledRoot(theme));

const StyledContent = styled('div')(({ theme }) => styledContent(theme));

// ----------------------------------------------------------------------

export default function VerifyOtpPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useContext(LanguageContext);

  const redirectToPage = (path) => {
    navigate(`/${path}`);
  };

  useEffect(() => {
    const previousPath = location.state?.from;
    if (previousPath !== ENDPOINTS.FORGET_PASSWORD) {
      redirectToPage(ENDPOINTS.LOGIN);
    }
  }, [location, navigate]);

  return (
    <>
      <StyledRoot>
        <AuthSideBar />
        <ContentSideBox>
          <Container maxWidth="sm">
            <Box position={'absolute'} left={'44px'} top={'44px'}>
              <Button
                startIcon={<Iconify icon={'eva:chevron-left-fill'} />}
                onClick={() => navigate(-1)}
                sx={style.backButton}
              >
                {t('BACK_BTN_TITLE')}
              </Button>
            </Box>
            <StyledContent>
              <Typography color={'#212121'} sx={style.loginFormTitle(language)}>
                {t('VERIFY_OTP_PAGE_TITLE')}
              </Typography>
              <VerifyOtpForm />
            </StyledContent>
          </Container>
        </ContentSideBox>
      </StyledRoot>
    </>
  );
}
