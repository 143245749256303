import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const remarkSchemaForReject = Yup.object().shape({
  remark: Yup.string()
    .matches(REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS, 'Invalid remarks')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Field cannot be empty')
    .label('remark'),
});

export const remarkSchemaAccept = Yup.object().shape({
  remark: Yup.string()
    .matches(REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS, 'Invalid remarks')
    .max(50, 'Too Long!')
    .label('remark'),
});
