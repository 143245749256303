import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getOrderDetails = (id) => {
  const payload = {
    endpoint: `orders/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  };
  return apiRequest(payload);
};

export const updateorderStatus = (data, id) => {
  return apiRequest({
    endpoint: `orders/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
    paymentUrl: true,
  });
};
