export default {
  USER_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/user$/,
      /dashboard\/view-user\?id=[a-zA-Z0-9\-]+$/,
      /dashboard\/delete-account$/,
      /dashboard\/delete-account\/details\?id=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [/dashboard\/edit-user\?id=[a-zA-Z0-9\-]+$/],
  },
  PRODUCT_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/products$/,
      /dashboard\/add-product\?productId=[a-zA-Z0-9\-]+&view=true/,
    ],
    writeUrlPatterns: [
      /dashboard\/add-product$/,
      /dashboard\/add-product\?productId=[a-zA-Z0-9\-]+$/,
    ],
  },
  REPORTING_ANALYTICS: {
    readUrlPatterns: [/dashboard\/reports$/],
  },
  TRANSACTION_HISTORY: {
    readUrlPatterns: [
      /dashboard\/transaction$/,
      /dashboard\/transaction-details\?transactionId=.+$/,
    ],
  },
  ADMIN_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/admin$/,
      /dashboard\/admin-form\?adminId=[a-zA-Z0-9\-]+&view=true$/,
      /dashboard\/admin-form\?view=true&adminId=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [
      /dashboard\/admin-form\?adminId=[a-zA-Z0-9\-]+$/,
      /dashboard\/admin-form$/,
    ],
  },
  ROLE_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/role$/,
      /dashboard\/role-form\?roleId=[a-zA-Z0-9\-]+&view=true$/,
      /dashboard\/role-form\?view=true&roleId=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [
      /dashboard\/role-form\?roleId=[a-zA-Z0-9\-]+$/,
      /dashboard\/role-form$/,
    ],
  },
  TRANSFER_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/transfer-gold-silver$/,
      /dashboard\/transfer-gold-silver\/details\?id=[a-zA-Z0-9\-]+$/,
      ,
    ],
  },
  VERIFICATION_APPROVAL: {
    readUrlPatterns: [
      /dashboard\/kyc$/,
      /dashboard\/kyc-verification\?id=[a-zA-Z0-9\-]+$/,
    ],
  },
  ORDER_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/orders$/,
      /dashboard\/order-details\?id=[a-zA-Z0-9\-]+$/,
    ],
  },
  WAREHOUSE_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/warehouse$/,
      /dashboard\/warehouse\/form\?id=[a-zA-Z0-9\-]+&view=true$/,
      /dashboard\/warehouse\/form\?view=true&id=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [
      /dashboard\/warehouse\/form$/,
      /dashboard\/warehouse\/form\?id=[a-zA-Z0-9\-]+$/,
    ],
  },
  ACCOUNT_MANAGER: {
    readUrlPatterns: [
      /dashboard\/account-manager$/,
      /dashboard\/account-manager-form\?id=[a-zA-Z0-9\-]+&view=true$/,
      /dashboard\/account-manager-form\?view=true&id=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [
      /dashboard\/account-manager-form\?id=[a-zA-Z0-9\-]+$/,
      /dashboard\/account-manager-form$/,
    ],
  },
  ANNOUNCEMENT_MANAGEMENT: {
    readUrlPatterns: [/dashboard\/announcement$/],
    writeUrlPatterns: [/dashboard\/announcement-form$/],
  },
  DASHBOARD: {
    readUrlPatterns: [/dashboard\/app$/],
  },
  TICKET_MANAGEMENT: {
    readUrlPatterns: [
      /dashboard\/ticket-management$/,
      /dashboard\/ticket-management\/details\?id=[a-zA-Z0-9\-]+$/,
    ],
    writeUrlPatterns: [
      /dashboard\/ticket-management$/,
      /dashboard\/ticket-management\/details\?id=[a-zA-Z0-9\-]+$/,
    ],
  },
  BANK_TRANSFERS: {
    readUrlPatterns: [/dashboard\/bank-transfers$/],
  },
  WITHDRAWALS: {
    readUrlPatterns: [/dashboard\/withdrawals$/],
    writeUrlPatterns: [/dashboard\/withdraw\/details$/],
  },
  CITY_MANAGEMENT: {
    readUrlPatterns: [/dashboard\/city-management$/],
    writeUrlPatterns: [
      /dashboard\/add-new-city\?id=[a-zA-Z0-9\-]+$/,
      /dashboard\/add-new-city$/
    ],
  },
};
