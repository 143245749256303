export const defaultScreenStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
};

export const defaultModalStyle = {
  width: '456px',
  backgroundColor: 'white',
  borderRadius: '14px',
  padding: '22px 32px 32px 32px',
  outline: 'none',
};
