import { Typography } from '@mui/material';
import { Stack, Box } from '@mui/system';
import { useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { useTranslation } from 'react-i18next';
import {
  languageSwitcherStyle,
  languageSwitcherTextStyle,
  synkArrowStyle,
} from './LanguageSwitcher.style';
import { LanguageContext } from './../../globalContext/LanguageSwitcher';
import {
  APP_LANGUAGE,
  ARABIC,
  ENGLISH,
} from './../../common/constants/AppConstants';

export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const { language, setLanguage } = useContext(LanguageContext);

  function handleLanguageChange() {
    if (language === APP_LANGUAGE.ENGLISH) {
      i18n.changeLanguage(APP_LANGUAGE.ARABIC);
      setLanguage(APP_LANGUAGE.ARABIC);
      return;
    }
    i18n.changeLanguage(APP_LANGUAGE.ENGLISH);
    setLanguage(APP_LANGUAGE.ENGLISH);
  }

  return (
    <Stack
      className="cursor-pointer ms-2"
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      onClick={() => handleLanguageChange()}
    >
      <Typography>
        {language === APP_LANGUAGE.ENGLISH ? ENGLISH : ARABIC}
      </Typography>
      <IconButton disableRipple>
        <SyncAltIcon />
      </IconButton>
    </Stack>
  );
}
