import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getDeleteAccountList = (query) => {
  const payload = {
    endpoint: API_ENDPOINTS.ACCOUNT_LIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  };
  return apiRequest(payload);
};

export const getAccountDetails = (id) => {
  const payload = {
    endpoint: `${API_ENDPOINTS.ACCOUNT_LIST}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  };
  return apiRequest(payload);
};

export const approveRejectReq = (data, id) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.ACCOUNT_LIST}/${id}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
