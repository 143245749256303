export const userPageStyles = {
  buttonSx: {
    backgroundColor: '#E3CA7E',
    color: '#4A4A4A',
    paddingX: '16px',
    paddingY: '10px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '16px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#E3CA7E',
    },
  },
  penIconSx: {
    color: '#A5A4A2',
    width: 13,
    height: 13,
  },
  deleteIconSx: {
    color: '#AE3F1E',
    width: 18.33,
    height: 20,
    cursor: 'pointer',
  },
  paginationContainerSx: { backgroundColor: '#F7F7F7', paddingY: '6px' },

  subHeadSx: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '550',
    lineHeight: '16px',
    letterSpacing: '0px !important',
    color: '#616161',
    letterSpacing: '0em',
  },
  userNameSx: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
  },
  roleTextSx: {
    color: '#777673',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '0em',
  },
  confirmationText: {
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: '550',
    lineHeight: '16px',
    letterSpacing: '0px !important',
    color: '#616161',
    letterSpacing: '0em',
  },
};
