import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../../../common/constants/Color';
import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';

import {
  successToaster,
  errorToaster,
  getModulePerm,
} from './../../../../utils/helperUtility';

import AuthInput from '../../../../components/auth-input';
import PhoneNumber from '../../../../components/phone-number';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../../../assets/icons/left_chevron.svg';
import {
  createAccountManager,
  getCountriesList,
  editAccountManager,
  getAccountManagerDetails,
} from '../../../../api/adminManagement';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../../auth/login/LoginForm.style';

import { addAccountManagerSchema } from '../../../../schema/addAccountManager';
import ENDPOINTS from '../../../../constants/endpoints';
import { MODULE_CONST } from '../../../../common/constants/ModuleConstant';
import RadioInput from '../../../../components/radio-input';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function AccountManagerForm({ isOpen, onClose, ...props }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [pageTitle, setPageTitle] = useState('CREATE_ACCOUNT_MANAGER');
  const [countries, setCountries] = useState([]);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    isActive: true,
  });

  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'isActive',
        value: value,
      },
    });
  };
  const getId = () => {
    return query.get('id');
  };
  const isViewForm = () => {
    return !!getId() && !!query.get('view');
  };
  const isEditForm = () => {
    return !!getId() && !isViewForm();
  };

  useEffect(() => {
    const fetchAllData = async () => {
      if (isViewForm() || isEditForm()) {
        setPageTitle('ACCOUNT_MANAGER_DETAILS');
      }
      const resCountries = await getCountriesList();

      const modifiedCountry = resCountries.data.data.map((country) => {
        return {
          ...country,
          id: country.id,
        };
      });
      setCountries(modifiedCountry);
      if (!getId()) {
        setInitialValues((prev) => ({
          ...prev,
          countryCode: modifiedCountry[0]?.id,
        }));
      }
      if (getId()) {
        getAccountManagerDetails(getId())
          .then((data) => {
            const {
              firstName,
              middleName,
              lastName,
              email,
              phoneNo,
              role,
              mobileCode,
              isActive,
            } = data.data.data;
            const selectedCountries = modifiedCountry.find((data) => {
              return data.mobileCode === mobileCode;
            });

            const receivedValue = {
              firstName: firstName || '',
              middleName: middleName || '',
              lastName: lastName || '',
              email: email || '',
              phoneNumber: phoneNo || '',
              countryCode: selectedCountries?.id || '',
              isActive: isActive || false,
            };
            setInitialValues(receivedValue);
          })
          .catch((err) => {
            errorToaster(err.data?.message, language);
          });
      }
    };
    fetchAllData();
  }, []);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addAccountManagerSchema,
    onSubmit: async (values) => {
      isEditForm()
        ? submitEditAccountManager(values)
        : submitCreateAccountManager(values);
    },
  });

  const submitCreateAccountManager = async (values) => {
    try {
      setLoading(true);
      let {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        countryCode,
        isActive,
      } = values;
      const payload = {
        firstName,
        middleName,
        lastName,
        email,
        phoneNo: phoneNumber,
        fkCountriesId: countryCode,
      };
      const response = await createAccountManager({ ...payload });
      if (response.data.isSuccess) {
        successToaster(t('ACCOUNT_MANAGER_ADDED_SUCCESS'), language);
        navigate(ENDPOINTS.ACCOUNT_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const submitEditAccountManager = async (values) => {
    try {
      setLoading(true);
      let { firstName, middleName, lastName, phoneNumber, isActive } = values;
      const payload = {
        firstName,
        middleName,
        lastName,
        isActive,
        phoneNo: phoneNumber,
      };
      const response = await editAccountManager(payload, getId());
      if (response.data.isSuccess) {
        successToaster(t('ACCOUNT_MANAGER_UPDATE_SUCCESS'), language);
        navigate(ENDPOINTS.ACCOUNT_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.ACCOUNT_MANAGEMENT);
  };

  const handleSubmit = (event) => {
    if (isViewForm()) {
      navigate(`${ENDPOINTS.ACCOUNT_MANAGER_FORM}?id=${getId()}`);
      return;
    }
    formik.submitForm(event);
  };

  const getDisableCond = () => {
    if (isViewForm()) return false;
    return !(formik.isValid && formik.dirty);
  };

  const currentModulePerm = getModulePerm('ACCOUNT_MANAGER');

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.ACCOUNT_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t(pageTitle)}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="firstName"
            label={t('FIRST_NAME')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('firstName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('FIRST_NAME')}
            name="firstName"
            id="firstName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('firstName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('firstName') && t(formik.errors.firstName)}
          />
          <AuthInput
            className="col-12 col-md-4"
            htmlFor="middleName"
            label={t('MIDDLE_NAME')}
            disabled={isViewForm()}
            sx={textInputField(isFieldError('middleName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('MIDDLE_NAME')}
            name="middleName"
            id="middleName"
            onChange={formik.handleChange}
            value={formik.values.middleName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('middleName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('middleName') && t(formik.errors.middleName)
            }
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="lastName"
            label={t('LAST_NAME')}
            disabled={isViewForm()}
            required={true}
            sx={textInputField(isFieldError('lastName'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('LAST_NAME')}
            name="lastName"
            id="lastName"
            onChange={formik.handleChange}
            value={formik.values.lastName}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('lastName')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('lastName') && t(formik.errors.lastName)}
          />

          <AuthInput
            className="col-12 col-md-4"
            htmlFor="email"
            label={t('EMAIL_TEXT')}
            disabled={isViewForm() || isEditForm()}
            required={true}
            sx={textInputField(isFieldError('email'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('EMAIL_TEXT')}
            name="email"
            id="email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('email')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('email') && t(formik.errors.email)}
          />
          <PhoneNumber
            className="col-12 col-md-4"
            placeholderForCountry="Country code"
            fieldName="countryCode"
            multiSelect={false}
            labelName={t('PHONE_NUMBER')}
            options={countries}
            disabled={isViewForm() || isEditForm()}
            disableInput={isViewForm()}
            selectedOptions={formik.values.countryCode}
            formik={formik}
            errorText={
              isFieldError('countryCode') && t(formik.errors.countryCode)
            }
            htmlFor="phoneNumber"
            label={''}
            required={true}
            sx={textInputField(isFieldError('phoneNumber'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
            placeholder={t('PHONE_NUMBER')}
            name="phoneNumber"
            id="phoneNumber"
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('phoneNumber')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              isFieldError('phoneNumber') && t(formik.errors.phoneNumber)
            }
          />
          {(isViewForm() || isEditForm()) && (
            <div className="col-12 col-md-4">
              <RadioInput
                disabled={isViewForm()}
                parentLabel={t('STATUS')}
                required={true}
                value={formik.values.isActive}
                textFieldError={isFieldError('isActive')}
                helperText={isFieldError('isActive')}
                options={[
                  { label: t('ACTIVE'), value: true },
                  { label: t('INACTIVE'), value: false },
                ]}
                labelKey="isActive"
                handleChange={handleRadioValueChange}
              />
            </div>
          )}
        </div>
        <FormButtons>
          <CancelButton onClick={() => handleCancelButton()}>
            {t('CANCEL')}
          </CancelButton>

          {currentModulePerm.writeMode && (
            <SubmitButton
              type="submit"
              onClick={handleSubmit}
              disabled={getDisableCond()}
            >
              {t(
                !isEditForm()
                  ? isViewForm()
                    ? 'EDIT'
                    : 'CREATE_ACCOUNT_MANAGER'
                  : 'SAVE'
              )}
            </SubmitButton>
          )}
        </FormButtons>
      </FormContainer>
    </div>
  );
}
