import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getGiftSettingListing = ({ query, id }) => {
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_GIFT_SETTINGS}/${id}`
      : `${API_ENDPOINTS.GET_GIFT_SETTINGS}`,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });
};

export const getCountryList = () => {
  return apiRequest({
    endpoint: `/public/${API_ENDPOINTS.COUNTRIES}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};

export const configureSettings = ({ data }) => {
  const { payload, id } = data;
  return apiRequest({
    endpoint: id
      ? `${API_ENDPOINTS.GET_GIFT_SETTINGS}/${id}`
      : `${API_ENDPOINTS.GET_GIFT_SETTINGS}`,
    method: id ? METHODS.PUT : METHODS.POST,
    headers: {},
    data: payload,
    privateURL: true,
  });
};
