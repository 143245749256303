/* eslint-disable consistent-return */
// import { notify } from '@atoms/FMAlert/FMAlert.component';
import { createAsyncThunk } from '@reduxjs/toolkit';
// import { setLoading } from 'redux/slices/loader.slice';

const asyncActionCreator = (typePrefix, apiCall, config) => {
  const payloadCreator = async (data, { rejectWithValue, dispatch }) => {
    const { reqData, callbacks } = data;
    try {
      // loader
      // if(!config?.doNotLoad ){
      // dispatch(setLoading(true));}

      // API call
      const response = await apiCall(reqData);
      const { data: responseData } = response;

      // notification
      // if(!config?.doNotNotify){
      //   notify({
      //     content:
      //       config?.messages?.success ||
      //       responseData?.message ||
      //       responseData?.data?.message,
      //     type: 'success',
      //   });}

      // callback:try
      if (callbacks) callbacks?.try?.(responseData);
      if (config?.suppressResponse) return;

      return responseData;
    } catch (error) {
      let errorData = error.data;

      if (error.data instanceof Blob) {
        const blobError = await error.data.text();
        errorData = JSON.parse(blobError);
      }

      // notification
      // if(config?.errorsOnly || !config?.doNotNotify)
      //   notify({
      //     content:
      //       config?.messages?.error ||
      //       errorData?.message ||
      //       'Something went wrong',
      //     type: 'error',
      //   });

      // callback:catch
      if (callbacks) callbacks?.catch?.(errorData);

      return rejectWithValue(errorData);
    } finally {
      // loader
      // if(!config?.doNotLoad){
      //  dispatch(setLoading(false));}

      // callback:finally
      if (callbacks) callbacks?.finally?.();
    }
  };
  return createAsyncThunk(typePrefix, payloadCreator);
};

export default asyncActionCreator;
