import React, { useRef, useEffect } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import Iconify from '../iconify';
import { style } from './styles';
import { debounce } from 'lodash';

const SearchBar = ({ onChange, value, onClear, placeholder, maxlength }) => {
  const inputRef = useRef(null);
  const handleChange = debounce((event) => {
    onChange(event);
  }, 500);

  const handleClearChange = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onClear();
  };

  const clearIconAdornment = (
    <InputAdornment position="end">
      <IconButton onClick={handleClearChange}>
        <Iconify icon={'eva:close-fill'} sx={{ color: '#4A4A4A' }} />
      </IconButton>
    </InputAdornment>
  );

  const invisibleIconAdornment = (
    <InputAdornment position="end">
      <span style={style.crossIconSx} />
    </InputAdornment>
  );

  return (
    <Box display="flex" justifyContent="flex-end">
      <TextField
        inputRef={inputRef}
        variant="outlined"
        placeholder={placeholder}
        onChange={handleChange}
        sx={style.textInputField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start">
                <Iconify icon={'jam:search'} sx={{ color: '#4A4A4A' }} />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: value ? clearIconAdornment : invisibleIconAdornment,
        }}
        inputProps={{
          maxLength: maxlength,
        }}
      />
    </Box>
  );
};

export default SearchBar;
