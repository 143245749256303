import API_ENDPOINTS from '../constants/apiEndpoints';
import apiRequest, { METHODS } from './apiRequest';

export const getReportList = (query) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.REPORT,
    query,
    method: METHODS.GET,
    privateURL: true,
    paymentUrl: true,
  });
};

export const getReportTypes = () => {
  return apiRequest({
    endpoint: API_ENDPOINTS.REPORT_TYPES,
    method: METHODS.GET,
    privateURL: true,
    paymentUrl: true,
  });
};

export const generateReport = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.REPORT,
    method: METHODS.POST,
    data,
    privateURL: true,
    paymentUrl: true,
  });
};

export const downloadReport = (reportId) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.REPORT}/${reportId}`,
    method: METHODS.GET,
    privateURL: true,
    paymentUrl: true,
  });
};
