const generateGoogleMapSearchableMapping = (searchName, actualName) => ({
  searchName,
  actualName,
});

export const GOOGLE_MAPS_MAPPING = {
  COUNTRY: generateGoogleMapSearchableMapping('country', 'country'),
  STATE: generateGoogleMapSearchableMapping(
    'administrative_area_level_1',
    'state'
  ),
  CITY: generateGoogleMapSearchableMapping(
    'administrative_area_level_3',
    'city'
  ),
  PIN_CODE: generateGoogleMapSearchableMapping('postal_code', 'zipCode'),
  LONGITUDE: generateGoogleMapSearchableMapping('lat', 'latitude'),
  LATITUDE: generateGoogleMapSearchableMapping('lng', 'longitude'),
  ADDRESS: generateGoogleMapSearchableMapping('formatted_address', 'address'),
};

export const libraries = ['places'];
