// # REACT_APP_BASE_URL=https://smartsigndev.azurewebsites.net
// # REACT_APP_CACHE_ENABLED=true
// # REACT_APP_VERSION=v1.0

const API = {
  REACT_APP_BASE_URL: '',
  REACT_APP_CACHE_ENABLED: true,
};

module.exports = {
  API,
};
