import ReactDOM from 'react-dom/client';

//
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';

import i18n from './i18n/config';
import { LanguageProvider } from './globalContext/LanguageSwitcher';
import { ToastContainer } from 'react-toastify';

import '@shopify/polaris/build/esm/styles.css';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <LanguageProvider>
    <I18nextProvider i18n={i18n}>
      <App />
      <ToastContainer closeButton={false} limit={1} />
    </I18nextProvider>
  </LanguageProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
