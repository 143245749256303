import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import moment from 'moment';
import Iconify from '../iconify';
const DateRangePicker = ({
  dateRange,
  setDateRange,
  placeholder,
  dateFormat = 'dd/MM/yyyy',
  popperPlacement = 'bottom-start',
}) => {
  const [startDate, endDate] = dateRange;

  const [localState, setLocalState] = useState([startDate, endDate]);
  const [localStartDate, localEndDate] = localState;

  const datePickerRef = useRef(null); // Create a ref for the DatePicker
  const openDatePicker = () => {
    datePickerRef.current.setOpen(true);
  };
  const updateSelectedDate = (event) => {
    if (moment(event[1]).isValid()) {
      setDateRange(event);
      setLocalState(event);
    } else {
      if (!moment(event[0]).isValid() && !moment(event[1]).isValid()) {
        setDateRange(event);
      }
      setLocalState(event);
    }
  };
  return (
    <div className="app-date-range-picker">
      <DatePicker
        ref={datePickerRef}
        selectsRange={true}
        startDate={localStartDate}
        endDate={localEndDate}
        onChange={updateSelectedDate}
        isClearable={true}
        monthsShown={2}
        maxDate={new Date()}
        dateFormat={dateFormat}
        popperPlacement={popperPlacement}
      />
      <span className="cal-icon" onClick={openDatePicker}>
        <Iconify icon="uil:calender" width={18} height={18} />
      </span>
      {!moment(localStartDate)?.isValid() ? (
        <span className="range-placeholder" onClick={openDatePicker}>
          {placeholder}
        </span>
      ) : null}
    </div>
  );
};

export default DateRangePicker;
