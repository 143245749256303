export const profileOptionStyle = {
  dropdownBox: {
    color: 'white',
    backgroundColor: '#303030',
    height: '28px',
    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
  formControl: { m: 1, minWidth: 120 },
};

export const navTopBarStyle = {
  toolbar: {
    backgroundColor: 'black',
    minHeight: '44px',
  },
  fullSizeContainer: { width: '100%', height: '100%' },
  icons: { width: 60, height: 60 },
  notificationIcon: { color: 'white', cursor: 'pointer' },
};
