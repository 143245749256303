import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';

export const basekValidationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
});

export const placedKSchema = basekValidationSchema.concat(
  Yup.object({
    remark: Yup.string()
      .matches(
        REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS,
        'Invalid remarks'
      )
      .required('Field cannot be empty'),
  })
);
export const rejectKSchema = basekValidationSchema.concat(
  Yup.object({
    remark: Yup.string()
      .matches(
        REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS,
        'Invalid remarks'
      )
      .required('Field cannot be empty'),
  })
);

export const confirmKSchema = basekValidationSchema.concat(
  Yup.object({
    remark: Yup.string().matches(
      REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS,
      'Invalid remarks'
    ),
  })
);

export const shippedKSchema = basekValidationSchema.concat(
  Yup.object({
    status: Yup.string().required('Status is required'),
    partnerName: Yup.string()
      .matches(REGEX_PATTERN.ALPHA_NUMERIC, 'Invalid partner name')
      .required('Field cannot be empty'),
    trackingId: Yup.string()
      .matches(
        REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS_NO_SPACE,
        'Invalid tracking id'
      )
      .required('Field cannot be empty'),
    // trackingLink: Yup.string().required(t('Tracking link is required')),
  })
);
