import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getDashboardData = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.DASHBOARD,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });
