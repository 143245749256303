import { InputLabel, Stack, TextField } from '@mui/material';
import { inputLabelStyle, style, imageStyle } from './style';
import { useContext } from 'react';
import { REGEX_PATTERN } from '../../constants/regex';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import './style.scss';
import { Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import EGP from '../../assets/icons/egypt.jpeg';
import AED from '../../assets/icons/unitedArab.png';
import SAR from '../../assets/icons/saudi.png';

import {
  selectInputField,
  textInputFieldStyle,
} from './../../sections/auth/login/LoginForm.style';
const PhoneNumber = ({
  label,
  handleFormData,
  placeholderForCountry,
  textFieldError,
  helperText,
  containerSx,
  textFieldStyle,
  maxLength,
  className,
  t,
  formik,
  fieldName,
  placeholder,
  multiSelect = false,
  labelName,
  options,
  disabled = false,
  errorText,
  selectedOptions,
  setSelectedOptions,
  disableInput,
  required = false,
  ...props
}) => {
  const { language } = useContext(LanguageContext);
  const isFieldError = (formik, fieldName) => {
    if (!formik.touched[fieldName] && formik.values[fieldName] === '')
      return true;
    return !!formik.errors[fieldName];
  };
  const handleChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue(fieldName, value);
  };
  const countryFlag = {
    EGP,
    AED,
    SAR,
  };

  const handleEnteredvalue = (event) => {
    const inputValue = event.key;
    if (!REGEX_PATTERN.NUMBERONLY.test(inputValue)) {
      event.preventDefault();
    }
  };

  const renderValue = (selected) => {
    const selectedOption = options.find((option) => option.id === selected);

    return (
      <div className="d-flex w-100 gap-1 justify-content-between">
        <img src={countryFlag[selectedOption?.currency]} style={imageStyle} />
        <div> {`(+${selectedOption?.mobileCode})`}</div>
      </div>
    );
  };
  return (
    <Stack sx={containerSx} className={`${className} auth-input-container`}>
      <InputLabel sx={inputLabelStyle(language)}>
        <span className={required ? 'asterisk-content' : ''}>{labelName}</span>
      </InputLabel>
      <div className="row gx-1">
        <div className="col-4">
          <Select
            disabled={disabled}
            id={fieldName}
            sx={selectInputField(
              isFieldError(formik, fieldName) &&
                formik.values[fieldName] !== '',
              language
            )}
            input={<OutlinedInput />}
            inputProps={{ sx: textInputFieldStyle }}
            multiple={multiSelect}
            height="40px"
            fullWidth
            name={fieldName}
            value={selectedOptions}
            renderValue={renderValue}
            placeholder={placeholderForCountry}
            onChange={(event) =>
              handleChange(event, setSelectedOptions, options)
            }
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <div className="d-flex gap-2">
                  <img style={imageStyle} src={countryFlag[option.currency]} />
                  {option.mobileCode}
                </div>
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          disabled={disableInput}
          className="col-8"
          sx={{ ...style.textInputField, height: '40px', ...textFieldStyle }}
          inputProps={{
            sx: style.textInputFieldStyle,
            maxLength,
            pattern: '[0-9]*',
          }}
          onKeyPress={handleEnteredvalue}
          placeholder={placeholder}
          onChange={handleFormData}
          error={Boolean(textFieldError)}
          helperText={helperText || ' '}
          {...props}
        />
      </div>
    </Stack>
  );
};

export default PhoneNumber;
