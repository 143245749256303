import { useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { style, resetPasswordInputStyle } from './style';
import Iconify from '../../../components/iconify';
import { tests } from '../../../common/constants/AppConstants';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../../api/resetPassword';
import ENDPOINTS from '../../../constants/endpoints';
import AuthInput from '../../../components/auth-input';
import { errorToaster, successToaster } from '../../../utils/helperUtility';
import { LanguageContext } from '../../../globalContext/LanguageSwitcher';
import { formHelperStyle, textInputField } from '../login/LoginForm.style';

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [pass, setPass] = useState({
    changePassword: '',
    confirmPassword: '',
  });

  const [isPasswordMatched, setPasswordmatched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const { language } = useContext(LanguageContext);

  const passwordPolicies = {
    length: pass.changePassword.length >= 8,
    upper: tests.upperCase.test(pass.changePassword),
    lower: tests.lowerCase.test(pass.changePassword),
    number: tests.digits.test(pass.changePassword),
    special: tests.symbol.test(pass.changePassword),
  };

  const allPoliciesSatisfied = Object.values(passwordPolicies).every(Boolean);

  useEffect(() => {
    !pass.changePassword
      ? setPasswordmatched(false)
      : setPasswordmatched(pass.changePassword === pass.confirmPassword);
  }, [pass]);

  const redirectToPage = (path) => {
    navigate(`/${path}`);
  };

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPass((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const isRequired = JSON.parse(
      localStorage.getItem('changePasswordRequired')
    );
    setIsLoading(true);
    try {
      const response = await resetPassword(email, pass.confirmPassword);
      if (response?.data?.isSuccess) {
        setIsLoading(false);
        setTimeout(() => {
          if (isRequired) {
            localStorage.removeItem('changePasswordRequired');
            navigate(ENDPOINTS.DASHBOARD, { replace: true });
          } else {
            redirectToPage(ENDPOINTS.LOGIN);
          }
        }, 3000);
        successToaster(t('RESET_PASSWORD_SUCCESSFUL_MSG'));
      }
    } catch (err) {
      setIsLoading(false);
      errorToaster(err?.data?.message ?? t('SOME_ERROR_OCCOURED'));
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack sx={{ gap: '44px' }}>
        <Stack>
          <AuthInput
            label={t('CHANGE_PASSWORD_LABEL_TEXT')}
            maxLength={20}
            placeholder={t('PLACEHOLDER_CHANGE_PASS_TEXT')}
            name={'changePassword'}
            id={'changePassword'}
            onChange={handlePasswordChange}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            textFieldStyle={style.textFieldStyle(allPoliciesSatisfied)}
          />
          {!allPoliciesSatisfied ? (
            <>
              <Typography
                color={'#777673'}
                fontWeight={500}
                fontFamily={'Montserrat'}
                fontSize={'12px'}
                marginLeft={language === 'ar' ? 'auto' : ''}
                mt={'-20px'}
              >
                {t('PASSWORD_POLICY_TEXT')}
              </Typography>
              <Box>
                {Object.entries(passwordPolicies).map(
                  ([policy, isSatisfied]) => {
                    let policyText;
                    switch (policy) {
                      case 'length':
                        policyText = t('PASSWORD_LENGTH_POLICY');
                        break;
                      case 'upper':
                        policyText = t('PASSWORD_UPPERCASE_POLICY');
                        break;
                      case 'lower':
                        policyText = t('PASSWORD_LOWERCASE_POLICY');
                        break;
                      case 'number':
                        policyText = t('PASSWORD_NUMERIC_POLICY');
                        break;
                      case 'special':
                        policyText = t('PASSWORD_SYMBO_POLICY');
                        break;
                      default:
                        policyText = '';
                    }
                    return (
                      <Box key={policy} display="flex" alignItems="center">
                        <Iconify
                          icon={'mdi:checkbox-blank-circle'}
                          style={{
                            color: isSatisfied ? '#B0DC78' : '#777673',
                            height: 6,
                            order: language === 'ar' ? 2 : 1,
                          }}
                        />
                        <Typography
                          variant="body1"
                          fontFamily={'Lato'}
                          fontSize={'12px'}
                          fontWeight={500}
                          color={'#777673'}
                          marginLeft={language === 'ar' ? 'auto' : '-5px'}
                          sx={{ order: language === 'ar' ? 1 : 2 }}
                        >
                          {policyText}
                        </Typography>
                      </Box>
                    );
                  }
                )}
              </Box>
            </>
          ) : (
            <>
              <Box
                display="flex"
                alignItems="center"
                mt={'-17px'}
                marginLeft={language === 'ar' ? 'auto' : ''}
              >
                <Iconify
                  icon={'eva:checkmark-circle-2-outline'}
                  style={resetPasswordInputStyle(language)}
                />
                <Typography
                  variant="body1"
                  fontFamily={'Inter'}
                  fontSize={'13px'}
                  fontWeight={500}
                  color={'#17840B'}
                >
                  {t('PASSWORD_STRONG_TEXT')}
                </Typography>
              </Box>
            </>
          )}
          <AuthInput
            onChange={handlePasswordChange}
            label={t('CONFIRM_PASSWORD_LABEL_TEXT')}
            placeholder={t('PLACEHOLDER_CONFIRM_PASS_TEXT')}
            containerSx={{ mt: `${allPoliciesSatisfied ? '12px' : '20px'}` }}
            id="confirmPassword"
            name="confirmPassword"
            sx={textInputField(
              !isPasswordMatched && pass.confirmPassword != '',
              language
            )}
            error={!isPasswordMatched && pass.confirmPassword != ''}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={
              !isPasswordMatched && pass.confirmPassword != ''
                ? t('BOTH_PASSWORDS_SHOULD_BE_SAME')
                : ' '
            }
            type={showConfirmPassword ? 'text' : 'password'}
            maxLength={20}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showConfirmPassword
                          ? 'eva:eye-fill'
                          : 'eva:eye-off-fill'
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          onClick={handleOnSubmit}
          loading={isLoading}
          variant="contained"
          sx={style.formButtonStyle}
          disabled={!isPasswordMatched || !allPoliciesSatisfied}
        >
          {isLoading ? '' : t('UPDATE_PASSWORD_BTN_TEXT')}
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default ResetPasswordForm;
