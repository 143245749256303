export const TRANSFER_STATUS = {
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export const bankTransferConstants = {
  ACCEPT_STATUS: 'APPROVED',
  REJECT_STATUS: 'REJECTED',
  MANUAL_DEPOSIT: 'MANUAL DEPOSIT',
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
};

export const FIELD_LABELS = {
  SEARCH_USER: 'searchUser',
};
