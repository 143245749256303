import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const remarkSchemaForAccountDelete = Yup.object().shape({
  remark: Yup.string()
    .matches(REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS, 'Invalid reason')
    .min(20, 'Too Short!')
    .max(100, 'Too Long!')
    .required('Field cannot be empty')
    .label('remark'),
});
