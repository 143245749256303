import { useTranslation } from 'react-i18next';
import { APP_LANGUAGE } from '../common/constants/AppConstants';

const useGetCountryListStructuredObject = () => {
  const { i18n } = useTranslation();

  return (countryList) => {
    return countryList.map((country) => ({
      id: country?.id,
      nameAr: country?.nameAr,
      nameEng: country?.nameEng,
      label:
        i18n?.language === APP_LANGUAGE.ARABIC
          ? country?.nameAr
          : country?.nameEng,
      value: country?.id,
      mobileCode: country?.mobilecCode,
      currency: country?.currency,
    }));
  };
};

export default useGetCountryListStructuredObject;
