import { InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';
import {
  selectLabelStyle,
  selectInputField,
  textInputFieldStyle,
} from './../../sections/auth/login/LoginForm.style';

import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './selectComponent.css';
const isFieldError = (formik, fieldName) => {
  const hasError = Boolean(formik.errors[fieldName]);
  const isTouched = Boolean(formik.touched[fieldName]);
  return isTouched && hasError;
};

export const SelectComponent = ({
  language,
  formik,
  fieldName,
  placeholder,
  labelName,
  options,
  selectedOption,
  setSelectedOption,
  entityName,
  required = true,
  valueKey,
  ...props
}) => {
  const isError = isFieldError(formik, fieldName);
  const { t } = useTranslation();

  return (
    <>
      <InputLabel
        htmlFor={fieldName}
        sx={selectLabelStyle(language)}
        required={required}
      >
        <span className={required ? 'asterisk-content' : ''}>{labelName}</span>
      </InputLabel>
      <div className="row gx-1" style={{ height: '40px' }}>
        <Select
          displayEmpty
          renderValue={
            formik.values[fieldName] !== ''
              ? undefined
              : () => <span className="placeholder-label">{placeholder}</span>
          }
          id={fieldName}
          sx={selectInputField(
            isFieldError(formik, fieldName) && formik.values[fieldName] == '',
            language
          )}
          // sx={selectInputField(isError, language)}

          MenuProps={{
            PaperProps: {
              className: 'custom-menu-paper-select',
            },
          }}
          input={<OutlinedInput error={isError} />}
          inputProps={{ sx: textInputFieldStyle }}
          height="40px"
          fullWidth
          label="Age"
          placeholder={placeholder}
          {...props}
          name={fieldName}
          error={isError}
        >
          {options.map((option) => (
            <MenuItem
              key={option.id}
              value={valueKey ? option[valueKey] : option.id}
            >
              {entityName ? option[entityName] : option.name}
            </MenuItem>
          ))}
        </Select>
        {isError && (
          <FormHelperText error={true}>
            {t(formik.errors[fieldName])}
          </FormHelperText>
        )}
      </div>
    </>
  );
};
