import React, { useContext, useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { InputLabel, TextField, Typography } from '@mui/material';

import AuthInput from '../../components/auth-input';
import { inputLabelStyle } from '../../components/auth-input/style';
import { getUsersForQuery } from '../../api/getUserForQuery';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { errorToaster, successToaster } from '../../utils/helperUtility';
import {
  acceptTransferSchema,
  manualDepositSchema,
  rejectTransferSchema,
} from '../../schema/bankTransferValidationSchema';
import { NA } from '../../common/constants/AppConstants';
import { bankTransferConstants } from './constants';
import { Colors } from '../../common/constants/Color';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .css-154lg22-MuiPaper-root-MuiDialog-paper': {
    overflow: 'visible',
    width: '500px',
  },
}));

const StyledOuterOptionContainer = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginBottom: '8px',
  cursor: 'pointer',
  alignItems: 'flex-start',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const StyledOptionRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [disabled, setDisabled] = useState(true);
  const [options, setOptions] = useState([]);
  const [searchedUserInfo, setSearchedUserInfo] = useState({});
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    description,
    transferId,
    actionType,
  } = props;

  const getSchema = () => {
    switch (actionType) {
      case bankTransferConstants.ACCEPT:
        return acceptTransferSchema;
      case bankTransferConstants.REJECT:
        return rejectTransferSchema;
      case bankTransferConstants.MANUAL_DEPOSIT:
        return manualDepositSchema;
      default:
        return null;
    }
  };

  const isRemarkRequired = getSchema();
  const formik = useFormik({
    initialValues: {
      bankReferenceNumber: '',
      amount: '',
      remark: '',
      searchUser: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: isRemarkRequired,
  });

  useEffect(() => {
    if (options.length === 0) {
      fetchUserOptions();
    }
  }, []);

  const fetchUserOptions = async () => {
    try {
      const { data } = await getUsersForQuery({
        query: '',
      });
      const structuredList = createListStructure({ data: data.data });
      setOptions(structuredList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDisabledCondition();
  }, [formik.values, isRemarkRequired]);

  const isFieldError = (field) => {
    return !!formik?.touched?.[field] && !!formik?.errors?.[field];
  };

  const handleSubmit = async () => {
    let payload;
    if (bankTransferConstants.ACCEPT === actionType) {
      payload = {
        data: {
          id: transferId,
          bankReferenceNumber: formik?.values?.bankReferenceNumber,
          amount: formik?.values?.amount,
        },
        action: bankTransferConstants.ACCEPT_STATUS,
      };
    } else if (bankTransferConstants.REJECT === actionType) {
      payload = {
        data: {
          id: transferId,
          remark: formik?.values?.remark ?? t(`${NA}`),
        },
        action: bankTransferConstants.REJECT_STATUS,
      };
    } else {
      payload = {
        data: {
          id: searchedUserInfo?.id,
          amount: formik?.values?.amount,
          remark: formik?.values?.remark,
        },
        action: bankTransferConstants.MANUAL_DEPOSIT,
      };
    }
    try {
      await handleConfirm(payload);
      successToaster(t('BANK_TRANSFER_STATUS_UPDATED'), language);
      handleClose();
    } catch (error) {
      errorToaster(t('SOME_ERROR_OCCOURED'), language);
      handleClose();
    }
    handleClose();
  };

  const getDisabledCondition = async () => {
    const validationSchema = getSchema();
    const res = await new Promise((resolve, reject) => {
      return validationSchema
        .validate(formik.values)
        .then(() => resolve(false))
        .catch((err) => {
          console.log('err', err);
          return resolve(err.errors.length > 0);
        });
    });
    setDisabled(res);
  };

  const createListStructure = ({ data }) => {
    const optionList = data.map((item) => {
      const fullName = [item?.firstName, item?.middleName, item?.lastName]
        .filter(Boolean)
        .join(' ');
      const objForCurrentItem = {
        id: item?.id,
        label: fullName,
        email: item?.email ?? '',
        phoneNumber: item?.phoneNo ?? '',
      };
      return objForCurrentItem;
    });
    return optionList;
  };

  const onInputChange = debounce(async (_, search_query) => {
    const { data } = await getUsersForQuery({
      query: search_query,
    });
    const structuredList = createListStructure({ data: data.data });
    setOptions(structuredList);
  }, 300);

  const renderAcceptLayout = () => {
    return (
      <div className="row mt-4">
        <AuthInput
          className="col-12"
          htmlFor="bankReferenceNumber"
          label={t('BANK_REFERENCE_NUMBER')}
          sx={textInputField(isFieldError('bankReferenceNumber'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
          placeholder={t('Enter bank reference number')}
          name="bankReferenceNumber"
          id="bankReferenceNumber"
          onChange={formik.handleChange}
          value={formik.values.bankReferenceNumber}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('bankReferenceNumber')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={
            isFieldError('bankReferenceNumber') &&
            t(formik.errors.bankReferenceNumber)
          }
          required
        />
        <AuthInput
          className="col-12"
          htmlFor="amount"
          label={t('AMOUNT')}
          sx={textInputField(isFieldError('amount'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
          placeholder={t('Enter amount')}
          name="amount"
          id="amount"
          onChange={formik.handleChange}
          value={formik.values.amount}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('amount')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={isFieldError('amount') && t(formik.errors.amount)}
          required
        />
      </div>
    );
  };

  const renderRejectLayout = () => {
    return (
      <div className="row mt-4">
        <AuthInput
          className="col-12"
          htmlFor="remark"
          label={t('REMARK')}
          required
          sx={textInputField(isFieldError('remark'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
          placeholder={t('REMARK')}
          name="remark"
          id="remark"
          onChange={formik.handleChange}
          value={formik.values.remark}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('remark')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={isFieldError('remark') && t(formik.errors.remark)}
        />
      </div>
    );
  };

  const renderManualDepositLayout = () => {
    return (
      <div className="row mt-4">
        <InputLabel sx={inputLabelStyle(language)} required>
          <span>{t('USER')}</span>
        </InputLabel>
        <Autocomplete
          size="small"
          options={options}
          sx={{ width: 500, marginBottom: 2 }}
          getOptionLabel={(option) => {
            return option.label || '';
          }}
          onChange={(event, newValue) => {
            setSearchedUserInfo(newValue);
          }}
          renderOption={(props, option) => {
            return (
              <StyledOuterOptionContainer {...props}>
                <StyledOptionRow>
                  <Typography component="h6">{option?.label}</Typography>
                  <Typography component="h6" sx={{ color: 'silver' }}>
                    {option?.phoneNumber}
                  </Typography>
                </StyledOptionRow>
                <StyledOptionRow>
                  <Typography component="h6">{option?.email}</Typography>
                </StyledOptionRow>
              </StyledOuterOptionContainer>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                value={formik.values.searchUser}
                placeholder={t('SEARCH_USERS')}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: '13px',
                    height: '40px',
                  },
                }}
              />
            );
          }}
        />
        <AuthInput
          className="col-12"
          htmlFor="amount"
          label={t('AMOUNT')}
          sx={textInputField(isFieldError('amount'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
          placeholder={t('Enter amount')}
          name="amount"
          id="amount"
          onChange={formik.handleChange}
          value={formik.values.amount}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('amount')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={isFieldError('amount') && t(formik.errors.amount)}
        />
        <AuthInput
          className="col-12"
          htmlFor="remark"
          label={t('REMARK')}
          sx={textInputField(isFieldError('remark'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
          placeholder={t('REMARK')}
          name="remark"
          id="remark"
          onChange={formik.handleChange}
          value={formik.values.remark}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('remark')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={isFieldError('remark') && t(formik.errors.remark)}
        />
      </div>
    );
  };

  const renderLayoutAsPerAction = (action) => {
    switch (action) {
      case bankTransferConstants.ACCEPT:
        return renderAcceptLayout();
      case bankTransferConstants.REJECT:
        return renderRejectLayout();
      case bankTransferConstants.MANUAL_DEPOSIT:
        return renderManualDepositLayout();
      default:
        return renderManualDepositLayout();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {renderLayoutAsPerAction(actionType)}
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={() => {
            formik.resetForm();
            handleClose();
          }}
        >
          {t('Cancel')}
        </CancelButton>
        <SubmitButton
          onClick={() => {
            handleSubmit();
          }}
          disabled={disabled}
          autoFocus
        >
          {t('Confirm')}
        </SubmitButton>
      </DialogActions>
    </StyledDialog>
  );
}
