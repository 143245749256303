import React, { useContext, useState, useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { TextField, Typography } from '@mui/material';

import AuthInput from '../../components/auth-input';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { errorToaster, successToaster } from '../../utils/helperUtility';
import { withdrawalConstants } from './constants';
import { Colors } from '../../common/constants/Color';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import {
  acceptRequestSchema,
  rejectRequestSchema,
} from '../../schema/withdrawalValidationSchema';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .css-154lg22-MuiPaper-root-MuiDialog-paper': {
    overflow: 'visible',
    width: '500px',
  },
}));

const StyledOuterOptionContainer = styled('li')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px',
  marginBottom: '8px',
  cursor: 'pointer',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const StyledOptionRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export default function AlertDialog(props) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [options, setOptions] = useState(null);
  const [searchedUserInfo, setSearchedUserInfo] = useState({});
  const [inputPromptForSearchedUser, setInputPromptForSearchedUser] =
    useState('');
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    description,
    withdrawalId,
    actionType,
  } = props;

  const getSchema = () => {
    switch (actionType) {
      case withdrawalConstants.ACCEPT:
        return acceptRequestSchema;
      case withdrawalConstants.REJECT:
        return rejectRequestSchema;
      default:
        return null;
    }
  };

  const isRemarkRequired = getSchema();

  const formik = useFormik({
    initialValues: {
      bankReferenceNumber: '',
      amount: '',
      remark: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: isRemarkRequired,
  });

  useEffect(() => {
    getDisabledCondition();
  }, [formik.values, isRemarkRequired]);

  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const { language } = useContext(LanguageContext);

  const handleSubmit = async () => {
    let payload;
    if (withdrawalConstants.ACCEPT === actionType) {
      payload = {
        data: {
          id: withdrawalId,
          bankReferenceNumber: formik?.values?.bankReferenceNumber,
        },
        action: withdrawalConstants.ACCEPT_STATUS,
      };
    } else {
      payload = {
        data: {
          id: withdrawalId,
          remark: formik?.values?.remark || 'N/A',
        },
        action: withdrawalConstants.REJECT_STATUS,
      };
    }
    try {
      await handleConfirm(payload);
      successToaster(t('WITHDRAWAL_REQUEST_STATUS_UPDATED_SUCESS'), language);
      handleClose();
    } catch (error) {
      errorToaster(t('SOME_ERROR_OCCURED'), language);
      handleClose();
    }
  };

  const getDisabledCondition = async () => {
    const validationSchema = getSchema();
    const res = await new Promise((resolve, reject) => {
      return validationSchema
        .validate(formik.values)
        .then(() => resolve(false))
        .catch((err) => {
          console.log('err', err);
          return resolve(err.errors.length > 0);
        });
    });
    setDisabled(res);
  };

  const renderAcceptLayout = () => {
    return (
      <div className="row mt-4">
        <AuthInput
          className="col-12"
          htmlFor="bankReferenceNumber"
          label={t('BANK_REFERENCE_NUMBER')}
          required
          sx={textInputField(isFieldError('bankReferenceNumber'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
          placeholder={t('Enter bank reference number')}
          name="bankReferenceNumber"
          id="bankReferenceNumber"
          onChange={formik.handleChange}
          value={formik.values.bankReferenceNumber}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('bankReferenceNumber')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={
            isFieldError('bankReferenceNumber') &&
            t(formik.errors.bankReferenceNumber)
          }
        />
      </div>
    );
  };

  const renderRejectLayout = () => {
    return (
      <div className="row mt-4">
        <AuthInput
          className="col-12"
          htmlFor="remark"
          label={t('REMARK')}
          required
          sx={textInputField(isFieldError('remark'), language)}
          height="40px"
          inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
          placeholder={t('REMARK')}
          name="remark"
          id="remark"
          onChange={formik.handleChange}
          value={formik.values.remark}
          onBlur={formik.handleBlur}
          textFieldError={isFieldError('remark')}
          FormHelperTextProps={{
            sx: formHelperStyle,
          }}
          helperText={isFieldError('remark') && t(formik.errors.remark)}
        />
      </div>
    );
  };

  const renderLayoutAsPerAction = (action) => {
    switch (action) {
      case withdrawalConstants.ACCEPT:
        return renderAcceptLayout();
      case withdrawalConstants.REJECT:
        return renderRejectLayout();
      default:
        return renderRejectLayout();
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {renderLayoutAsPerAction(actionType)}
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={() => {
            formik.resetForm();
            handleClose();
          }}
        >
          {t('Cancel')}
        </CancelButton>
        <SubmitButton
          onClick={() => {
            handleSubmit();
          }}
          disabled={disabled}
          autoFocus
        >
          {t('Confirm')}
        </SubmitButton>
      </DialogActions>
    </StyledDialog>
  );
}
