import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './translations/en.json';
import {
  APP_LANGUAGE,
  SESSION_LANGUAGE_KEY,
} from './../common/constants/AppConstants';
import arTranslation from './translations/ar.json';

i18n
  .use(initReactI18next) // Pass i18n instance to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    fallbackLng: localStorage.getItem(SESSION_LANGUAGE_KEY)
      ? localStorage.getItem(SESSION_LANGUAGE_KEY)
      : APP_LANGUAGE.ENGLISH, // Use 'en' if detected lng is not available
    debug: false, // Set to true if you want to see i18n logs
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
