import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';

export const addNewGiftSettings = Yup.object().shape({
  fk_countries_id: Yup.string()
    .label('fk_countries_id')
    .required('Field cannot be empty'),

  giftFeeValue: Yup.string()
    .test('check-negatives', 'Value must be greater or equal to zero!', (value) => {
      if (REGEX_PATTERN.NEGATIVES_AND_NUMBER_WITH_DECIMAL.test(value)) {
        return Number(value) >= 0;
      } else return true;
    })
    .test(
      'check-number',
      'Alphabet and special characters like %,*,#,! are not allowed.',
      (value) => REGEX_PATTERN.NUMBER_WITH_DECIMAL.test(value)
    )
    .required('Field can not be empty!')
    .label('giftFeeValue'),

  giftFeeUnit: Yup.string()
    .required('Field can not be empty!')
    .label('giftFeeUnit'),

  metalType: Yup.string()
    .required('Field can not be empty!')
    .label('metalType'),

  status: Yup.string().required('Field can not be empty!').label('status'),
});
