import { Button } from '@mui/material';

export function InputSaveButton({
  children,
  cancelButton,
  onClick,
  display,
  ...props
}) {
  return (
    <Button
      variant="contained"
      disableRipple
      onClick={onClick}
      {...props}
      sx={{
        width: '138px',
        display: !display ? '' : 'none',
        height: '100%',
        marginLeft: '28px',
        borderRadius: '8px',
        fontFamily: 'Lato',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '17px',
        letterSpacing: 0,
        textAlign: 'center',
        backgroundColor: cancelButton ? '#F4F3F3' : '#E3CA7E',
        color: cancelButton ? '#777673' : '#000000',
        boxShadow: 'none',
        '&.MuiButtonBase-root:hover': {
          backgroundColor: cancelButton ? '#F4F3F3' : '#E3CA7E',
          color: cancelButton ? '#777673' : '#000000',
        },
      }}
    >
      {children}
    </Button>
  );
}
