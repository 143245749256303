// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';
// hooks
import { useTranslation } from 'react-i18next';
// sections
import { LoginForm } from '../sections/auth/login';
import { style, styledBox } from './LoginPage.style';
import AuthSideBar from '../components/auth-sidebar/AuthSideBar';

// components
import LanguageSwitcher from '../components/language-switcher/LanguageSwitcher';
import { useContext } from 'react';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledContent = styled('div')(({ theme }) => styledBox);

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);

  return (
    <>
      <StyledRoot>
        <AuthSideBar />
        <Container maxWidth="sm">
          <StyledContent>
            <LanguageSwitcher />
            <Typography color={'#212121'} sx={style.loginFormTitle(language)}>
              {t('LOGIN')}
            </Typography>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
