import { Box } from '@mui/material';
import { Header } from './Header';
import { heading } from './AddUser.style';
import { AddUserForm } from './AddUserForm';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from './../../constants/endpoints';
import { useTranslation } from 'react-i18next';

export const AddUser = ({ viewOnly }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box marginX="8px" marginY={'-26px'}>
      <Header
        headingStyle={heading}
        onClick={() => navigate(`${ENDPOINTS.USER_MANAGEMENT}`)}
      >
        {t('USER_DETAIL')}
      </Header>
      <AddUserForm
        width="100%"
        minHeight="480px"
        viewOnly={viewOnly}
      ></AddUserForm>
    </Box>
  );
};
