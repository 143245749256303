import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Typography,
  Box,
} from '@mui/material';
import Iconify from '../iconify/Iconify';

import { style } from './style';
import './style.scss';
import { useTranslation } from 'react-i18next';
import Pagination from '../pagination';

const CommonTable = ({
  resourceName,
  columns,
  data = [],
  isCheckBox,
  loading,
  emptyTableText,
  showPagination = false,
  pageCount,
  currentPage,
  handlePageChange,
  totalList,
  itemsPerPage,
}) => {
  const { t } = useTranslation();
  const [selectedResources, setSelectedResources] = React.useState([]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const CircleUnchecked = () => (
    <Iconify icon="system-uicons:checkbox-empty" width={16} color="action" />
  );

  const CircleChecked = () => (
    <Iconify
      icon="fluent:checkbox-checked-20-filled"
      width={16}
      color="primary"
    />
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelectedResources(newSelecteds);
      return;
    }
    setSelectedResources([]);
  };

  const handleClick = (event, id) => {
    if (event.target.type === 'checkbox') {
      event.stopPropagation();
    }

    const selectedIndex = selectedResources.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedResources, id);
    } else if (selectedIndex >= 0) {
      newSelected = newSelected.concat(
        selectedResources.slice(0, selectedIndex),
        selectedResources.slice(selectedIndex + 1)
      );
    }

    setSelectedResources(newSelected);
  };

  const isSelected = (id) => selectedResources.indexOf(id) !== -1;

  return (
    <>
      <TableContainer className="table-container">
        <Table aria-label={resourceName.plural.toLocaleLowerCase()}>
          <TableHead sx={style.tableHeadSx}>
            <TableRow sx={style.tableHeadRowSx}>
              {isCheckBox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    icon={<CircleUnchecked />}
                    checkedIcon={<CircleChecked />}
                    sx={style.checkboxSx}
                    checked={
                      data?.length > 0 &&
                      selectedResources.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
              )}
              {columns.map(
                ({ title, key, align, sortButton, renderSortButton }) => (
                  <TableCell
                    key={key}
                    align={align}
                    style={{
                      ...style.titleSx,
                      paddingTop: 1,
                      paddingBottom: 1,
                    }}
                  >
                    {title}
                    {sortButton && renderSortButton && renderSortButton(key)}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={columns.length + (isCheckBox ? 1 : 0)}
                  sx={{
                    ...style.textSx,
                    ...style.nodataFoundText,
                  }}
                >
                  {t('Loading...')}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : data?.length > 0 ? (
            <TableBody>
              {data?.map((rowData, index) => {
                const isItemSelected = isSelected(rowData.id);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, rowData.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={rowData.id}
                    selected={isItemSelected}
                    sx={style.tableBodyRowSx}
                  >
                    {isCheckBox && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          icon={<CircleUnchecked />}
                          checkedIcon={<CircleChecked />}
                          sx={style.checkboxSx}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, rowData.id)}
                        />
                      </TableCell>
                    )}
                    {columns.map(
                      ({ key, isComponent, align, requiredStyle }) => (
                        <TableCell
                          key={key}
                          align={align}
                          style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            ...requiredStyle,
                          }}
                          sx={style.tableCellContentSx}
                        >
                          {isComponent ? (
                            rowData[key]()
                          ) : align === 'end' ? (
                            <Typography
                              variant="body2"
                              display="block"
                              fontWeight="fontWeightBold"
                              sx={style.rowDataSx}
                            >
                              {rowData[key]}
                            </Typography>
                          ) : (
                            <Box sx={style.rowDataSx}>{rowData[key]}</Box>
                          )}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            emptyTableText && (
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{ ...style.textSx, ...style.nodataFoundText }}
                    colSpan={columns.length + (isCheckBox ? 1 : 0)}
                  >
                    <b>{emptyTableText}</b>
                  </TableCell>
                </TableRow>
              </TableBody>
            )
          )}
        </Table>
      </TableContainer>
      {showPagination && (
        <Box
          display="flex"
          justifyContent={'flex-end'}
          sx={style.paginationContainerSx}
        >
          <Typography sx={style.pagiShowingSx}>
            {t('PG_SHOWING')}
            <Typography component={'span'} sx={style.pagiNumSx}>
              {Math.min(startIndex + 1, totalList)}-
              {Math.min(endIndex, totalList)}
            </Typography>
            {t('PG_FROM')}
            <Typography component={'span'} sx={style.pagiNumSx}>
              {totalList}
            </Typography>
            {t('PG_RESULTS')}
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      )}
    </>
  );
};

export default CommonTable;
