export default function Formlabel(theme) {
  return {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#C24622',
          fontFamily: 'Montserrat',
        },
      },
    },
  };
}
