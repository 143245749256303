import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const getRoleList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.USER_ROLE_LIST,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const createRole = (data) => {
  return apiRequest({
    endpoint: API_ENDPOINTS.ADD_ROLE,
    method: METHODS.POST,
    headers: {},
    data: data,
    privateURL: true,
  });
};
export const editRole = (roleId, data) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.ADD_ROLE}/${roleId}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
export const getRoleDetails = (roleId) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.GET_ROLE}/${roleId}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });
};

export const deleteRole = (roleId, data) => {
  return apiRequest({
    endpoint: `${API_ENDPOINTS.DELETE_ROLE}/${roleId}`,
    method: METHODS.PUT,
    headers: {},
    data: data,
    privateURL: true,
  });
};
