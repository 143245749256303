import React, { useContext, useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Colors } from '../../common/constants/Color';
import Iconify from '../../components/iconify';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { bulkUpload } from '../../api/add-edit-product';
import { errorToaster, successToaster } from '../../utils/helperUtility';
import { BULK_UPLOAD_CONSTANT } from './BulUploadConstant';
const XLSX = require('xlsx');
const ExcelJS = require('exceljs');

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function BulkUpload(props) {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showUploadStatus, setShowUploadStatus] = useState(false);
  const [resultFileDataToDownload, setResultFileDataToDownload] = useState('');
  const { open, handleClose, handleAccept, handleReject, title, productData } =
    props;
  const uploadRef = useRef(null);

  useEffect(() => {});

  const { language } = useContext(LanguageContext);

  const downloadSheet = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(BULK_UPLOAD_CONSTANT.FILE_NAME);

    worksheet.columns = [
      { header: 'SKU', key: 'sku', width: 20 },
      { header: 'Quantity', key: 'quantity', width: 10 },
    ];

    productData.forEach((item) => {
      worksheet.addRow({
        sku: item.sku,
        quantity: item.quantity,
      });
    });

    worksheet.getColumn('sku').eachCell((cell) => {
      cell.protection = {
        locked: true,
      };
    });

    worksheet.getColumn('quantity').eachCell((cell) => {
      cell.protection = { locked: false };
    });

    worksheet.protect();

    const buffer = await workbook.xlsx.writeBuffer();

    const blob = new Blob([buffer], {
      type: BULK_UPLOAD_CONSTANT.EXCEL_TYPE,
    });

    const downloadUrl = URL.createObjectURL(blob);

    const downloadLink = document.createElement('a');
    downloadLink.href = downloadUrl;
    downloadLink.download = BULK_UPLOAD_CONSTANT.FILE_NAME_WITH_EXTENSION;
    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(downloadUrl);
  };

  const handleFileChange = (event) => {
    setShowUploadStatus(false);
    setResultFileDataToDownload('');
    const file = event.target.files[0];
    let validFiletype = true;
    if (!(file.type === BULK_UPLOAD_CONSTANT.EXCEL_TYPE)) {
      validFiletype = false;
    }
    const fileNameExtension = file.name.slice(-5).toLowerCase();
    if (fileNameExtension !== '.xlsx') {
      validFiletype = false;
    }

    if (!validFiletype) {
      errorToaster(t('XLSX_FILE_ALLOWED_MSG'), language);
      setShowUploadStatus(false);
      setResultFileDataToDownload('');
      if (uploadRef.current) {
        uploadRef.current.value = '';
      }
      return;
    }
    uploadBulkUpdateFile(event);
  };
  const uploadBulkUpdateFile = async (event) => {
    const file = event.target.files[0];
    setResultFileDataToDownload('');
    try {
      setLoading(true);
      const response = await bulkUpload(file);
      setShowUploadStatus(false);
      if (response.data.type !== BULK_UPLOAD_CONSTANT.JSON_TYPE) {
        setShowUploadStatus(true);
        setResultFileDataToDownload(response.data);
        if (uploadRef.current) {
          uploadRef.current.value = '';
        }
      } else {
        setShowUploadStatus(false);
        setResultFileDataToDownload('');
        successToaster(t(`BULK_UPDATE_SUCCESS`));
        handleClose();
      }
      setLoading(false);
    } catch (err) {
      setShowUploadStatus(false);
      setResultFileDataToDownload('');
      setLoading(false);
      event.target.value = '';
      const error = JSON.parse(await err.data.text());
      errorToaster(t(error.message || 'SOME_ERROR_OCCOURED'), language);
      if (uploadRef.current) {
        uploadRef.current.value = '';
      }
    }
  };

  const downloadXLSX = () => {
    const blobURL = window.URL.createObjectURL(resultFileDataToDownload);
    const downloadLink = document.createElement('a');
    downloadLink.href = blobURL;
    downloadLink.download = BULK_UPLOAD_CONSTANT.FILE_NAME_WITH_EXTENSION;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    window.URL.revokeObjectURL(blobURL);
    downloadLink.remove();
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ style: { width: '500px', minHeight: '220px' } }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText> */}
        <div className="bulk-upload-center">
          {loading && <CircularProgress color="warning" className="loader" />}
        </div>

        <div className="download-line">{t('BULK_UPLOAD_DECRIPTION')}</div>
        {showUploadStatus && (
          <div className="download-line mt-4">
            <span>{t('ERROR_SHEET_MSG_PREFIX')}</span>
            <a href="#" onClick={() => downloadXLSX()}>
              {t('CLICK')}
            </a>
            <span>{t('ERROR_SHEET_MSG_SUFFIX')}</span>
          </div>
        )}

        <div className="row mt-4"></div>
      </DialogContent>
      <DialogActions>
        <div className="button-container">
          <SubmitButton
            onClick={() => downloadSheet()}
            autoFocus
            startIcon={
              <Iconify width={'18px'} icon="material-symbols:download" />
            }
          >
            {t('download sample')}
          </SubmitButton>
          <SubmitButton
            component="label"
            variant="contained"
            tabIndex={-1}
            startIcon={
              <Iconify width={'18px'} icon="material-symbols:upload" />
            }
            disabled={loading}
          >
            {t('upload sheet')}
            <VisuallyHiddenInput
              type="file"
              accept=".xlsx"
              ref={uploadRef}
              onChange={handleFileChange}
            />
          </SubmitButton>
        </div>
      </DialogActions>
    </Dialog>
  );
}
