const API = {
  REACT_APP_BASE_URL: 'https://api-uat.ogold.app/api',
  REACT_APP_PAYMENT_BASE_URL: 'https://api-payment-uat.ogold.app/',
  REACT_APP_CACHE_ENABLED: true,
  GOOGLE_MAPS_API_KEY: 'AIzaSyCYC-HbXiBK9tRzmIle1mYcAwOc3w8i3j0',
};

module.exports = {
  API,
};
