import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Colors } from '../../common/constants/Color';
import AuthInput from '../../components/auth-input';

import { useFormik } from 'formik';
import { remarkSchemaForAccountDelete } from '../../schema/deleteAccountSchema';
import { AccountDeleteConstant } from './AccountDeleteConstant';

import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

export default function AccountDeleteConfirmation(props) {
  const { t } = useTranslation();
  const {
    open,
    handleClose,
    handleAccept,
    handleReject,
    title,
    description,
    actionType,
  } = props;

  const formik = useFormik({
    initialValues: { remark: '' },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: remarkSchemaForAccountDelete,
    onSubmit: async (values) => {
      if (handleAccept) {
        handleClose();
        handleAccept({
          data: formik.values.remark,
          action: AccountDeleteConstant.REJECT_STATUS,
        });
      }
    },
  });

  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const { language } = useContext(LanguageContext);

  const handleSubmit = (event) => {
    if (AccountDeleteConstant.ACCEPT === actionType) {
      if (handleAccept) {
        handleClose();
        handleAccept({
          data: formik.values.remark || '',
          action: AccountDeleteConstant.ACCEPT_STATUS,
        });
      }
    } else {
      formik.submitForm(event);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { width: '40%', minHeight: '200px' },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>

        {actionType === AccountDeleteConstant.REJECT && (
          <div className="row mt-4">
            <AuthInput
              className="col-12"
              htmlFor="remark"
              label={t('REASON')}
              required={true}
              sx={textInputField(isFieldError('remark'), language)}
              height="40px"
              inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
              placeholder={t('REASON')}
              name="remark"
              id="remark"
              onChange={formik.handleChange}
              value={formik.values.remark}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('remark')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('remark') && t(formik.errors.remark)}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <SubmitButton
          onClick={() => {
            handleSubmit();
          }}
          autoFocus
          disabled={
            actionType === AccountDeleteConstant.REJECT &&
            !(formik.isValid && formik.dirty)
          }
        >
          {t('Confirm')}
        </SubmitButton>
      </DialogActions>
    </Dialog>
  );
}
