import React, { useEffect, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import './orderStyle.css';
import UpdateOrderStatus from './update-status';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';

import { getOrderDetails, updateorderStatus } from '../../api/order-details';
import {
  successToaster,
  errorToaster,
  getFormattedDate,
} from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { Colors } from '../../common/constants/Color';
import OutlinedCard from '../../components/product-card/productCard';
import { getModulePerm } from '../../utils/helperUtility';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';
import { OrderConstant } from './orderConstant';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { orderStatusConstant } from './orderConstant';
import { getStatusClassName } from '../../utils/helperUtility';
const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    // maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
    marginTop: '40px',
  };
});

const OrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [PageTitle, setPageTitle] = useState('ORDER_DETAILS');
  const [orderData, setOrderData] = useState();
  const { language } = useContext(LanguageContext);

  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  const orderId = () => {
    return query.get('id');
  };

  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
  };

  const returnStatus =
    orderData &&
    orderData.status !== OrderConstant.REJECTED &&
    orderData.status !== OrderConstant.DELIVERED;
  const isShipStatus =
    orderData && orderData.deliveryMode === OrderConstant.SHIP;

  const handleAccept = async ({ data, action, isShipped }) => {
    try {
      setLoading(false);

      const body = {};
      if (isShipped) {
        body.remarks = data.remark || '';
        body.logisticPartnerName = data.partnerName;
        body.trackingId = data.trackingId;
        body.status = data.status;
      } else if (action !== OrderConstant.SHIPPED) {
        body.remarks = data.remark || '';
        body.status =
          action === OrderConstant.CONFIRMED ||
          action === OrderConstant.REJECTED
            ? action
            : data.status;
      }

      const response = await updateorderStatus(body, orderId());
      if (response.data.isSuccess) {
        const msg = returntoastMsg(action);
        successToaster(t(msg), language);
        navigate(ENDPOINTS.ORDER_MANAGEMENT);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };

  const returntoastMsg = (action) => {
    if (action === OrderConstant.CONFIRMED) return 'ACEEPT_MSG';
    if (action === OrderConstant.REJECTED) return 'REJECT_MSG';
    else return 'STATUS_UPDATE_SUCCESS';
  };
  const handleReject = () => {
    // setActionType('');
  };

  const returnCustomerName = () => {
    if (!orderData?.customerDetails) return '';
    const { firstName, middleName, lastName } = orderData?.customerDetails;
    return `${firstName || ''} ${middleName || ''} ${lastName || ''}`;
  };

  const returnCustomerAddress = () => {
    if (!orderData?.customerDetails) return '';

    const { address, city, locality, zipCode } = orderData;
    const addressParts = [address, locality, city].filter(Boolean); // This will remove any falsy values (empty strings, null, undefined)

    return addressParts.join(', ');
  };

  const returnCustomerZipCode = () => {
    if (!orderData?.customerDetails) return '';

    const { zipCode } = orderData;
    return zipCode || 'NA';
  };

  const returnCustomerEmail = () => {
    if (!orderData?.customerDetails) return '';
    const { email } = orderData?.customerDetails;
    return `${email || ''}`;
  };
  const returnCustomerPhone = () => {
    if (!orderData?.customerDetails) return '';
    const { phoneNo, mobileCode } = orderData?.customerDetails;
    if (mobileCode) {
      return `(+${mobileCode}) ${phoneNo || ''}`;
    }
    return `${phoneNo || ''}`;
  };

  const returnWareHouseName = () => {
    if (!orderData?.warehouseDetails) return '';
    const { name } = orderData?.warehouseDetails;
    return `${name || ''}`;
  };

  const returnWareHouseAddress = () => {
    if (!orderData?.warehouseDetails) return '';

    const { addressLine1, addressLine2, city, countryEng } =
      orderData.warehouseDetails;
    const addressParts = [addressLine1, addressLine2, city, countryEng].filter(
      Boolean
    ); // This will remove any falsy values (empty strings, null, undefined)

    return addressParts.join(', ');
  };

  const returnWareHouseZipCode = () => {
    if (!orderData?.warehouseDetails) return '';

    const { zipCode } = orderData.warehouseDetails;

    return zipCode || 'NA';
  };
  const getCurrentStatus =
    orderStatusConstant[orderData && orderData.status] || '';

  const getOrderNumber = (orderData && orderData.orderNo) || 'NA';
  const currentModulePerm = getModulePerm(MODULE_CONST.ORDER_MANAGEMENT);
  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;
  const getDataForOrder = async () => {
    try {
      const id = orderId();
      setLoading(true);
      const response = await getOrderDetails(id);
      const data = response.data.data;
      // const name = `${data.productDetails[0].name}-${data.productDetails[0].id}`;
      setOrderData(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    getDataForOrder();
  }, []);

  const getAddressType = () => {
    const title = isShipStatus
      ? t('Drop addresses')
      : t('Order Warehouse/Store Address');
    return title;
    // <span className="address-title ">{`(${title})`}</span>;
  };

  const getDeliveredDate = () => {
    if (orderData && Array.isArray(orderData.statusDetails)) {
      const deliveredDate = orderData.statusDetails.filter(
        (item) => item.status === OrderConstant.DELIVERED
      );
      if (deliveredDate.length)
        return getFormattedDate(deliveredDate[0]?.time, 'DD/MM/YYYY HH:mm');
      else return 'NA';
    } else {
      return 'NA';
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => navigate(ENDPOINTS.ORDER_MANAGEMENT)}
          >
            <img src={LeftChevronIcon} />
          </div>
          <FormTitle>
            {t('ORDER_ID')}-<span className="">{getOrderNumber}</span>
          </FormTitle>
        </div>
      </div>
      <FormContainer>
        <div className="row customer-details">
          <div
            className="col-6 customer-details"
            dir={isArabic ? 'rtl' : 'ltr'}
          >
            {t('CURRENT_STATUS')} -{' '}
            {getCurrentStatus && (
              <span
                className={`status-val ${getStatusClassName(getCurrentStatus)}`}
              >
                {getCurrentStatus}
              </span>
            )}
          </div>
          <div className="status-btn d-flex justify-content-end">
            {returnStatus && currentModulePerm.writeMode && (
              <SubmitButton onClick={() => setOpen(true)}>
                {t('UPDATE_STATUS')}
              </SubmitButton>
            )}
          </div>
        </div>

        <div className="row customer-details" dir={isArabic ? 'rtl' : 'ltr'}>
          <div className="customer-details">
            {t('Order Type')} -{' '}
            <span className="status-val">{orderData?.deliveryMode}</span>
          </div>
        </div>

        <div className="row customer-details" dir={isArabic ? 'rtl' : 'ltr'}>
          <div className="customer-details">
            {t('REQUESTED_DATE')} -{' '}
            <span className="status-val">
              {getFormattedDate(orderData?.createdAt, 'DD/MM/YYYY HH:mm') ||
                'NA'}
            </span>
          </div>
        </div>
        {orderData && orderData.status === OrderConstant.DELIVERED && (
          <div className="row customer-details" dir={isArabic ? 'rtl' : 'ltr'}>
            <div className="customer-details">
              {t('DELIVERY_DATE')} -{' '}
              <span className="status-val">{getDeliveredDate()}</span>
            </div>
          </div>
        )}

        {orderData && orderData.status === OrderConstant.REJECTED && (
          <div className="row customer-details" dir={isArabic ? 'rtl' : 'ltr'}>
            <div className="customer-details">
              {t('REMARK')} -{' '}
              <span className="status-val">{orderData.remarks || 'NA'}</span>
            </div>
          </div>
        )}

        {/*  */}

        <div className="row mt-2">
          <div className="col-6" dir={isArabic ? 'rtl' : 'ltr'}>
            <div className="row address-heading">
              <div className="address-heading">{t('CUSTOMER_DETAILS')}</div>
            </div>
          </div>

          <div className="col-6" dir={isArabic ? 'rtl' : 'ltr'}>
            <div className="row address-heading">
              <div className="address-heading">
                {t('Warehouse/Delivery Address')}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 customer-card">
            <Card variant="outlined" sx={{ height: '220px' }}>
              <CardContent className="">
                <div className="row">
                  <div className="col-6">
                    <div className="tileHeading">{t('CUST_NAME')}</div>
                    <div className="name-title">{returnCustomerName()}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('CUST_PH')}</div>
                    <div className="tileValues">{returnCustomerPhone()}</div>
                  </div>

                  <div className="col-6">
                    <div className="tileHeading">{t('CUST_EMAIL')}</div>
                    <div className="tileValues">{returnCustomerEmail()}</div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>

          {isShipStatus && (
            <div className="col-12 col-md-6">
              <Card variant="outlined" sx={{ height: '220px' }}>
                <CardContent className="">
                  <div className="row">
                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_NAME')}</div>
                      <div className="name-title">{returnCustomerName()}</div>
                    </div>

                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_PH')}</div>
                      <div className="tileValues">{returnCustomerPhone()}</div>
                    </div>

                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_EMAIL')}</div>
                      <div className="tileValues">{returnCustomerEmail()}</div>
                    </div>

                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_ZIPCODE')}</div>
                      <div className="tileValues">
                        {returnCustomerZipCode()}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="tileHeading">{t('CUST_ADDRESS')}</div>
                      <div className="tileValues">
                        {returnCustomerAddress()}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}

          {!isShipStatus && (
            <div className="col-12 col-md-6">
              <Card variant="outlined" sx={{ height: '220px' }}>
                <CardContent className="">
                  <div className="row">
                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_NAME')}</div>
                      <div className="tileValues">{returnWareHouseName()}</div>
                    </div>

                    <div className="col-6">
                      <div className="tileHeading">{t('CUST_ZIPCODE')}</div>
                      <div className="tileValues">
                        {returnWareHouseZipCode()}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="tileHeading">{t('CUST_ADDRESS')}</div>
                      <div className="tileValues">
                        {returnWareHouseAddress()}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          )}
        </div>
        <div className="row mt-4 address-heading">
          <div className="address-heading">{t('PRODUCT_DETAILS')}</div>
        </div>
        <div className="row mt-2">
          {orderData &&
            orderData?.productDetails.map((item) => {
              return (
                <OutlinedCard
                  key={item.id}
                  className="col-12 col-md-6"
                  data={item}
                  currency={orderData.customerDetails.currency}
                />
              );
            })}
        </div>
      </FormContainer>
      {open && (
        <UpdateOrderStatus
          open={open}
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleReject={handleReject}
          title={t('UPDATE_STATUS')}
          description={''}
          orderData={orderData}
        />
      )}
    </div>
  );
};

export default OrderDetails;
