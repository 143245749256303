import { useTranslation } from 'react-i18next';
import useResponsive from '../../hooks/useResponsive';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import LogoIcon from '../../assets/icons/Logo.svg';
import { style, generateStyledBox, styledImage, styledMessage } from './style';

const StyledSection = styled('div')(({ theme }) => generateStyledBox(theme));
const ImageSection = styled('img')(() => styledImage);
const StyledMessage = styled('div')(() => styledMessage);
export default function AuthSideBar() {
  const { t } = useTranslation();
  const mdUp = useResponsive('up', 'md');
  return (
    <>
      {mdUp && (
        <StyledSection>
          <ImageSection src={LogoIcon} alt="login" />
          <StyledMessage>
            <Typography sx={style.welcomeText}>{t('WELCOME_TITLE')}</Typography>
            <Typography sx={style.welcomeDesText}>
              {t('WELCOME_DESCRIPTION')}
            </Typography>
          </StyledMessage>
        </StyledSection>
      )}
    </>
  );
}
