export const REGEX_PATTERN = {
  ALPHA_NUMERIC: /^[A-Za-z0-9]+( [A-Za-z0-9]+)*$/,
  ALPHABET_WITH_SPACE: /^[A-Za-z]+( [A-Za-z]+)*$/,
  ALPHA_NUMERIC_WITH_COMMA: /^[A-Za-z0-9,]+( [A-Za-z0-9,]+)*$/,
  ALPHABET_ONLY_WITH_SPACE: /^[a-zA-Z ]*$/,
  ALPHABET_ONLY: /^[a-zA-Z]*$/,
  ALPHA_NUMERIC_WITH_SPECIAL_CHARS:
    /^(?:[A-Za-z0-9]+(?:[ ,/.][A-Za-z0-9]+)*)?$/,
  ALPHA_NUMERIC_WITH_SPECIAL_CHARS_NO_SPACE:
    /^(?:[A-Za-z0-9]+(?:[-,.:\/][A-Za-z0-9]+)*)?$/,
  ALPHA_NUMERIC_WITH_NO_SPACE: /^[A-Za-z]+$/,
  PHONE_NUMBER:
    /(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/,
  EMAIL: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
  NUMBERONLY: /^[0-9]$/,
  NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL: /^[1-9]\d*$/,
  NUMBER_WITH_ZERO_WITHOUT_DECIMAL: /^(0|[1-9]\d*)$/,
  NUMBER_WITH_DECIMAL: /^\d*(\.\d+)?$/,
  NEGATIVES_AND_NUMBER_WITH_DECIMAL: /^-?\d+(\.\d+)?$/,
  NUMBER_GREATER_THAN_ZERO: /^(?!0+(\.0+)?$)\d+(\.\d+)?$/,
  NUMBER_BETWEEN_ZERO_HUNDRED_WITH_DECIMAL:
    /^(0(\.0{1,2})?|0\.\d{1,2}|[1-9](\d{0,2}(\.\d{1,2})?)?|100(\.0{1,2})?)$/,
  NUMBER_INCLUDING_ZERO_HUNDRED_WITH_DECIMAL:
    /^(100(?:\.0{1,2})?|\d{1,2}(?:\.\d{1,2})?)$/,
  NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL_HAVING_12_DIGITS:
    /^(?!0{12})\d{12}$/,
  NUMBER_GREATER_THAN_ZERO_WITH_DECIMAL_HAVING_ATMAX_10_DIGITS:
    /^(?!0+$)(\d{1,10}(\.\d{1,10})?)$/,
  ALL_WITH_SPACES: /^[^\s]+( [^\s]+)*$/,
  ALL_EXCLUDING_SPECIAL_CHARACTERS:
    /^[^!*@#%$^&+{}()[\]<>?=\/\\|;~\.",':\- ]+( [^!*@#%$^&+{}()[\]<>?=\/\\|;~\.",':\- ]+)*$/,
  ALL_EXCLUDING_NUMBERS_AND_SPECIAL_CHARACTERS:
    /^[^!@#%$^&+{}()[\]<>?=\/\\|;~\.",':\- ]+( [^!@#%$^&+{}()[\]<>?=\/\\|;~\.",':\- ]+)*$/,
  NUMBER_GREATER_THAN_ZERO_WITHOUT_DECIMAL_AND_NEGATIVE_SIGN_UPTO_TEN_PLACES:
    /^[-+]?[1-9][0-9]{0,9}$/,
  NUMBER_WITH_ZERO_WITH_DECIMAL_UPTO_TWO_PLACES_AND_DIGITS_UPTO_10_PLACES:
    /^(?!0(\.0{1,2})?$)\d{1,10}(\.\d{1,2})?$/,
  ALPHA_NUMERIC_ARABIC: /^[\u0600-\u06FF0-9]+( [\u0600-\u06FF0-9]+)*$/,

  NoSpecial_Chars_WithOptional_SpacesValidator:
    /^[^\s@#\$;,.%:;?{}&/][A-Za-z0-9\-\/.]*([A-Za-z0-9\-\/.]+[ ]?)*[A-Za-z0-9\-\/.]?[^\s@#\$;,?/%;:{}&]$/,
  ARABIC_NoSpecial_Chars_WithOptional_SpacesValidator:
    /^[^\s@#\$;,.%:;?{}&][A-Za-z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\-\/.]*((?:[A-Za-z\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\-\/.]+[ ]?)+)[^\s@#\$;,?/%;:{}&]$/,

  ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR:
    /^(?:[A-Za-z0-9]+(?:[ ,] |[ /.]| - |-(?! )| (?=-))?)*[A-Za-z0-9]+$/,

  ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR_WITH_DOT_IN_END:
    /^(?:[A-Za-z0-9]+(?:[ ,] |[ /.]| - |-(?! )| (?=-))?)*[A-Za-z0-9]+\.?$/,
  ARABIC_ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR:
    /^(?:[\u0600-\u06FFA-Za-z0-9]+(?:[ ,/.]| - |-(?! )| (?=-))?)*[\u0600-\u06FFA-Za-z0-9]+$/,
  ARABIC_ALPHA_NUMERIC_WITH_SOME_SPECIAL_CHAR_WITH_DOT_IN_END:
    /^(?:[\u0600-\u06FFA-Za-z0-9]+(?:[ ,/.]| - |-(?! )| (?=-))?)*[\u0600-\u06FFA-Za-z0-9]+\.?$/,
  REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE: /^(?!.*\s{2,}).*[^ ]$/,
  ARABIC_REGEX_TO_ALLOW_ALL_CHAR_NUMBER_WITHOUT_MULTIPLE_SPACE:
    /^(?!.*\s{2,})[\s\S]*[^\s]$/,
  ALPHA_NUMERIC_WITH_NO_SPACE_WITH_ARABIC: /^[\u0600-\u06FFA-Za-z]+$/u,
};
