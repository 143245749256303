import { useFormik } from 'formik';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { Colors } from '../../common/constants/Color';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import {
  successToaster,
  errorToaster,
  getModulePerm,
} from '../../utils/helperUtility';
import AuthInput from '../../components/auth-input';
import {
  addUpdateNewCity,
  getCityData,
  getCountryList,
} from '../../api/cityManagement';
import {
  formHelperStyle,
  textInputField,
  textInputFieldStyle,
} from '../../sections/auth/login/LoginForm.style';
import ENDPOINTS from '../../constants/endpoints';
import CountryWithFLag from '../../components/countryList/CountryList';
import LeftChevronIcon from '../..//assets/icons/left_chevron.svg';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { addNewCitySchema } from '../../schema/addNewCitySchema';
import useChangeLabelForCountryList from '../../hooks/useChangeLabelForCountryList';
import useGetCountryListStructuredObject from '../../hooks/useGetCountryListStructuredObject';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function AddNewCityForm() {
  const [countryList, setCountryList] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const getStructure = useGetCountryListStructuredObject();
  const changeLabelForCountryList =
    useChangeLabelForCountryList(setCountryList);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();
  const cityId = query.get('id');
  const [initialValues, setInitialValues] = useState({
    fk_countries_id: '',
    cityName: '',
    deliveryCharge: '',
  });

  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: addNewCitySchema,
    onSubmit: async (values) => {
      createPayload(values);
    },
  });

  useEffect(() => {
    fetchListOfCountry();
    if (!!cityId) {
      getPreviousSavedCityData();
    }
  }, []);

  useEffect(() => {
    changeLabelForCountryList();
  }, [i18n?.language]);

  const getPreviousSavedCityData = async () => {
    setLoading(true);
    try {
      const { data } = await getCityData({ id: cityId });
      const { name, fkCountriesId, deliveryCharge } = data.data;
      setInitialValues({
        fk_countries_id: fkCountriesId,
        cityName: name,
        deliveryCharge: deliveryCharge,
      });
    } catch (error) {
      console.error('Error fetching city details:', error);
    }
    setLoading(false);
  };

  const fetchListOfCountry = async () => {
    setLoading(true);
    try {
      const { data } = await getCountryList();
      const structuredCountryList = getStructure(data.data);
      setCountryList((prev) => [...prev, ...structuredCountryList]);
    } catch (error) {
      console.error('Error fetching country list:', error);
    }
    setLoading(false);
  };

  const createPayload = async (values) => {
    try {
      setLoading(true);
      let { cityName, deliveryCharge, fk_countries_id } = values;
      const data = cityId
        ? {
            payload: {
              name: cityName,
              delivery_charge: Number(deliveryCharge),
              fk_countries_id: fk_countries_id,
              is_active: true,
            },
            id: cityId,
          }
        : {
            payload: {
              name: cityName,
              delivery_charge: Number(deliveryCharge),
              fk_countries_id: fk_countries_id,
            },
          };
      const response = await addUpdateNewCity({
        ...{ data },
      });
      if (response.data.isSuccess) {
        successToaster(
          cityId ? t('CITY_UPDATED_SUCCESS') : t('NEW_CITY_ADDED_SUCCESS'),
          language
        );
        navigate(ENDPOINTS.CITY_MANAGEMENT);
      }
    } catch (err) {
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelButton = () => {
    navigate(ENDPOINTS.CITY_MANAGEMENT);
  };

  const handleSubmit = (event) => {
    formik.submitForm(event);
  };

  const getDisableCond = () => {
    return !(formik.isValid && formik.dirty);
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.CITY_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{!!cityId ? t('UPDATE_CITY') : t('ADD_NEW_CITY')}</FormTitle>
      </div>
      <FormContainer>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="row mb-4">
              <CountryWithFLag
                className="col-12 col-md-4"
                placeholderForCountry={t('SELECT_COUNTRY')}
                fieldName="fk_countries_id"
                multiSelect={false}
                labelName={t('Country')}
                options={countryList}
                selectedOptions={formik.values.fk_countries_id}
                formik={formik}
                htmlFor="fk_countries_id"
                label={''}
                sx={textInputField(isFieldError('fk_countries_id'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
                name="fk_countries_id"
                id="fk_countries_id"
                onChange={formik.handleChange}
                value={formik.values.fk_countries_id}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('fk_countries_id')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                required
                isUseLabel
                {...{ t }}
              />
            </div>
            <div className="row mb-2">
              <AuthInput
                className="col-12 col-md-4"
                htmlFor="city-name"
                label={t('City')}
                disabled={false}
                required={true}
                sx={textInputField(isFieldError('cityName'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 100 }}
                placeholder={t('Enter_City')}
                name="cityName"
                id="cityName"
                onChange={formik.handleChange}
                value={formik.values.cityName}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('cityName')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('cityName') && t(formik.errors.cityName)
                }
              />
            </div>
            <div className="row">
              <AuthInput
                className="col-12 col-md-4"
                htmlFor="delivery-charge"
                label={t('DELIVERY_CHARGE')}
                disabled={false}
                required={true}
                sx={textInputField(isFieldError('deliveryCharge'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
                placeholder={t('ENTER_DELIVERY_CHARGE')}
                name="deliveryCharge"
                id="deliveryCharge"
                onChange={formik.handleChange}
                value={formik.values.deliveryCharge}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('deliveryCharge')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('deliveryCharge') &&
                  t(formik.errors.deliveryCharge)
                }
              />
            </div>
            <FormButtons>
              <CancelButton onClick={() => navigate(ENDPOINTS.CITY_MANAGEMENT)}>
                {t('CANCEL')}
              </CancelButton>

              <SubmitButton
                type="submit"
                onClick={handleSubmit}
                disabled={getDisableCond()}
              >
                {!!cityId ? t('UPDATE_CITY') : t('ADD_CITY')}
              </SubmitButton>
            </FormButtons>
          </>
        )}
      </FormContainer>
    </div>
  );
}
