import { createContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import {
  BEFORE_LOGIN_URL,
  USER_DETAILS_KEY_NAME,
} from '../../common/constants/AppConstants';
import getAccessibleUrls from './getAccessibleUrls';
import { getRespectiveUrl } from '../../utils/helperUtility';

const hasPermission = (permissions, path) =>
  getAccessibleUrls(permissions).some(
    (permission) =>
      path.match(permission) ||
      (path.length &&
        path.charAt(path.length - 1) === '/' &&
        path.substring(0, path.length - 1).match(permission))
  );
  
export const User = createContext(null);

const isLoginEndpoint = (pathname) =>
  [
    ENDPOINTS.LOGIN,
    '/' + ENDPOINTS.LOGIN,
    ENDPOINTS.LOGIN + '/',
    '/' + ENDPOINTS.LOGIN + '/',
  ].includes(pathname);

export function AuthGuard({ children }) {
  const location = useLocation();
  const path = `${location.pathname}${location.search}`;
  const user = JSON.parse(localStorage.getItem(USER_DETAILS_KEY_NAME) || '{}');
  if (user == null || !Object.keys(user).length) {
    if (isLoginEndpoint(location.pathname)) return <>{children}</>;
    localStorage.setItem(BEFORE_LOGIN_URL, path);
    return <Navigate to={`/${ENDPOINTS.LOGIN}`} replace />;
  }
  if (isLoginEndpoint(location.pathname)) {
    return <Navigate to={ENDPOINTS.DASHBOARD} replace />;
  }

  const beforeLoginUrl = localStorage.getItem(BEFORE_LOGIN_URL);
  if (beforeLoginUrl) {
    localStorage.removeItem(BEFORE_LOGIN_URL);
    return <Navigate to={beforeLoginUrl} replace />;
  }
  if (user.systemDefinedRole || hasPermission(user.permissions, path))
    return <User.Provider value={user}>{children}</User.Provider>;

  if (path === '/dashboard/app'|| path==='/dashboard') {
    const respUrl = getRespectiveUrl();
    return <Navigate to={respUrl} />;
  }

  return <Navigate to={ENDPOINTS.Page404} replace />;
}
