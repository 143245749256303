// @mui
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container, Typography, Box, Button } from '@mui/material';

// hooks
import { useTranslation } from 'react-i18next';
// sections
import { style } from './LoginPage.style';
import Iconify from '../components/iconify/Iconify';
import { ForgetPasswordForm } from '../sections/auth/forget-password';
import AuthSideBar from '../components/auth-sidebar/AuthSideBar';
import useResponsive from '../hooks/useResponsive';
import { useContext } from 'react';
import { LanguageContext } from '../globalContext/LanguageSwitcher';
import ContentSideBox from '../components/content-sidebox/ContentSidebox';
import { styledContent, styledRoot } from './forgetPassword.style';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => styledRoot(theme));

const StyledContent = styled('div')(({ theme }) => styledContent(theme));

// ----------------------------------------------------------------------

export default function ForgetPassword() {
  const { t } = useTranslation();
  const mdUp = useResponsive('up', 'md');
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();

  return (
    <>
      <StyledRoot>
        <AuthSideBar />
        <ContentSideBox>
          <Container maxWidth={'sm'}>
            <Box position={'absolute'} left={'44px'} top={'44px'}>
              <Button
                startIcon={<Iconify icon={'eva:chevron-left-fill'} />}
                onClick={() => {
                  navigate(-1);
                }}
                sx={style.backButton}
              >
                {t('BACK_BTN_TITLE')}
              </Button>
            </Box>
            <Box>
              <StyledContent>
                <Typography
                  color={'#212121'}
                  sx={style.loginFormTitle(language)}
                >
                  {t('FORGET_PASSWORD_FORM_TITLE')}
                </Typography>
                <ForgetPasswordForm />
              </StyledContent>
            </Box>
          </Container>
        </ContentSideBox>
      </StyledRoot>
    </>
  );
}
