import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';
import { bankTransferConstants } from '../pages/Bank Transfers/constants';

export const getBankTranferList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.BANK_TRANSFER,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const acceptRejectBankDetails = async (data) => {
  let payload = {};
  if (data?.action === bankTransferConstants.REJECT_STATUS) {
    payload = {
      status: data?.action,
      reject_remarks: data?.remark,
    };
  } else {
    payload = {
      status: data?.action,
      amount: Number(data?.amount),
      bank_reference_no: data?.bankReferenceNumber,
    };
  }
  await apiRequest({
    endpoint: `${API_ENDPOINTS.BANK_TRANSFER}/${data?.id}`,
    method: METHODS.PUT,
    data: payload,
    headers: {
      'content-type': 'application/json',
    },
    privateURL: true,
  });
};

export const handleManualDeposit = async (data) => {
  await apiRequest({
    endpoint: `${API_ENDPOINTS.MANUAL_DEPOSIT}`,
    method: METHODS.POST,
    data: {
      to_transfer_user_id: data?.id,
      amount: Number(data?.amount),
      admin_remarks: data?.remark,
    },
    headers: {
      'content-type': 'application/json',
    },
    privateURL: true,
  });
};
