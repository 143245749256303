import * as React from 'react';
import Switch from '@mui/material/Switch';
import './style.css';
import { styled } from '@mui/material/styles';

const StyledLabel = styled('label')(() => {
  return {
    fontSize: '12px',
    fontWeight: 450,
    lineHeight: '20px',
  };
});
export default function SwitchButton({ checked, handleChange, label, name,...props }) {
  return (
    <div className='d-flex align-items-center gap-1'>
      <StyledLabel htmlFor={name}>{label}</StyledLabel>
      <Switch checked={checked} onChange={handleChange} name={name} {...props}/>
    </div>
  );
}
