import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import AuthInput from '../../components/auth-input';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import {
  changeLabelTranslation,
  errorToaster,
  normalizeSpaces,
  successToaster,
} from '../../utils/helperUtility';
import {
  ACTION_LABELS,
  STATUS_TYPE_OPTIONS,
  tokenList,
  uniqueKeyGenerator,
} from '../../common/constants/AppConstants';
import { Colors } from '../../common/constants/Color';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import { schemaToConfigClient } from '../../schema/thirdPartyClientSchema';
import RadioInput from '../../components/radio-input/RadioInput';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .css-154lg22-MuiPaper-root-MuiDialog-paper': {
    overflow: 'visible',
    width: '500px',
  },
}));

export default function ClientConfigurationModal(props) {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const [disabled, setDisabled] = useState(true);
  const [generatedUniqueKey, setGeneratedUniqueKey] = useState(undefined);
  const statusTypeOptions = changeLabelTranslation(STATUS_TYPE_OPTIONS, t);
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    description,
    actionType,
    clientId,
    dataForModal,
    reloadTableData,
  } = props;

  useEffect(() => {
    setGeneratedUniqueKey(uniqueKeyGenerator(64, tokenList[0]));
  }, []);

  const formik = useFormik({
    initialValues: {
      name: clientId ? normalizeSpaces(dataForModal?.existingName) : '',
      key: clientId ? dataForModal?.exisitingKey : generatedUniqueKey,
      status: clientId ? dataForModal?.existingStatus : true,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: schemaToConfigClient,
  });

  useEffect(() => {
    getDisabledCondition();
  }, [formik.values]);

  const getDisabledCondition = async () => {
    const validationSchema = schemaToConfigClient;
    const res = await new Promise((resolve, reject) => {
      return validationSchema
        .validate(formik.values)
        .then(() => resolve(false))
        .catch((err) => {
          console.log('err', err);
          return resolve(err.errors.length > 0);
        });
    });
    setDisabled(res);
  };

  const isFieldError = (field) => {
    return !!formik?.touched?.[field] && !!formik?.errors?.[field];
  };

  const handleSubmit = async () => {
    let data;
    if (ACTION_LABELS.ADD_NEW === actionType) {
      data = {
        payload: {
          name: formik?.values?.name,
          key: formik?.values?.key,
        },
      };
    } else {
      data = {
        payload: {
          name: formik?.values?.name,
          is_active: formik?.values?.status,
        },
        id: clientId,
      };
    }
    try {
      await handleConfirm(data);
      successToaster(
        actionType === ACTION_LABELS.ADD_NEW
          ? t('NEW_CLIENT_ADDED_SUCCESS')
          : t('UPDATE_CLIENT_SUCCESS'),
        language
      );
    } catch (error) {
      errorToaster(t('SOME_ERROR_OCCOURED'), language);
    } finally {
      handleClose();
      reloadTableData();
    }
  };

  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'status',
        value: value,
      },
    });
  };

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        <div className="row mt-4">
          <AuthInput
            className="col-12"
            htmlFor="name"
            label={t('NAME')}
            required
            sx={textInputField(isFieldError('name'), language)}
            height="40px"
            inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
            placeholder={t('NAME')}
            name="name"
            id="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            onBlur={formik.handleBlur}
            textFieldError={isFieldError('name')}
            FormHelperTextProps={{
              sx: formHelperStyle,
            }}
            helperText={isFieldError('name') && t(formik.errors.name)}
          />
        </div>
        {!clientId && (
          <div className="row">
            <AuthInput
              className="col-12"
              htmlFor="key"
              label={t('KEY')}
              sx={textInputField(isFieldError('key'), language)}
              height="40px"
              inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
              placeholder={t('KEY')}
              name="key"
              id="key"
              onChange={formik.handleChange}
              value={formik.values.key}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('key')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('key') && t(formik.errors.key)}
              disabled
            />
          </div>
        )}
        <div className="col-12 col-md-4">
          <RadioInput
            parentLabel={t('STATUS')}
            required
            value={formik.values.status}
            textFieldError={isFieldError('status')}
            helperText={isFieldError('status')}
            options={statusTypeOptions}
            labelKey="status"
            handleChange={handleRadioValueChange}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CancelButton
          onClick={() => {
            formik.resetForm();
            handleClose();
          }}
        >
          {t('Cancel')}
        </CancelButton>
        <SubmitButton
          onClick={() => {
            setDisabled(true);
            handleSubmit();
          }}
          disabled={disabled}
          autoFocus
        >
          {actionType === ACTION_LABELS.ADD_NEW ? t('ADD') : t('UPDATE')}
        </SubmitButton>
      </DialogActions>
    </StyledDialog>
  );
}
