import { Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../constants/endpoints';
import { useTranslation } from 'react-i18next';

export const Header = ({ children, headingStyle, onClick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Stack
      marginTop="26px"
      marginBottom="35px"
      direction="row"
      alignItems="center"
    >
      <IconButton
        disableRipple
        sx={{ padding: '0 !important', marginRight: '8px' }}
        onClick={onClick}
      >
        <ArrowBackIosNewIcon sx={{ color: 'black', width: 20, height: 20 }} />
      </IconButton>
      <Typography component="h2" sx={headingStyle}>
        {children}
      </Typography>
    </Stack>
  );
};
