export const mobileRegEx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; // NOSONAR

export const PASSWORD_REGEX_WITH_SPECIAL_CHARACTERS =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$])(?=.{8,32}$)/; // NOSONAR

export const NAME_REGEX = /^[A-Za-z\s]*$/;

export const WHITE_SPACES_REGEX = /^(\S+$)/g;

export const ALPHA_NUMERIC_REGEX = /[a-zA-Z0-9]+/;

export const NO_SYMBOLS = /^[^\W_]+$/;

export const NUMERIC_REGEX = /^\d*$/;

export const URL_REGEX =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w?[a-zA-Z-_%/@?]+)*([^/\w?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/; // NOSONAR

export const EMAIL_REGEX =
  /^(([0-9a-zA-Z]+['\-._+]?)+[0-9a-zA-Z]+[@]([0-9a-zA-Z]+[-]?){2,}[.](([a-zA-Z]+[-]?){2,}[.]){0,2}[a-zA-Z]{2,}){0,50}$/; // NOSONAR

export const COMMA_REGEX = /\B(?=(\d{3})+(?!\d))/g; // NOSONAR

export const tests = {
  lowerCase: /[a-z]+/,
  upperCase: /[A-Z]+/,
  digits: /\d+/,
  symbol: /[*.!@#$^&_+-]+/,
  length: /^.{6,20}$/,
};

export const EMAIL_MATCH =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,5}))$/;
