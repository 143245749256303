import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import UploadIcon from '../../assets/icons/upload-01.svg';
import { useTranslation } from 'react-i18next';
import {
  convertMegaBytesToBytes,
  errorToaster,
} from '../../utils/helperUtility';
import { Colors } from '../../common/constants/Color';
import './style.css';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '11px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  width: '104px',
  padding: '4px, 22px, 4px, 22px',
  height: '24px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

const InputFileUpload = React.forwardRef(
  (
    {
      buttonTitle,
      className,
      labelName,
      required = false,
      hideChangeOption = false,
      onUpload,
      productImages = [],
      type,
      bulkUpload = false,
      requiredLabelName = 'Test',
      fileAccept = '.pdf, .jpeg, .png',
      maxFileSize = 5,
      fileLimit = 1,
      deleteCase,
      allowedMimeTypes,
    },
    ref
  ) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === APP_LANGUAGE.ARABIC;

    const handleFileChange = (event) => {
      const files = event.target.files;
      const fileSize = convertMegaBytesToBytes(maxFileSize);

      let isLargeFile = false;
      let hasInvalidFileType = false;
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > fileSize) {
          isLargeFile = true;
          errorToaster(t(`Maximum file size is ${maxFileSize}Mb`));
          event.target.value = '';
          break;
        }

        // Check if the file type is allowed
        const fileExtension = `.${files[i].name.split('.').pop()}`;
        if (
          !Object.keys(allowedMimeTypes).includes(
            fileExtension.toLocaleLowerCase()
          )
        ) {
          hasInvalidFileType = true;
          const msg =
            deleteCase === 'PRODUCT'
              ? 'Only JPEG, and PNG files are allowed'
              : 'Only PDF, JPEG, and PNG files are allowed';
          errorToaster(t(msg));
          event.target.value = '';
          break;
        }
      }
      if (files.length + productImages.length > parseInt(fileLimit)) {
        errorToaster(t(`File limit exceed`));
        return;
      }
      if (files && !isLargeFile) {
        onUpload(files, deleteCase);
      }
    };
    return (
      <div
        className={`${className} d-flex flex-column`}
        dir={isArabic ? 'rtl' : 'ltr'}
      >
        <div
          className={`${required ? 'asterisk-content' : ''} file-upload-title`}
        >
          {labelName}
        </div>

        {!hideChangeOption && (
          <div className="file-upload-container">
            <img src={UploadIcon} />

            <SubmitButton
              component="label"
              variant="contained"
              tabIndex={-1}
              disabled={productImages.length == fileLimit}
            >
              {buttonTitle}
              <VisuallyHiddenInput
                type="file"
                ref={ref}
                accept={fileAccept}
                multiple={bulkUpload}
                onChange={handleFileChange}
              />
            </SubmitButton>
          </div>
        )}
        <div className="required-label img-asterisk-content">
          {requiredLabelName}
        </div>
      </div>
    );
  }
);
export default InputFileUpload;
