import { useEffect, useState, useContext } from 'react';

import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../../components/iconify';
import CommonTable from '../../components/index-table/CommonTable';
import { userPageStyles } from '../userPageStyle';
import Pagination from '../../components/pagination';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../common/constants/Color';
import { ACTION_LABELS, NA } from '../../common/constants/AppConstants';
import {
  configThirdPartyClientListing,
  getThirdPartyClientListing,
} from '../../api/thirdPartyClient';
import { errorToaster, getModulePerm } from '../../utils/helperUtility';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import ClientConfigurationModal from './ClientConfigurationModal';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';

export default function ThirdPartyClientListing() {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const currentModulePerm = getModulePerm(MODULE_CONST.THIRD_PARTY_CLIENTS);
  const [listingInfo, setListingInfo] = useState({
    clientListingData: [],
    totalList: 0,
    currentPage: 1,
  });
  const [loading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [actionType, setActionType] = useState(undefined);
  const [ModalMessage, setModalMessage] = useState(undefined);
  const [id, setId] = useState(undefined);
  const [dataForModal, setDataForModal] = useState({});
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(listingInfo.totalList / itemsPerPage);
  const startIndex = (listingInfo.currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (event, value) => {
    setListingInfo((prev) => ({ ...prev, currentPage: value }));
    getTableData(value);
  };

  useEffect(() => {
    getTableData();
  }, []);

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const { data } = await getThirdPartyClientListing({
        id: undefined,
      });
      setListingInfo((prev) => ({
        ...prev,
        clientListingData: data?.data?.result,
        totalList: Number(data?.data?.totalCount),
      }));
    } catch (error) {
      console.error('Error fetching admin data:', error);
      errorToaster(
        error?.data?.message ??
          error?.data ??
          error?.message ??
          t('SOME_ERROR_OCCURED'),
        language
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClientUpdate = (data) => {
    setId(data?.id);
  };

  const ButtonForTable = ({ rowItemData }) => {
    return (
      <>
        <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={`${Colors.GRAY}`}
            display={'flex'}
            alignItems={'center'}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => updateExistingClient(rowItemData)}
          >
            {t('UPDATE')}
          </Typography>
        </Box>
      </>
    );
  };

  const currentItems = listingInfo?.clientListingData?.map((client) => ({
    id: client?.id ?? NA,
    name: client?.name ?? NA,
    key: client?.key ?? NA,
    status: client?.isActive ? t('ACTIVE') : t('INACTIVE') ?? NA,
    renderButton: () =>
      currentModulePerm.writeMode && <ButtonForTable rowItemData={client} />,
  }));

  const clientListingColumnConfig = [
    {
      key: 'name',
      title: t('Name'),
      sortButton: false,
      requiredStyle: { width: '28%' },
    },
    {
      key: 'key',
      title: t('Key'),
      requiredStyle: { width: '50%' },
    },
    {
      key: 'status',
      title: t('Status'),
      sortButton: false,
      requiredStyle: { width: '12%' },
    },
    {
      key: 'renderButton',
      title: '',
      isComponent: true,
      requiredStyle: { width: '12%' },
    },
  ];

  const resourceName = {
    singular: 'client',
    plural: 'clients',
  };

  const handleModalClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsShowModal(false);
  };

  const handleConfirm = async (data) => {
    await configThirdPartyClientListing({ ...{ data } });
  };

  const handleAddClients = () => {
    setIsShowModal(true);
    setActionType(ACTION_LABELS.ADD_NEW);
    setModalMessage(t('ADD_NEW_CLIENT_MSG'));
  };

  const updateExistingClient = (rowItemData) => {
    setIsShowModal(true);
    setDataForModal({
      existingName: rowItemData?.name,
      exisitingKey: rowItemData?.key,
      existingStatus: rowItemData?.isActive,
    });
    setId(rowItemData.id);
    setActionType(ACTION_LABELS.UPDATE_EXISTING);
    setModalMessage(t('UPDATE_EXISTING_CLIENT_MSG'));
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('THIRD_PARTY_CLIENTS')}
        </Typography>
        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={handleAddClients}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('ADD_NEW_CLIENT')}
          </Button>
        )}
      </Stack>

      <Card>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={clientListingColumnConfig}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_CITY_FOUND')}
        />
        <Box
          display="flex"
          justifyContent={'flex-end'}
          sx={userPageStyles.paginationContainerSx}
        >
          <Typography
            color={Colors.LIGHT_GRAY}
            fontSize={12}
            marginY={'6px'}
            fontFamily={'Inter'}
            fontWeight={'500'}
          >
            {t('PG_SHOWING')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {Math.min(startIndex + 1, listingInfo.totalList)}-
              {Math.min(endIndex, listingInfo.totalList)}
            </Typography>
            {t('PG_FROM')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {listingInfo.totalList}
            </Typography>
            {t('PG_RESULTS')}
          </Typography>
          <Pagination
            count={pageCount}
            page={listingInfo.currentPage}
            onChange={handlePageChange}
          />
        </Box>
        {isShowModal && (
          <ClientConfigurationModal
            open={isShowModal}
            handleClose={handleModalClose}
            handleConfirm={handleConfirm}
            actionType={actionType}
            title={
              actionType === ACTION_LABELS.ADD_NEW
                ? t('ADD_NEW_CLIENT')
                : t('UPDATE_THIS_CLIENT')
            }
            reloadTableData={getTableData}
            description={t(ModalMessage)}
            clientId={actionType === ACTION_LABELS.ADD_NEW ? undefined : id}
            {...{ dataForModal }}
            ModalMessage
          />
        )}
      </Card>
    </Container>
  );
}
