export const style = {
  formButtonStyle: {
    backgroundColor: '#303030',
    height: '40px',
    padding: '6px 12px',
    borderRadius: '8px',
    gap: '2px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#FFFFFF',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#303030',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.17)',
      color: '#FFFFFF',
    },
  },

  textFieldStyle: (allPoliciesSatisfied) => ({
    '& .MuiInputBase-root': {
      height: '40px',
      borderRadius: '8px',
      borderWidth: '0.66px',
      gap: '4px',
      '& fieldset': {
        border: `0.66px solid ${
          allPoliciesSatisfied ? '#17840B' : '#8A8A8A'
        } !important`,
      },
      '&.Mui-focused': {
        backgroundColor: '#FDFDFD',
      },
    },
  }),
};

export const resetPasswordInputStyle = (language) => ({
  color: '#17840B',
  height: 20,
  marginRight: language === 'ar' ? 0 : 8,
  marginLeft: language === 'ar' ? 8 : 0,
  order: language === 'ar' ? 2 : -1,
});
