import { Box, Button, Typography } from '@mui/material';
import ModalBox from '../../modal/ModalBox';
import { Header } from '../Header';
import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { userPageStyles } from '../../../pages/userPageStyle';

const DeleteModal = ({
  isopen,
  toCloseOnOutsideModalClick,
  handleClose,
  handleConfirmDeleteClick,
  children,
  Heading,
  confirmBtnTitle,
  isModalButtonsVisible = true,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalBox
        isOpen={isopen}
        toCloseOnOutsideModalClick={toCloseOnOutsideModalClick}
        onClose={handleClose}
        // width={width}
      >
        <Header fontStyle={styles.fontStyle} closeButtonAction={handleClose}>
          <Typography sx={userPageStyles.subHeadSx}>
            {Heading ? Heading : t('DELETE_MODAL_SUB_HEAD')}
          </Typography>
        </Header>
        {children}
        <Box
          marginTop={'32px'}
          display={'flex'}
          justifyContent={'flex-end'}
          gap={'28px'}
        >
          {isModalButtonsVisible && (
            <>
              <Button onClick={handleClose} sx={styles.cancleBtnSx}>
                {t('NO')}
              </Button>
              <Button onClick={handleConfirmDeleteClick} sx={styles.yesBtnSx}>
                {confirmBtnTitle ? confirmBtnTitle : t('YES_DELETE')}
              </Button>
            </>
          )}
        </Box>
      </ModalBox>
    </>
  );
};

export default DeleteModal;
