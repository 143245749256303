import { useEffect, useState } from 'react';
import { Card, Stack, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import CommonTable from '../../components/index-table/CommonTable';
import DateRangePicker from '../../components/date-range-picker';
import SelectInput from '../../components/select-input/SelectInput';
import Pagination from '../../components/pagination';
import SearchBar from '../../components/search-bar-debounce';
import { getFormattedDate, getModulePerm } from '../../utils/helperUtility';
import ENDPOINTS from '../../constants/endpoints';
import {
  APP_LANGUAGE,
  useStatusList,
} from '../../common/constants/AppConstants';
import { SEMI_MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { Colors } from '../../common/constants/Color';
import { userPageStyles } from '../userPageStyle';
import { getWithdrawalListData } from '../../api/withdrawalRequest';
import { MODULE_CONST } from '../../common/constants/ModuleConstant';

const StyledFilterBox = styled('div')(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '12px',
    padding: '8px',
  };
});

export default function WithdrawalRequest() {
  const { t, i18n } = useTranslation();
  const currentModulePerm = getModulePerm(MODULE_CONST.WITHDRAWALS);
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    getTableData(value);
  };

  const statusList = useStatusList();

  const [filterData, setFilterData] = useState({
    createdAt: [null, null],
  });

  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
  };

  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const getListQueryPayload = () => {
    let dateFilterData = {};
    if (searchTerm) {
      dateFilterData['search_str'] = searchTerm;
    }
    if (
      filterData.createdAt?.length === 2 &&
      moment(filterData.createdAt[1]).isValid()
    ) {
      dateFilterData['start_date'] = getFormattedDate(
        filterData.createdAt[0],
        'YYYY-MM-DD'
      );
      const endDateWithExtraDay = moment(filterData.createdAt[1]).add(
        1,
        'days'
      );
      dateFilterData['end_date'] = getFormattedDate(
        endDateWithExtraDay.toDate(),
        'YYYY-MM-DD'
      );
    }
    if (!!filterData?.status) {
      dateFilterData['status'] = filterData.status;
    }
    return dateFilterData;
  };

  useEffect(() => {
    getTableData();
  }, [searchTerm, filterData]);

  const getTableData = async (page = 1) => {
    setLoading(true);
    try {
      const queryPayload = getListQueryPayload();
      const { data } = await getWithdrawalListData({
        query: {
          ...queryPayload,
          page_limit: itemsPerPage,
          page_skip: page - 1,
        },
      });
      setWithdrawalData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setCurrentPage(page);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching admin data:', error);
      setLoading(false);
    }
  };

  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;

  const goToWithdrawalDetails = (rowItemData) => {
    navigate(`${ENDPOINTS.WITHDRAWAL_DETAILS}?id=${rowItemData?.id}`);
  };

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
        <Typography
          fontWeight={'500'}
          fontSize={'12px'}
          fontFamily={'Inter'}
          color={`${Colors.GRAY}`}
          display={'flex'}
          alignItems={'center'}
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => goToWithdrawalDetails(rowItemData)}
        >
          {t('VIEW')}
        </Typography>
      </Box>
    );
  };

  const currentItems = withdrawalData.map((item) => {
    const fullName = [item?.firstName, item?.middleName, item?.lastName]
      .filter(Boolean)
      .join(' ');
    const completePhoneNumber = [item?.mobileCode, item?.phoneNo]
      .filter(Boolean)
      .join('-');
    const customerInfo = [fullName, item?.email, `+${completePhoneNumber}`]
      .filter(Boolean)
      .map((item, index, array) => (
        <div>
          {item}
          {index < array.length - 1 && <br />}
        </div>
      ));
    return {
      id: item?.id,
      transactionNo: item?.transactionNo || 'NA',
      customerInfo: customerInfo,
      withdrawalAmount: item?.amount || 'NA',
      dateTimeOfSubmission: moment(item?.createdAt).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      status: item?.status,
      renderButton: () =>
        currentModulePerm.writeMode && <ButtonsForTable rowItemData={item} />,
    };
  });

  const columnConfig = [
    {
      key: 'transactionNo',
      title: t('TRANSACTION_NO'),
      content: (rowData) => <strong>{rowData.transactionNo}</strong>,
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'customerInfo',
      title: t('CUSTOMER_INFO'),
      requiredStyle: { width: '20%' },
    },
    {
      key: 'withdrawalAmount',
      title: t('WITHDRAWAL_AMOUNT'),
      requiredStyle: { width: '20%' },
    },
    {
      key: 'dateTimeOfSubmission',
      title: t('DATE_TIME_OF_SUBMISSION'),
      sortButton: false,
      requiredStyle: { width: '20%' },
    },
    {
      key: 'status',
      title: t('STATUS'),
      requiredStyle: { width: '20%' },
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleFilterSelection = (key, event) => {
    setFilterData((existingData) => {
      const data = {
        ...existingData,
        [key]: event,
      };

      return data;
    });
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('WITHDRAWALS')}
        </Typography>
      </Stack>
      <Card>
        <StyledFilterBox>
          <div className="w-50 d-flex justify-content-start">
            <SelectInput
              placeholder={t(`STATUS`)}
              labelInline={true}
              containerSx={{ width: '120px' }}
              label={'Status'}
              value={filterData.status}
              options={statusList}
              handleSelectChange={(event) => {
                handleFilterSelection('status', event);
              }}
            />
            <div className="ms-2">
              <DateRangePicker
                placeholder={t('DATE_PICKER_CREATION_DATE_TITLE')}
                dateRange={filterData.createdAt}
                popperPlacement="bottom-end"
                setDateRange={(event) =>
                  handleFilterSelection('createdAt', event)
                }
              />
            </div>
          </div>
          <SearchBar
            onChange={handleSearchChange}
            value={searchTerm}
            placeholder={t('Search')}
            maxlength={SEMI_MAX_INPUT_LENGTH}
            onClear={handleClearSearch}
          />
        </StyledFilterBox>
        <CommonTable
          resourceName={resourceName}
          data={currentItems}
          columns={columnConfig}
          isCheckBox={false}
          loading={loading}
          onSortButtonClick={() => {}}
          emptyTableText={t('NO_WITHDRAWAL_FOUND')}
        />
        <Box
          display="flex"
          justifyContent={'flex-end'}
          sx={userPageStyles.paginationContainerSx}
        >
          <Typography
            color={Colors.LIGHT_GRAY}
            fontSize={12}
            marginY={'6px'}
            fontFamily={'Inter'}
            fontWeight={'500'}
          >
            {t('PG_SHOWING')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {Math.min(startIndex + 1, totalList)}-
              {Math.min(endIndex, totalList)}
            </Typography>
            {t('PG_FROM')}
            <Typography
              component={'span'}
              fontSize={12}
              color={Colors.DARK_GRAY}
              fontFamily={'Inter'}
              fontWeight={'500'}
              marginX={'2px'}
            >
              {totalList}
            </Typography>
            {t('PG_RESULTS')}
          </Typography>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Card>
    </Container>
  );
}
