import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import UpdateTicketStatus from './UpdateTicketStatus';
import { Colors } from '../../common/constants/Color';
import { getTicketsDetails, updateTicketStatus } from '../../api/tickets';
import {
  errorToaster,
  getFormattedDate,
  successToaster,
} from '../../utils/helperUtility';
import ENDPOINTS from '../../constants/endpoints';
import { TICKET_STATUS } from './constants';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';

const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',
    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

export default function TicketDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [ticketData, setTicketData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const { language } = useContext(LanguageContext);

  function useQuery() {
    return new URLSearchParams(location.search);
  }

  const requestId = () => {
    return query.get('id');
  };

  const query = useQuery();

  const onStatusUpdate = async (payload, action) => {
    try {
      setLoading(false);
      const response = await updateTicketStatus(payload, ticketData?.id);
      if (response.data.isSuccess) {
        const msg = returntoastMsg(action);
        successToaster(t(msg), language);
        navigate(ENDPOINTS.TICKET_MANAGEMENT);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      errorToaster(t(err.data?.message || 'SOME_ERROR_OCCOURED'), language);
    }
  };

  const returntoastMsg = (action) => {
    if (action === TICKET_STATUS.OPEN) return 'IN_PROGRESS_MSG';
    if (action === TICKET_STATUS.IN_PROGRESS) return 'CLOSED_MSG';
  };

  useEffect(() => {
    const id = requestId();
    const getDataForReq = async () => {
      setLoading(true);
      try {
        const response = await getTicketsDetails(id);
        const data = response.data.data;
        setTicketData(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    getDataForReq();
  }, []);

  console.log('ss', ticketData);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.TICKET_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} alt="Back" />
        </div>
        <FormTitle>{t('TICKET_DETAILS')}</FormTitle>
      </div>

      {loading ? (
        <Stack
          height={'300px'}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <CircularProgress />
        </Stack>
      ) : (
        <FormContainer>
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('TITLE')}</div>
              <div className="account-details-value">
                {ticketData.title || 'N/A'}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('STATUS')}</div>
              <div className="account-details-value">
                {ticketData.status === TICKET_STATUS.IN_PROGRESS
                  ? 'IN PROGRESS'
                  : ticketData.status || 'N/A'}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('DESCRIPTION')}</div>
              <div className="account-details-value">
                {ticketData.description || 'N/A'}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('CREATED_AT')}</div>
              <div className="account-details-value">
                {getFormattedDate(
                  ticketData.createdAt,
                  'DD/MM/YYYY HH:mm:ss'
                ) || 'N/A'}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('EMAIL_TEXT')}</div>
              <div className="account-details-value">
                {ticketData.email || 'N/A'}
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="account-details-label">{t('PHONE')}</div>
              <div className="account-details-value">
                {ticketData.phoneNo || 'N/A'}
              </div>
            </div>

            {ticketData && ticketData?.remarks && (
              <div className="col-12 col-md-4">
                <div className="account-details-label">{t('ADMIN_REMAKS')}</div>
                <div className="account-details-value">
                  {ticketData.remarks || 'NA'}
                </div>
              </div>
            )}
          </div>

          {ticketData?.status !== TICKET_STATUS.CLOSED && (
            <div className="d-flex justify-content-end">
              <SubmitButton onClick={() => setOpenStatusModal(true)}>
                {t('UPDATE_STATUS')}
              </SubmitButton>
            </div>
          )}
        </FormContainer>
      )}

      {openStatusModal && (
        <UpdateTicketStatus
          open={openStatusModal}
          handleClose={() => setOpenStatusModal(false)}
          ticketData={ticketData}
          title={t('UPDATE_STATUS')}
          submitBtnText={
            ticketData?.status === 'OPEN'
              ? t('MARK_INPROGRESS')
              : t('MARK_CLOSED')
          }
          handleStatusUpdate={onStatusUpdate}
        />
      )}
    </div>
  );
}
