export const style = {
  formButtonStyle: {
    backgroundColor: '#303030',
    height: '40px',
    padding: '6px 12px',
    borderRadius: '8px',
    gap: '2px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: '#FFFFFF',
    marginTop: '44px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#303030',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.17)',
      color: '#FFFFFF',
    },
  },
  subTitle: (language) => ({
    color: '#777673',
    fontSize: '14px',
    fontfamily: 'lato',
    fontWeight: '400',
    textAlign: language === 'ar' ? 'right' : 'left',
    marginLeft: language === 'ar' ? 'auto' : '0',
  }),

  textFieldStyle: {
    '& .MuiInputBase-root': {
      height: '48px',
      width: '56px',
      borderRadius: '8px',
      borderWidth: '0.66px',
      overflow: 'hidden',
      gap: '4px',
      '& fieldset': {
        border: '0.66px solid #8A8A8A !important',
      },
      '&.Mui-focused': {
        backgroundColor: '#FDFDFD',
      },
    },
  },
  textFieldInputProps: {
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'montserrat',
  },

  inputStyle: {
    width: '56px',
    height: '48px',
    margin: '0 4px',
    fontSize: '16px',
    borderRadius: '8px',
    border: '1px solid #8A8A8A',
    textAlign: 'center',
    outline: 'none',
  },
};
