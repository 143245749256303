import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';
export const addRoleFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(4, 'Too Short!')
    .max(30, 'Too Long!')
    .matches(REGEX_PATTERN.ALPHA_NUMERIC, 'Invalid Role Name!')
    .required('Required')
    .label('Role Name'),
  description: Yup.string()
    .min(4, 'Too Short!')
    .max(100, 'Too Long!')
    .matches(REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS, 'Invalid Description!')
    .required('Required')
    .label('Description'),
});
