import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTransactionDetails } from '../../api/transactions';
// import { LanguageContext } from '../../../../globalContext/LanguageSwitcher';
import { styled } from '@mui/material/styles';
import LeftChevronIcon from '../../assets/icons/left_chevron.svg';
import TextField from '@mui/material/TextField';
import ENDPOINTS from '../../constants/endpoints';
import './TransactionManagement.css';
import { Colors } from '../../common/constants/Color';
import { getFormattedDate } from '../../utils/helperUtility';
import { sourceMapper } from './constant';
const FormTitle = styled('div')(({}) => {
  return {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
  };
});

const CancelButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.SLATE_GRAY}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));
const FormContainer = styled('div')(({}) => {
  return {
    marginTop: '35px',
    height: '100%',
    maxHeight: '480px',
    padding: '22px 32px 32px 32px',
    borderRadius: '14px',

    background: `${Colors.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
});

const FormButtons = styled('div')(({}) => {
  return {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '28px',
  };
});

export default function TransactiionDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [transactionData, setTransactionData] = useState([]);
  function useQuery() {
    return new URLSearchParams(location.search);
  }
  const query = useQuery();

  useEffect(() => {
    const fetchAllData = async () => {
      const adminId = query.get('transactionId');
      setLoading(true);
      try {
        const response = await getTransactionDetails(adminId);
        const data = response.data.data;
        if (data) {
          data.createdAt = getFormattedDate(
            data.createdAt,
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        setTransactionData(data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchAllData();
  }, []);
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleCancelButton = () => {
    navigate(ENDPOINTS.TRANSACTION_MANAGEMENT);
  };

  const printName = (item) => {
    // return `${details.firstName || ''}  ${details.middleName || ''} ${
    //   details.lastName || ''
    // }`;
    return item.firstName || item.middleName || item.lastName
      ? `${item.firstName || ''} ${item.middleName || ''} ${
          item.lastName || ''
        }`.trim()
      : 'NA';
  };

  const phoneNumber = (item) => {
    if (item.mobileCode) {
      return `(+${item.mobileCode})${item.phoneNo}`;
    } else {
      return item.phoneNo || 'NA';
    }
  };

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center gap-2">
        <div
          className="cursor-pointer"
          onClick={() => navigate(ENDPOINTS.TRANSACTION_MANAGEMENT)}
        >
          <img src={LeftChevronIcon} />
        </div>
        <FormTitle>{t('TRANSACTION_DETAILS')}</FormTitle>
      </div>
      <FormContainer>
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Transaction ID')}</div>
                <div className="list-value">
                  {transactionData.transactionNo || 'NA'}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Transaction Date')}</div>
                <div className="list-value">
                  {transactionData.createdAt || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('NAME')}</div>
                <div className="list-value">{printName(transactionData)}</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('EMAIL_TEXT')}</div>
                <div className="list-value">
                  {transactionData.email || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('PHONE')}</div>
                <div className="list-value">
                  <div className="list-value">
                    {phoneNumber(transactionData)}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Country')}</div>
                <div className="list-value">
                  <div className="list-value">
                    {transactionData.nameEng || 'NA'}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('transaction amount')}</div>
                <div className="list-value">
                  {transactionData.amount || 'NA'}
                  {` ${transactionData.currency || ''}`}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Transaction Quantity')}</div>
                <div className="list-value">
                  {transactionData.quantity || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Unit Price')}</div>
                <div className="list-value">
                  {transactionData.unitPrice || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('Mode')}</div>
                <div className="list-value">{transactionData.mode || 'NA'}</div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('SOURCE')}</div>
                <div className="list-value">
                  {sourceMapper[transactionData.source] || 'NA'}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="transaction-container">
              <div className="list">
                <div className="list-heading">{t('STATUS')}</div>
                <div className="list-value">
                  {transactionData.status || 'NA'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormButtons>
          {/* <CancelButton onClick={() => handleCancelButton()}>
            {t('CLOSE')}
          </CancelButton> */}
        </FormButtons>
      </FormContainer>
    </div>
  );
}
