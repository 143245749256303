import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';

export const validationSchema = Yup.object().shape({
  remarks: Yup.string()
    .required('Remarks is required')
    .min(3, 'Too Short!')
    .max(100, 'Too Long!')
    .matches(REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS, 'Invalid remarks'),
});
