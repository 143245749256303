export const WITHDRAWAL_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
};

export const withdrawalConstants = {
  ACCEPT: 'approve',
  REJECT: 'reject',
  ACCEPT_STATUS: 'APPROVED',
  REJECT_STATUS: 'REJECTED',
};
