import { Box, CircularProgress, Typography } from '@mui/material';
import { formInputStyle } from './../add-user/AddUser.style';
import { useFormik } from 'formik';
import { warehouseSchema } from '../../schema';
import AuthInput from '../../components/auth-input';
import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from './../../sections/auth/login/LoginForm.style';
import { useState, useContext, useMemo } from 'react';
import { Colors, MAX_INPUT_LENGTH } from '../../common/constants/AppConstants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAsync } from './../../hooks/useAsync';
import { getCountries } from '../../api/getCountries';
import { InputSaveButton } from '../add-user/InputSaveButton';
import PhoneNumber from '../phone-number';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Place from '../place/Place';
import { addWarehouse, updateWarehouse, warehouse } from '../../api/warehouse';
import {
  successToaster,
  errorToaster,
  getModulePerm,
} from '../../utils/helperUtility';
import { LanguageContext } from './../../globalContext/LanguageSwitcher';
import ENDPOINTS from '../../constants/endpoints';
import { Stack } from '@mui/system';
import RadioInput from '../../components/radio-input';
import SelectInput from '../select-input/SelectInput';
import { getCityListing } from '../../api/cityManagement';
const Mode = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
};

export default function WarehouseForm({ width, minHeight }) {
  const [isFetchedSearchAddress, setIsFetchedSearchAddress] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [fetchUserDataSuccess, setFetchUserDataSuccess] = useState(undefined);
  const [result, setResult] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: '',
    searchAddress: '',
    firstAddressLine: '',
    secondAddressLine: '',
    city: '',
    cityId: '',
    zipCode: '',
    latitude: '',
    longitude: '',
    isActive: true,
    contactPersonName: '',
    contactPersonCountryCode: '',
    contactPersonPhoneNumber: '',
  });
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, view] = [searchParams.get('id'), searchParams.get('view')];
  const currentModulePerm = getModulePerm('WAREHOUSE_MANAGEMENT');
  const isFieldError = (field) => {
    if (!formik.touched?.[field] && formik.values[field] === '') return false;
    return !!formik.errors?.[field];
  };
  const isFieldDisabled = (fieldName) => {
    const disabledFields = ['latitude', 'longitude', 'city', 'zipCode'];
    if (mode === Mode.CREATE || mode === Mode.UPDATE) {
      if (isFetchedSearchAddress) {
        return Boolean(disabledFields.includes(fieldName) && result[fieldName]);
      } else {
        return false;
      }
    }
    if (mode === Mode.READ) return true;
    return false;
  };
  const buttonInfo = () => {
    if (mode === Mode.CREATE || mode === Mode.UPDATE) {
      const isFormValid = () => {
        const {
          name,
          firstAddressLine,
          city,
          cityId,
          zipCode,
          latitude,
          longitude,
          contactPersonName,
          contactPersonPhoneNumber,
          contactPersonCountryCode,
        } = formik.values;
        return Boolean(
          name &&
            firstAddressLine &&
            city &&
            cityId &&
            latitude &&
            longitude &&
            contactPersonName &&
            contactPersonPhoneNumber &&
            contactPersonCountryCode &&
            Object.keys(formik.errors).length === 0 &&
            formik.isValid &&
            !formik.isSubmitting
        );
      };
      return {
        isDisabled: !isFormValid(),
        onClick: (event) => formik.submitForm(event),
      };
    }
    return {
      isDisabled: false,
      onClick: () =>
        setSearchParams({
          id,
        }),
    };
  };
  const mode = useMemo(() => {
    if (id) return view == 'true' ? Mode.READ : Mode.UPDATE;
    return Mode.CREATE;
  }, [id, view]);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: warehouseSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      const {
        name,
        contactPersonCountryCode,
        firstAddressLine,
        secondAddressLine,
        cityId,
        zipCode,
        contactPersonName,
        latitude,
        longitude,
        isActive,
        contactPersonPhoneNumber,
      } = values;
      const requestBody = {
        name,
        fkCountriesId: contactPersonCountryCode,
        addressLine1: firstAddressLine,
        addressLine2: secondAddressLine || undefined,
        fk_cities_id: cityId ?? '',
        zipCode: zipCode || undefined,
        contactPersonName,
        latitude: latitude ? parseFloat(latitude) : undefined,
        longitude: longitude ? parseFloat(longitude) : undefined,
        contactPersonNo: contactPersonPhoneNumber,
      };
      try {
        const response =
          mode === Mode.CREATE
            ? await addWarehouse(requestBody)
            : await updateWarehouse({ ...requestBody, isActive }, id);
        successToaster(t(response.data.message), language);
        navigate(ENDPOINTS.WAREHOUSE_MANAGEMENT);
      } catch (error) {
        if (
          error.data.message &&
          error.data.message.includes('duplicate key value')
        ) {
          errorToaster(t('Phone no should be unique'), language);
        } else {
          errorToaster(t(error.data.message), language);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });
  const {
    loading: getContriesLoadingStatusIsSuccess,
    value: getCountriesResponse,
    error: getCountriesError,
  } = useAsync(getCountries, []);
  useEffect(() => {
    const fetchUserData = async () => {
      if (mode === Mode.READ || mode === Mode.UPDATE) {
        try {
          const warehouseResult = await warehouse(id);
          setFetchUserDataSuccess(warehouseResult?.data);
        } catch (err) {
          setNetworkError(true);
        }
      }
    };
    fetchUserData();
    getCityOptions();
  }, []);
  const getCityOptions = async () => {
    try {
      const { data } = await getCityListing({});
      setCityOptions(() =>
        data.data.map((obj) => ({
          value: obj?.id,
          label: obj?.name,
        }))
      );
    } catch (error) {
      console.error('Error fetching city options:', error);
      errorToaster(t(error ?? error.data.message), language);
    }
  };
  const errors = [getCountriesError];
  useEffect(() => {
    if (errors.includes(true) === true) setNetworkError(true);
  }, errors);
  useEffect(() => {
    const defaultCountryResponse = getCountriesResponse?.data?.data?.[0];
    const { id } = defaultCountryResponse ?? {};
    const address = result?.address?.split(', ') ?? [];
    const firstAddressLine = address.slice(0, address?.length / 2);
    const secondAddressLine = address.slice(
      address?.length / 2,
      address.length
    );
    const {
      name,
      contactPersonName,
      contactPersonCountryCode,
      contactPersonPhoneNumber,
    } = formik.values;
    formik.setValues({
      name: name ?? '',
      isActive: initialValues.isActive ?? true,
      searchAddress: result?.address ?? '',
      firstAddressLine: firstAddressLine.join(', '),
      secondAddressLine: secondAddressLine.join(', '),
      city: result.city ?? initialValues.city ?? '',
      cityId: result.cityId ?? initialValues.cityId ?? '',
      zipCode: result.zipCode ?? '',
      latitude: result.latitude ?? '',
      longitude: result.longitude ?? '',
      contactPersonName: contactPersonName ?? '',
      contactPersonCountryCode: contactPersonCountryCode
        ? contactPersonCountryCode
        : !getContriesLoadingStatusIsSuccess
        ? id ?? ''
        : '',
      contactPersonPhoneNumber: contactPersonPhoneNumber ?? '',
    });
    if (result && Object.keys(result).length) {
      setIsFetchedSearchAddress(true);
    }
  }, [result, getCountriesResponse]);
  useEffect(() => {
    if (
      (mode === Mode.UPDATE || mode === Mode.READ) &&
      !getCountriesError &&
      getContriesLoadingStatusIsSuccess === false &&
      fetchUserDataSuccess?.isSuccess
    ) {
      const {
        name,
        fkCountriesId,
        addressLine1,
        addressLine2,
        city,
        fkCitiesId,
        zipCode,
        contactPersonName,
        latitude,
        longitude,
        isActive,
        contactPersonNo,
      } = fetchUserDataSuccess?.data ?? {};
      setInitialValues((prev) => ({
        name: name ?? prev.name ?? '',
        contactPersonCountryCode: fkCountriesId ?? prev.fkCountriesId ?? '',
        searchAddress: prev.searchAddress ?? '',
        firstAddressLine: addressLine1 ?? prev.firstAddressLine ?? '',
        secondAddressLine: addressLine2 ?? prev.secondAddressLine ?? '',
        city: city ?? prev?.city ?? '',
        cityId: fkCitiesId ?? prev?.cityId ?? '',
        zipCode: zipCode ?? prev.zipCode ?? '',
        contactPersonName: contactPersonName ?? prev.contactPersonName ?? '',
        latitude: latitude ?? prev.latitude ?? '',
        longitude: longitude ?? prev.longitude ?? '',
        contactPersonPhoneNumber:
          contactPersonNo ?? prev.contactPersonPhoneNumber ?? '',
        isActive: isActive ?? prev?.isActive ?? true,
      }));
    }
  }, [
    getContriesLoadingStatusIsSuccess,
    getCountriesError,
    fetchUserDataSuccess,
  ]);

  const handleRadioValueChange = (value) => {
    formik.handleChange({
      target: {
        name: 'isActive',
        value: value,
      },
    });
  };
  const content = (
    <Box
      component={'form'}
      width={width}
      minHeight={minHeight}
      paddingX={'32px'}
      paddingTop={'22px'}
      paddingBottom={'130px'}
      sx={{ backgroundColor: Colors.WHITE, borderRadius: '14px' }}
    >
      {networkError ? (
        <Typography variant="h3" textAlign={'center'} color="red" mt={24}>
          Some Error Occoured !!
        </Typography>
      ) : (
        <>
          <Box sx={formInputStyle}>
            <Box>
              <AuthInput
                htmlFor="name"
                label={t('Warehouse name')}
                required={true}
                sx={textInputField(
                  isFieldError('name') && formik.errors.name,
                  language
                )}
                disabled={isFieldDisabled('name')}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                autoComplete="off"
                placeholder={t('Name')}
                name="name"
                id="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('name') && formik.errors.name}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={isFieldError('name') && t(formik.errors.name)}
              />
            </Box>
            {mode !== Mode.READ && (
              <Box>
                <Place
                  props={{
                    setResult,
                    initialValues,
                    setInitialValues,
                    formik,
                  }}
                >
                  <AuthInput
                    htmlFor="searchAddress"
                    label={t('Landmark')}
                    sx={textInputField(
                      isFieldError('searchAddress') &&
                        formik.errors.searchAddress,
                      language
                    )}
                    disabled={isFieldDisabled('searchAddress')}
                    autoComplete="off"
                    height="40px"
                    inputProps={{
                      sx: textInputFieldStyle,
                      maxLength: 255,
                    }}
                    placeholder={t('Landmark')}
                    name="searchAddress"
                    id="searchAddress"
                    FormHelperTextProps={{
                      sx: formHelperStyle,
                    }}
                    onChange={(event) => {
                      setIsFetchedSearchAddress(false);
                      formik.handleChange(event);
                    }}
                    value={formik.values.searchAddress}
                    onBlur={formik.handleBlur}
                    textFieldError={
                      isFieldError('searchAddress') &&
                      formik.errors.searchAddress
                    }
                    helperText={
                      isFieldError('searchAddress') &&
                      t(formik.errors.searchAddress)
                    }
                  />
                </Place>
              </Box>
            )}
            <Box>
              <AuthInput
                htmlFor="latitude"
                allowdecimals={true}
                autoComplete="off"
                inputType="number"
                label={t('Latitude')}
                required={true}
                sx={textInputField(
                  isFieldError('latitude') && formik.errors.latitude,
                  language
                )}
                disabled={isFieldDisabled('latitude')}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: 20,
                }}
                placeholder={t('Latitude')}
                name="latitude"
                id="latitude"
                onChange={formik.handleChange}
                value={formik.values.latitude}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('latitude') && formik.errors.latitude
                }
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('latitude') && t(formik.errors.latitude)
                }
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="longitude"
                allowdecimals={true}
                inputType={'number'}
                autoComplete="off"
                label={t('Longitude')}
                required={true}
                sx={textInputField(
                  isFieldError('longitude') && formik.errors.longitude,
                  language
                )}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: 20,
                }}
                placeholder={t('Longitude')}
                name="longitude"
                id="longitude"
                onChange={formik.handleChange}
                value={formik.values.longitude}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('longitude') && formik.errors.longitude
                }
                disabled={isFieldDisabled('longitude')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('longitude') && t(formik.errors.longitude)
                }
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="addressLineOne"
                autoComplete="off"
                label={t('Address Line 1')}
                required={true}
                sx={textInputField(
                  isFieldError('firstAddressLine') &&
                    formik.errors.firstAddressLine,
                  language
                )}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: 255,
                }}
                placeholder={t('Address Line 1')}
                name="firstAddressLine"
                id="addressLineOne"
                onChange={formik.handleChange}
                disabled={isFieldDisabled('firstAddressLine')}
                value={formik.values.firstAddressLine}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('firstAddressLine') &&
                  formik.errors.firstAddressLine
                }
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('firstAddressLine') &&
                  t(formik.errors.firstAddressLine)
                }
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="secondAddressLine"
                autoComplete="off"
                label={t('Address Line 2')}
                required={false}
                sx={textInputField(
                  isFieldError('secondAddressLine') &&
                    formik.errors.secondAddressLine,
                  language
                )}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: 255,
                }}
                disabled={isFieldDisabled('secondAddressLine')}
                placeholder={t('Address Line 2')}
                name="secondAddressLine"
                id="secondAddressLine"
                onChange={formik.handleChange}
                value={formik.values.secondAddressLine}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('secondAddressLine') &&
                  formik.errors.secondAddressLine
                }
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('secondAddressLine') &&
                  t(formik.errors.secondAddressLine)
                }
              />
            </Box>
            <Box>
              <div className="row gx-1" style={{ height: '40px' }}>
                <SelectInput
                  customStylingClass="custom-app-polaris-select-input"
                  value={initialValues.cityId}
                  label="City"
                  options={cityOptions}
                  handleSelectChange={(selectedOptions) => {
                    setInitialValues({
                      name: formik.values.name,
                      isActive: formik.values.isActive,
                      searchAddress: formik.values.searchAddress,
                      latitude: formik.values.latitude,
                      longitude: formik.values.longitude,
                      firstAddressLine: formik.values.firstAddressLine,
                      secondAddressLine: formik.values.secondAddressLine,
                      zipCode: formik.values.zipCode,
                      latitude: formik.values.latitude,
                      longitude: formik.values.longitude,
                      contactPersonCountryCode:
                        formik.values.contactPersonCountryCode,
                      contactPersonName: formik.values.contactPersonName,
                      contactPersonPhoneNumber:
                        formik.values.contactPersonPhoneNumber,
                      city: selectedOptions,
                      cityId: selectedOptions,
                    });
                  }}
                  placeholder={t('SELECT_CITY')}
                  required
                />
              </div>
            </Box>
            <Box>
              <AuthInput
                htmlFor="zipCode"
                autoComplete="off"
                label={t('Zip Code')}
                sx={textInputField(
                  isFieldError('zipCode') && formik.errors.zipCode,
                  language
                )}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: 7,
                }}
                placeholder={t('Zip Code')}
                name="zipCode"
                id="zipCode"
                disabled={isFieldDisabled('zipCode')}
                onChange={formik.handleChange}
                value={formik.values.zipCode}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('zipCode') && formik.errors.zipCode
                }
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={isFieldError('zipCode') && t(formik.errors.zipCode)}
              />
            </Box>
            <Box>
              <AuthInput
                htmlFor="contactPersonName"
                label={t('Contact Person Name')}
                autoComplete="off"
                required={true}
                sx={textInputField(
                  isFieldError('contactPersonName') &&
                    formik.errors.contactPersonName,
                  language
                )}
                height="40px"
                inputProps={{
                  sx: textInputFieldStyle,
                  maxLength: MAX_INPUT_LENGTH,
                }}
                placeholder={t('Contact Person Name')}
                name="contactPersonName"
                id="contactPersonName"
                disabled={isFieldDisabled('contactPersonName')}
                onChange={formik.handleChange}
                value={formik.values.contactPersonName}
                onBlur={formik.handleBlur}
                textFieldError={
                  isFieldError('contactPersonName') &&
                  formik.errors.contactPersonName
                }
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('contactPersonName') &&
                  t(formik.errors.contactPersonName)
                }
              />
            </Box>
            <Box>
              <Box>
                <PhoneNumber
                  fieldName="contactPersonCountryCode"
                  autoComplete="off"
                  multiSelect={false}
                  labelName={t('Contact Person Phone No')}
                  disabled={isFieldDisabled('contactPersonCountryCode')}
                  disableInput={isFieldDisabled('contactPersonPhoneNumber')}
                  options={
                    getContriesLoadingStatusIsSuccess
                      ? []
                      : getCountriesResponse?.data?.data?.map(
                          ({ id, mobileCode, currency }) => ({
                            id,
                            mobileCode,
                            currency,
                          })
                        )
                  }
                  selectedOptions={formik.values.contactPersonCountryCode}
                  formik={formik}
                  errorText={
                    isFieldError('contactPersonCountryCode') &&
                    formik.errors.contactPersonCountryCode
                  }
                  htmlFor="phoneNumber"
                  label={''}
                  required={true}
                  sx={textInputField(
                    isFieldError('contactPersonPhoneNumber'),
                    language
                  )}
                  height="40px"
                  inputProps={{ sx: textInputFieldStyle, maxLength: 15 }}
                  placeholder={t('Phone number')}
                  name="contactPersonPhoneNumber"
                  id="contactPersonPhoneNumber"
                  onChange={formik.handleChange}
                  value={formik.values.contactPersonPhoneNumber}
                  onBlur={formik.handleBlur}
                  textFieldError={
                    isFieldError('contactPersonPhoneNumber') &&
                    formik.errors.contactPersonPhoneNumber
                  }
                  FormHelperTextProps={{
                    sx: formHelperStyle,
                  }}
                  helperText={
                    isFieldError('contactPersonPhoneNumber') &&
                    t(formik.errors.contactPersonPhoneNumber)
                  }
                />
              </Box>
            </Box>

            <Box>
              <RadioInput
                disabled={isFieldDisabled('status') || mode === Mode.CREATE}
                parentLabel={t('STATUS')}
                required={true}
                value={formik.values.isActive}
                textFieldError={isFieldError('isActive')}
                helperText={isFieldError('isActive')}
                options={[
                  { label: t('ACTIVE'), value: true },
                  { label: t('INACTIVE'), value: false },
                ]}
                labelKey="isActive"
                handleChange={handleRadioValueChange}
              />
            </Box>
          </Box>

          <Box
            sx={{
              gridColumn: '1 / -1',
              marginTop: '12px',
              display: 'grid',
              gridAutoRows: '40px',
              justifyContent: 'end',
              gridAutoFlow: 'column',
            }}
          >
            <InputSaveButton
              variant="contained"
              onClick={() => navigate(`${ENDPOINTS.WAREHOUSE_MANAGEMENT}`)}
              cancelButton
            >
              {t('Cancel')}
            </InputSaveButton>
            <InputSaveButton
              variant="contained"
              onClick={(event) => buttonInfo().onClick(event)}
              disabled={buttonInfo().isDisabled}
              style={{
                display: currentModulePerm.writeMode ? '' : 'none',
              }}
            >
              {t(
                mode === Mode.CREATE
                  ? 'CREATE'
                  : mode === Mode.READ
                  ? 'Edit'
                  : 'Update'
              )}
            </InputSaveButton>
          </Box>
        </>
      )}
    </Box>
  );
  return !getContriesLoadingStatusIsSuccess &&
    (mode === Mode.CREATE || fetchUserDataSuccess?.isSuccess) ? (
    <>{content}</>
  ) : (
    <Stack height={'300px'} justifyContent={'flex-end'} alignItems={'center'}>
      <CircularProgress />
    </Stack>
  );
}
