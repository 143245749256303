import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const resetPassword = (email, newPassword, authToken) =>
  apiRequest({
    endpoint: API_ENDPOINTS.RESET_PASSWORD,
    method: METHODS.POST,
    data: {
      email: email,
      new_password: newPassword,
    },
    privateURL: true,
    token: authToken,
  });
