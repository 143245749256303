import { createSlice } from '@reduxjs/toolkit';
import { getKycList as getKycListApi } from '../../api/kyc';
import asyncActionCreator from '../../common/utils/asyncActionCreator';

export const kycListSliceName = 'kycLists';

export const getKycList = asyncActionCreator(
  `${kycListSliceName}/list`,
  getKycListApi,
  {
    doNotNotify: true,
    doNotLoad: true,
  }
);

const initialState = {
  loading: true,
  error: false,
  data: [],
  totalList: 0,
  limit: 10,
};

const getKycListSlice = createSlice({
  name: kycListSliceName,
  initialState,
  reducers: {
    resetKycList: (state) => {
      state.data = {
        ...initialState.data,
        list: { ...initialState.data },
      };
      state.error = initialState.error;
      state.loading = initialState.loading;
      state.totalList = initialState.totalList;
      state.limit = initialState.limit;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getKycList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getKycList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.data = action.payload.data || [];
        state.totalList = action.payload.totalCount;
        state.limit = action.payload.limit;
      })
      .addCase(getKycList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetKycList } = getKycListSlice.actions;

export default getKycListSlice.reducer;
