import React from 'react';
import { styled } from '@mui/material/styles';
import { Colors } from '../../common/constants/Color';
import Button from '@mui/material/Button';

const RejButton = styled(Button)(({ theme }) => ({
  background: `${Colors.GENERAL_GRAY}`,
  color: `${Colors.RED}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.GENERAL_GRAY}`,
  },
}));

function RejectButton({ handleClick, disabledCondn = false, children }) {
  return (
    <RejButton disabled={disabledCondn} onClick={() => handleClick()}>
      {children}
    </RejButton>
  );
}

export default RejectButton;
