import * as Yup from 'yup';
import { REGEX_PATTERN } from '../constants/regex';

export const acceptRequestSchema = Yup.object().shape({
  bankReferenceNumber: Yup.string()
    .test(
      'check-special-character',
      'Special Characters like *,@,!,^,# are not allowed.',
      (value) => REGEX_PATTERN.ALL_EXCLUDING_SPECIAL_CHARACTERS.test(value)
    )
    .test(
      'check-length',
      'Bank reference number must be 50 characters long.',
      (value) => value.length <= 50
    )
    .required('Field can not be empty!')
    .label('bankReferenceNumber'),
});

export const rejectRequestSchema = Yup.object().shape({
  remark: Yup.string()
    .test(
      'check-min-length',
      'You remark is too short!',
      (value) => value.length > 3
    )
    .test(
      'check-max-length',
      'Your remark is too long!',
      (value) => value.length <= 50
    )
    .test(
      'check-special-characters',
      'Special Characters like *,@,!,^,# are not allowed.',
      (value) => REGEX_PATTERN.ALPHA_NUMERIC_WITH_SPECIAL_CHARS.test(value)
    )
    .required('Field cannot be empty')
    .label('remark'),
});
