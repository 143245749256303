import React, { useContext, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Colors } from '../../common/constants/Color';
import AuthInput from '../../components/auth-input';
import RejectButton from '../../components/RejectButton/RejectButton';
import { useFormik } from 'formik';
import {
  basekValidationSchema,
  placedKSchema,
  rejectKSchema,
  confirmKSchema,
  shippedKSchema,
} from '../../schema/status-update';
import { REGEX_PATTERN } from '../../constants/regex';
import { orderStatusConstant } from './orderConstant';

import {
  textInputField,
  textInputFieldStyle,
  formHelperStyle,
} from '../../sections/auth/login/LoginForm.style';
import { LanguageContext } from '../../globalContext/LanguageSwitcher';
import { SelectComponent } from '../../components/add-user/SelectComponent';
import { OrderConstant } from './orderConstant';
import './orderStyle.css';
import { APP_LANGUAGE } from '../../common/constants/AppConstants';
import { getStatusClassName } from '../../utils/helperUtility';

const SubmitButton = styled(Button)(({ theme }) => ({
  background: `${Colors.LIGHT_YELLOW}`,
  color: `${Colors.BLACK}`,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '17px',
  textAlign: 'center',
  padding: '12px 32px 12px 32px',
  height: '40px',
  borderRadius: '8px',
  '&:hover': {
    background: `${Colors.LIGHT_YELLOW}`,
  },
}));

export default function UpdateOrderStatus(props) {
  const { t, i18n } = useTranslation();
  const {
    open,
    handleClose,
    handleAccept,
    handleReject,
    title,
    description,
    orderData,
  } = props;
  const { language } = useContext(LanguageContext);
  const isFieldError = (field) => {
    return !!formik.touched?.[field] && !!formik.errors?.[field];
  };
  const [actionType, setActionType] = useState(null);

  const status = [
    {
      id: 'DELIVERED',
      name: t('Delivered'),
    },
    {
      id: 'READYFORPICKUP',
      name: t('ReadyForPickUp'),
    },

    {
      id: 'SHIPPED',
      name: t('Shipped'),
    },
  ];

  const filterStatusList = (currentStatus, statusList) => {
    const deliveryMode = orderData.deliveryMode;

    if (currentStatus === OrderConstant.CONFIRMED) {
      // Remove 'DELIVERED' from the list
      statusList = statusList.filter(
        (status) => status.id !== OrderConstant.DELIVERED
      );

      if (deliveryMode === OrderConstant.PICKUP) {
        statusList = statusList.filter(
          (status) => status.id !== OrderConstant.SHIPPED
        );
      } else if (deliveryMode === OrderConstant.SHIP) {
        statusList = statusList.filter(
          (status) => status.id !== OrderConstant.READYFORPICKUP
        );
      }
    } else if (
      currentStatus === OrderConstant.SHIPPED ||
      currentStatus === OrderConstant.READYFORPICKUP
    ) {
      // Remove both 'READYFORPICKUP' and 'SHIPPED' from the list
      return statusList.filter(
        (status) =>
          status.id !== OrderConstant.READYFORPICKUP &&
          status.id !== OrderConstant.SHIPPED
      );
    }
    return statusList;
  };

  const statusList = filterStatusList(orderData.status, status);
  const baseValidationSchema = basekValidationSchema;
  const [showShippedForm, setShowShippedForm] = useState(false);

  const [currentValidationSchema, setCurrentValidationSchema] =
    useState(baseValidationSchema);

  const placedSchema = placedKSchema;

  // Schema for REJECTED action where remark field is required
  const rejectSchema = rejectKSchema;

  // Schema for CONFIRMED action where remark field is not required
  const confirmSchema = confirmKSchema;

  const shippedSchema = shippedKSchema;
  const isArabic = i18n.language === APP_LANGUAGE.ARABIC;

  const formik = useFormik({
    initialValues: {
      remark: '',
      partnerName: '',
      status: orderData.status !== OrderConstant.PLACED ? '' : orderData.status,
      trackingId: '',
      // trackingLink: '',
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: currentValidationSchema,
    onSubmit: (values) => {
      console.log(formik);

      if (handleAccept && actionType === OrderConstant.CONFIRMED) {
        handleClose();
        handleAccept({
          data: formik.values,
          action: actionType,
          isShipped: false,
        });
      } else if (handleReject && actionType === OrderConstant.REJECTED) {
        handleClose();
        handleAccept({
          data: formik.values,
          action: actionType,
          isShipped: false,
        });
      } else {
        handleAccept({
          data: formik.values,
          action: actionType,
          isShipped: formik.values.status === OrderConstant.SHIPPED,
        });
      }
    },
  });

  useEffect(() => {
    let schema;
    if (formik.values.status === OrderConstant.PLACED) {
      schema = placedSchema;
    } else if (formik.values.status === OrderConstant.SHIPPED) {
      setShowShippedForm(true);
      schema = shippedSchema;
    } else {
      schema = baseValidationSchema;
    }
    setCurrentValidationSchema(schema);
    formik.validateForm(); // Trigger validation with the new schema
  }, [formik.values.status, t]);

  const isStatusPlaced = orderData.status === OrderConstant.PLACED;
  const isStatusShipped = formik.values.status === OrderConstant.SHIPPED;
  const getCurrentStatus =
    orderStatusConstant[orderData && orderData.status] || '';
  const handleAction = (type) => {
    let schema;
    if (type === OrderConstant.REJECTED) {
      schema = rejectSchema;
    } else if (type === OrderConstant.CONFIRMED) {
      schema = confirmSchema;
    } else if (formik.values.status === OrderConstant.SHIPPED) {
      schema = shippedSchema;
    } else {
      schema = baseValidationSchema;
    }

    setCurrentValidationSchema(schema); // Update the schema state
    setActionType(type); // Set the action type
    formik.validateForm().then(() => {
      // Trigger validation with the new schema
      if (formik.isValid) {
        formik.submitForm();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: { width: '40%' },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="row" dir={isArabic ? 'rtl' : 'ltr'}>
          <div className="status-label">
            {t('CURRENT_STATUS')} :{' '}
            {getCurrentStatus && (
              <span
                className={`status-val ${getStatusClassName(getCurrentStatus)}`}
              >
                {getCurrentStatus}
              </span>
            )}
          </div>
        </div>
        {!isStatusPlaced && (
          <div className="col-12 mb-4">
            <SelectComponent
              fieldName={'status'}
              formik={formik}
              labelName={t('STATUS')}
              language={language}
              options={statusList}
              placeholder={t('PLACEHOLDER_SELECT_STATUS')}
              value={formik.values.status}
              onChange={(event) => {
                formik.setFieldValue('status', event.target.value);
              }}
              onBlur={formik.handleBlur}
              disabled={false}
            />
          </div>
        )}

        {isStatusPlaced && (
          <div className="row mt-4">
            <AuthInput
              className="col-12"
              htmlFor="remark"
              label={t('REMARK')}
              // required={true}
              sx={textInputField(isFieldError('remark'), language)}
              height="40px"
              inputProps={{ sx: textInputFieldStyle, maxLength: 500 }}
              placeholder={t('REMARK')}
              name="remark"
              id="remark"
              onChange={formik.handleChange}
              value={formik.values.remark}
              onBlur={formik.handleBlur}
              textFieldError={isFieldError('remark')}
              FormHelperTextProps={{
                sx: formHelperStyle,
              }}
              helperText={isFieldError('remark') && t(formik.errors.remark)}
            />
          </div>
        )}

        {isStatusShipped && (
          <>
            <div className="row mt-4">
              <AuthInput
                className="col-12"
                htmlFor="partnerName"
                label={t('PARTNER_NAME')}
                required={true}
                sx={textInputField(isFieldError('partnerName'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 50 }}
                placeholder={t('PARTNER_NAME')}
                name="partnerName"
                id="partnerName"
                onChange={formik.handleChange}
                value={formik.values.partnerName}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('partnerName')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('partnerName') && t(formik.errors.partnerName)
                }
              />
            </div>

            <div className="row mt-4">
              <AuthInput
                className="col-12"
                htmlFor="trackingId"
                label={t('TRACKING_ID')}
                required={true}
                sx={textInputField(isFieldError('trackingId'), language)}
                height="40px"
                inputProps={{ sx: textInputFieldStyle, maxLength: 30 }}
                placeholder={t('TRACKING_ID')}
                name="trackingId"
                id="trackingId"
                onChange={formik.handleChange}
                value={formik.values.trackingId}
                onBlur={formik.handleBlur}
                textFieldError={isFieldError('trackingId')}
                FormHelperTextProps={{
                  sx: formHelperStyle,
                }}
                helperText={
                  isFieldError('trackingId') && t(formik.errors.trackingId)
                }
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isStatusPlaced && (
          <RejectButton
            disabledCondn={!(formik.isValid && formik.dirty)}
            handleClick={() => handleAction(OrderConstant.REJECTED)}
          >
            {t('REJECT')}
          </RejectButton>
        )}
        {isStatusPlaced && (
          <SubmitButton
            onClick={() => handleAction(OrderConstant.CONFIRMED)}
            disabled={!formik.isValid}
            autoFocus
          >
            {t('ACCEPT')}
          </SubmitButton>
        )}
        {!isStatusPlaced && (
          <SubmitButton
            onClick={() => handleAction(OrderConstant.OTHER)}
            disabled={!(formik.isValid && formik.dirty)}
            autoFocus
          >
            {t('Confirm')}
          </SubmitButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
