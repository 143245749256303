export const OrderConstant = {
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  OTHER: 'OTHER',
  SHIPPED: 'SHIPPED',
  PLACED: 'PLACED',
  SHIP: 'SHIP',
  PICKUP: 'PICKUP',
  DELIVERED: 'DELIVERED',
  READYFORPICKUP: 'READYFORPICKUP',
};

export const orderStatusConstant = {
  CONFIRMED: 'Confirmed',
  REJECTED: 'Rejected',
  SHIPPED: 'Shipped',
  PLACED: 'Placed',
  SHIP: 'Ship',
  DELIVERED: 'Delivered',
  READYFORPICKUP: 'Ready for pickup',
  PICKUP: 'Pickup',
};
