import { Box, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { headerStyle } from './header.style';

export const Header = ({
  children,
  closeButtonAction,
  exitIconStyle,
  height,
  fontStyle,
}) => {
  return (
    <Box height={height} sx={headerStyle}>
      <Typography variant="h6" component="h2" sx={fontStyle}>
        {children}
      </Typography>
      {typeof closeButtonAction === 'function' && (
        <IconButton
          aria-label="close"
          onClick={closeButtonAction}
          disableRipple
        >
          <CloseIcon
            fontSize="inherit"
            sx={{ ...exitIconStyle, color: '#222222' }}
          />
        </IconButton>
      )}
    </Box>
  );
};
