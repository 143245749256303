export const Colors = {
  DARK_BACKGROUND: '#303030',
  LIGHT_BACKGROUND: '#FDFDFD',
  LIGHT_GRAY: '#8A8A8A',
  GRAY: '#A5A4A2',
  GENERAL_GRAY: '#F4F3F3',
  SLATE_GRAY: '#3B3B3B',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  LIGHT_YELLOW: '#E3CA7E',
  RED: '#AE3F1E',
  PAPER_BACKGOUND: '#F7F7F7',
  LIGHT_GRAY: '#C3C3C3',
  DARK_GRAY: '#777673',
  COMMON_GRAY: '#969592',
  GREEN_VERIFIED: '#17840B',
};
