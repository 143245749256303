import Avatar from '@mui/material/Avatar';
import { ASSETS_IMAGE_PATH } from '../../common/constants/AppConstants';
import { baseAvtarStyle } from './ImageAvtar.style';
import { getShortName } from '../../utils/helperUtility';

export default function ImageAvatar({ firstName, lastName, imageId }) {
  return (
    <Avatar
      alt={firstName}
      src={`${ASSETS_IMAGE_PATH}/${imageId}`}
      sx={baseAvtarStyle(imageId, firstName)}
    >
      {getShortName(firstName, lastName)}
    </Avatar>
  );
}
