import { COMMONLY_ACCESSIBLE_URLS } from '../../common/constants/AppConstants';
import permissionMapping from './permissionsMapping';
import { uniq } from 'lodash';

export default (permissions) =>
  uniq(
    permissions.reduce((accessibleUrls, currentPermission) => {
      const readPermissions =
        permissionMapping[currentPermission.moduleCode]?.readUrlPatterns ?? [];
      const writePermissions =
        permissionMapping[currentPermission.moduleCode]?.writeUrlPatterns ?? [];
      if (currentPermission.readMode) accessibleUrls?.push(...readPermissions);
      if (currentPermission.writeMode)
        accessibleUrls?.push(...writePermissions, ...readPermissions);
      return accessibleUrls;
    }, COMMONLY_ACCESSIBLE_URLS)
  );
