import { useEffect, useState } from 'react';
import { Card, Stack, Button, Container, Typography, Box } from '@mui/material';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useNavigate } from 'react-router-dom';
import CommonTable from '../components/index-table/CommonTable';
import { userPageStyles } from './userPageStyle';
import { useDebounce } from '@uidotdev/usehooks';
import Pagination from '../components/pagination';
import ENDPOINTS from '../constants/endpoints';
import { useTranslation } from 'react-i18next';
import './AdminManagement/adminConstant';
import './AdminManagement/adminManagement.css';
import { getModulePerm } from '../utils/helperUtility';
import SearchBar from '../components/search-bar';
import { MAX_INPUT_LENGTH } from '../common/constants/AppConstants';
import { warehouseList } from '../api/warehouse';
import { getCountries } from '../api/getCountries';
import { FILTER_OPTION } from '../common/constants/ModuleConstant';
import SelectInput from '../components/select-input/SelectInput';
import { Badge } from '@shopify/polaris';

const CLICK_EVENT_KEYS = {
  NEW_WAREHOUSE: 'NEW_WAREHOUSE',
  EDIT_WAREHOUSE: 'EDIT_WAREHOUSE',
  VIEW_WAREHOUSE: 'VIEW_WAREHOUSE',
};

export default function WarehouseManagementPage() {
  const { t } = useTranslation();
  const [warehouseData, setWarehouseData] = useState([]);
  const [countriesData, setCountriesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(true);
  const [filter, setFilter] = useState(FILTER_OPTION.ALL);
  const navigate = useNavigate();
  const [totalList, setTotalList] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = parseInt(10);
  const pageCount = Math.ceil(totalList / itemsPerPage);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value;
    if (newSearchTerm.length === 1) {
      if (newSearchTerm.charAt(newSearchTerm.length - 1) === ' ') return;
    } else if (newSearchTerm.length > 1) {
      if (
        newSearchTerm.charAt(newSearchTerm.length - 1) === ' ' &&
        newSearchTerm.charAt(newSearchTerm.length - 2) === ' '
      )
        return;
    }
    setCurrentPage(1);
    setSearchTerm(newSearchTerm);
  };
  const handleClearSearch = (event) => {
    setSearchTerm('');
  };

  const handleStatusChange = () => {
    setFilterByStatus(filterByStatus !== true);
  };

  useEffect(() => {
    getTableData();
  }, [debouncedSearchTerm, currentPage, filter]);

  const getTableData = async () => {
    setLoading(true);
    try {
      const query = {
        pageLimit: limit,
        pageSkip: currentPage - 1,
        search_str: searchTerm,
      };
      const { data: countriesData } = await getCountries();
      const { data } = await warehouseList(query);
      setCountriesData(countriesData.data);
      setWarehouseData(data.data);
      setLimit(data.limit);
      setTotalList(data.totalCount);
      setLoading(false);
    } catch (error) {
      console.error(t('SOME_ERROR_OCCOURED'));
      setLoading(false);
    }
  };

  const currentModulePerm = getModulePerm('WAREHOUSE_MANAGEMENT');

  const ButtonsForTable = ({ rowItemData }) => {
    return (
      <Box display={'flex'} gap={'40px'} justifyContent={'center'}>
        {(currentModulePerm.writeMode || currentModulePerm.readMode) && (
          <Typography
            fontWeight={'500'}
            fontSize={'12px'}
            fontFamily={'Inter'}
            color={'#A5A4A2'}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              handleClickEvent(CLICK_EVENT_KEYS.VIEW_WAREHOUSE, rowItemData)
            }
          >
            {t('VIEW_BTN')}
          </Typography>
        )}
        {currentModulePerm.writeMode && (
          <Box display={'flex'} gap={'16px'}>
            <Box>
              <Iconify
                icon="streamline:pencil"
                width={'14px'}
                color="primary"
                title={
                  !currentModulePerm.writeMode
                    ? t('YOU_DONT_HAVE_PERMISSION')
                    : ''
                }
                sx={{
                  ...userPageStyles.penIconSx,
                  cursor: currentModulePerm.writeMode
                    ? 'pointer'
                    : 'not-allowed',
                }}
                onClick={() =>
                  currentModulePerm.writeMode &&
                  handleClickEvent(CLICK_EVENT_KEYS.EDIT_WAREHOUSE, rowItemData)
                }
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  };
  const currentItems = warehouseData.map((warehouse) => ({
    id: warehouse.id,
    name: warehouse.name ?? '',
    personName: warehouse.contactPersonName ?? '',
    contactNumber: `(+${
      countriesData.find((data) => data?.id === warehouse?.fkCountriesId)
        ?.mobileCode ?? ''
    }) ${warehouse?.contactPersonNo ?? ''}`,

    renderActive: () => {
      return warehouse.isActive ? (
        <Badge tone="enabled" progress="complete">
          {t('ACTIVE_BADGE')}
        </Badge>
      ) : (
        <Badge tone="disabled">{t('INACTIVE_BADGE')}</Badge>
      );
    },
    address: `${warehouse.addressLine1 ?? ''} ${warehouse.addressLine2 ?? ''} ${
      warehouse.city ?? ''
    } ${warehouse.nameEng ?? ''}`,
    renderButton: () => <ButtonsForTable rowItemData={warehouse} />,
  }));

  const roleColumns = [
    {
      key: 'name',
      title: t('Warehouse Name'),
    },
    {
      key: 'personName',
      title: t('Person Name'),
    },
    {
      key: 'contactNumber',
      title: t('Contact Number'),
    },
    {
      key: 'renderActive',
      title: t('TH_STATUS'),
      isComponent: true,
    },
    {
      key: 'address',
      title: t('Address'),
    },
    { key: 'renderButton', title: '', isComponent: true },
  ];

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const handleClickEvent = (key, data = null) => {
    switch (key) {
      case CLICK_EVENT_KEYS.NEW_WAREHOUSE:
        navigate(ENDPOINTS.FORM);
        break;
      case CLICK_EVENT_KEYS.EDIT_WAREHOUSE:
        navigate(`${ENDPOINTS.WAREHOUSE_MANAGEMENT}/form?id=${data.id}`);
        break;
      case CLICK_EVENT_KEYS.VIEW_WAREHOUSE:
        navigate(
          `${ENDPOINTS.WAREHOUSE_MANAGEMENT}/form?id=${data.id}&view=true`
        );
        break;
      default:
        break;
    }
  };

  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <Typography variant="h4" gutterBottom>
          {t('Warehouse Management')}
        </Typography>

        {currentModulePerm.writeMode && (
          <Button
            variant="contained"
            sx={userPageStyles.buttonSx}
            onClick={() => handleClickEvent(CLICK_EVENT_KEYS.NEW_WAREHOUSE)}
            startIcon={<Iconify width={'18px'} icon="el:plus-sign" />}
          >
            {t('Add new Warehouse')}
          </Button>
        )}
      </Stack>

      <Card>
        <Scrollbar>
          <div className="filter-wrapper">
            <SearchBar
              onChange={handleSearchChange}
              value={searchTerm}
              placeholder={t('Search')}
              maxlength={MAX_INPUT_LENGTH}
              onClear={handleClearSearch}
            />
          </div>
          <CommonTable
            resourceName={resourceName}
            data={currentItems}
            columns={roleColumns}
            isCheckBox={false}
            loading={loading}
            onSortButtonClick={() => {}}
            handleStatusChange={handleStatusChange}
            emptyTableText={t('NO_WAREHOUSE_FOUND')}
          />
          <Box
            display="flex"
            justifyContent={'flex-end'}
            sx={userPageStyles.paginationContainerSx}
          >
            <Typography
              color={'#C3C3C3'}
              fontSize={12}
              marginY={'6px'}
              fontFamily={'Inter'}
              fontWeight={'500'}
            >
              {t('PG_SHOWING')}
              <Typography
                component={'span'}
                fontSize={12}
                color={'#777673'}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {Math.min(startIndex + 1, totalList)}-
                {Math.min(endIndex, totalList)}
              </Typography>
              {t('PG_FROM')}
              <Typography
                component={'span'}
                fontSize={12}
                color={'#777673'}
                fontFamily={'Inter'}
                fontWeight={'500'}
                marginX={'2px'}
              >
                {totalList}
              </Typography>
              {t('PG_RESULTS')}
            </Typography>
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
            />
          </Box>
        </Scrollbar>
      </Card>
    </Container>
  );
}
