import apiRequest, { METHODS } from './apiRequest';
import API_ENDPOINTS from '../constants/apiEndpoints';

export const addWarehouse = (requestBody) =>
  apiRequest({
    endpoint: API_ENDPOINTS.WAREHOUSE,
    method: METHODS.POST,
    data: requestBody,
    privateURL: true,
  });

export const warehouseList = (query) =>
  apiRequest({
    endpoint: API_ENDPOINTS.WAREHOUSES,
    method: METHODS.GET,
    headers: {},
    query: query,
    privateURL: true,
  });

export const warehouse = (id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.WAREHOUSES}/${id}`,
    method: METHODS.GET,
    headers: {},
    privateURL: true,
  });

export const updateWarehouse = (requestBody, id) =>
  apiRequest({
    endpoint: `${API_ENDPOINTS.WAREHOUSE}/${id}`,
    method: METHODS.PUT,
    data: requestBody,
    privateURL: true,
  });
