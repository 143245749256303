import React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import NavTopBar from '../../components/navbar/NavTopbar';
import SideNavBar from './nav/SideNavBar';
import { SideBarProvider } from '../../globalContext/SideBar';
import { Frame, Navigation } from '@shopify/polaris';
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 50;

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto', // Enable scrollbars when content overflows
  maxHeight: `calc(100vh - ${APP_BAR_DESKTOP}px)`, // Set the maximum height to the viewport height minus the header height
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ contentInFullArea = false }) {
  return (
    <Frame>
      <SideBarProvider>
        <NavTopBar />
        <Stack direction="row" className="h-100">
          <SideNavBar />
          <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden' }}>
            {' '}
            {/* This Box becomes the flex container for the sidebar and main content */}
            {contentInFullArea ? (
              <Outlet />
            ) : (
              <Main>
                <Outlet />
              </Main>
            )}
          </Box>
        </Stack>
      </SideBarProvider>
    </Frame>
  );
}
