/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import config from '../config';
import { LOCALSTORAGE_KEYS } from '../constants/localStorageConstant';

export const METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
};
const { API } = config;

const BASE_URL = API.REACT_APP_BASE_URL;
const PAYMENT_BASE_URL = API.REACT_APP_PAYMENT_BASE_URL;

function apiRequest({
  method = 'get',
  endpoint,
  data,
  query,
  token,
  type = 'application/json',
  responseType,
  headers,
  privateURL = false,
  paymentUrl = false,
}) {
  let baseUrl = privateURL ? BASE_URL : `${BASE_URL}/public`;
  if (paymentUrl) {
    baseUrl = PAYMENT_BASE_URL;
  }

  const _token = token || localStorage.getItem(LOCALSTORAGE_KEYS.AUTH_TOKEN);
  const options = {
    'accept-version': '',
    'Content-Type': '',
  };

  if (_token) {
    options.Authorization = `${_token}`;
  }

  options['Content-Type'] = type;

  return new Promise((resolve, reject) => {
    axios({
      baseURL: baseUrl,
      method,
      url: endpoint,
      data,
      headers: { ...options, ...headers },
      params: query,
      responseType,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        const errorBody = error.response || {};
        if (
          error.response?.status === 401 &&
          window.location.pathname !== '/'
        ) {
          window.localStorage.clear();
          window.location.replace('/');
          window.location.reload();
        } else {
          reject(errorBody);
        }
      });
  });
}

export default apiRequest;
