export const style = {
  textInputField: {
    '& .MuiInputBase-root': {
      height: '28px',
      borderRadius: '8px',
      width: '100%',
      borderWidth: '0.66px',
      boxShadow: '1px 1px 4px #E3E3E3',
      paddingY: '4px',
      '& fieldset': {
        border: '0.66px solid #E3E3E3 !important',
        fontSize: '12px',
      },
      '&.Mui-focused': {
        backgroundColor: '#FDFDFD',
      },
      '& .MuiOutlinedInput-input': {
        paddingLeft: '0px', 
        paddingRight: '0px', 
      },
    },
    '.MuiInputBase-input.MuiOutlinedInput-input': {
      fontSize: '12px',
      '&::placeholder': {
        color: '#A5A4A2',
        fontSize: '12px',
        lineHeight: '14.52',
        fontFamily: 'Inter',
        paddingLeft: '0',
        marginLeft: '0',
      },
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: '0px', 
      marginLeft: '0px', 
    },
  },
  crossIconSx: { width: '36px', height: '36px', display: 'inline-block' }
};
