import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import { MODULE_CONST } from '../common/constants/ModuleConstant';
const moduleRouteMapping = {
  '/dashboard/user': MODULE_CONST.USER_MANAGEMENT,
  '/dashboard/view-user': MODULE_CONST.USER_MANAGEMENT,
  '/dashboard/edit-user': MODULE_CONST.USER_MANAGEMENT,
  '/dashboard/admin': MODULE_CONST.ADMIN_MANAGEMENT,
  '/dashboard/admin-form': MODULE_CONST.ADMIN_MANAGEMENT,
  '/dashboard/role': MODULE_CONST.ROLE_MANAGEMENT,
  '/dashboard/role-form': MODULE_CONST.ROLE_MANAGEMENT,
  '/dashboard/transaction': MODULE_CONST.TRANSACTION_HISTORY,
  '/dashboard/transaction-details': MODULE_CONST.TRANSACTION_HISTORY,
  '/dashboard/kyc': MODULE_CONST.VERIFICATION_APPROVAL,
  '/dashboard/kyc-verification': MODULE_CONST.VERIFICATION_APPROVAL,
  '/dashboard/warehouse': MODULE_CONST.WAREHOUSE_MANAGEMENT,
  '/dashboard/warehouse/form': MODULE_CONST.WAREHOUSE_MANAGEMENT,
  '/dashboard/app': MODULE_CONST.HOME,
  '/dashboard/products': MODULE_CONST.PRODUCT_MANAGEMENT,
  '/dashboard/add-product': MODULE_CONST.PRODUCT_MANAGEMENT,
  '/dashboard/orders': MODULE_CONST.ORDER_MANAGEMENT,
  '/dashboard/order-details': MODULE_CONST.ORDER_MANAGEMENT,
  '/dashboard/delete-account': MODULE_CONST.ACCOUNT_DELETE_REQUEST,
  '/dashboard/delete-account/details': MODULE_CONST.ACCOUNT_DELETE_REQUEST,
  '/dashboard/reports': MODULE_CONST.REPORTING_ANALYTICS,
  '/dashboard/transfer-gold-silver': MODULE_CONST.TRANSFER_MANAGEMENT,
  '/dashboard/transfer-gold-silver/details': MODULE_CONST.TRANSFER_MANAGEMENT,
  '/dashboard/account-manager': MODULE_CONST.ACCOUNT_MANAGEMENT,
  '/dashboard/account-manager-form': MODULE_CONST.ACCOUNT_MANAGEMENT,
  '/dashboard/announcement': MODULE_CONST.ANNOUNCEMENT_MANAGEMENT,
  '/dashboard/announcement-form': MODULE_CONST.ANNOUNCEMENT_MANAGEMENT,
  '/dashboard/ticket-management': MODULE_CONST.TICKET_MANAGEMENT,
  '/dashboard/withdrawals': MODULE_CONST.WITHDRAWALS,
  '/dashboard/bank-transfers': MODULE_CONST.BANK_TRANSFERS,
  '/dashboard/city-management': MODULE_CONST.CITY_MANAGEMENT,
  '/dashboard/withdrawals': MODULE_CONST.WITHDRAWALS,
  '/dashboard/bank-transfers': MODULE_CONST.BANK_TRANSFERS,
  '/dashboard/withdrawals': MODULE_CONST.WITHDRAWALS,
  '/dashboard/gift-settings': MODULE_CONST.GIFT_SETTINGS,
  '/dashboard/third-party-clients': MODULE_CONST.THIRD_PARTY_CLIENTS,
};

const getModuleCodeFromPath = (pathname) => {
  for (const [route, moduleCode] of Object.entries(moduleRouteMapping)) {
    if (pathname.startsWith(route)) {
      return moduleCode;
    }
  }
  return null;
};

export const SidebarContext = createContext();

export const SideBarProvider = ({ children }) => {
  const location = useLocation();
  const [selectedNav, setSelectedNav] = useState(null);

  useEffect(() => {
    const path = location.pathname;
    const moduleCode = getModuleCodeFromPath(path);
    setSelectedNav(moduleCode);
  }, [location]);

  const selectNavItem = (moduleCode) => {
    setSelectedNav(moduleCode);
  };

  return (
    <SidebarContext.Provider value={{ selectedNav, selectNavItem }}>
      {children}
    </SidebarContext.Provider>
  );
};
