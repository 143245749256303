import { useEffect, useRef } from 'react';

const useOutsideClick = ({ onOutsideClick, elementNotToBeConsidered }) => {
  const componentRef = useRef(null);

  const handleMouseDown = (element) => {
    if (
      componentRef?.current &&
      !componentRef?.current.contains(element.target)
    ) {
      if (elementNotToBeConsidered?.current) {
        !elementNotToBeConsidered.current.contains(element.target) &&
          onOutsideClick();
      } else onOutsideClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    return () => document.removeEventListener('mousedown', handleMouseDown);
  }, []);

  return { componentRef };
};

export default useOutsideClick;
